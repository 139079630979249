@charset "UTF-8";
/*!
Theme Name: Sandbox - Modern & Multipurpose Bootstrap 5 Template
Theme URI:	https://sandbox.elemisthemes.com/start.html
Version:	1.2.1
Author:		elemis
*/
/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700"); */

:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-blue: #3f78e0;
  --bs-purple: #747ed1;
  --bs-violet: #a07cc5;
  --bs-pink: #d16b86;
  --bs-red: #e2626b;
  --bs-orange: #f78b77;
  --bs-yellow: #fab758;
  --bs-green: #6bbea3;
  --bs-leaf: #7cb798;
  --bs-aqua: #54a8c7;
  --bs-navy: #343f52;
  --bs-ash: #9499a3;
  --bs-white: #fff;
  --bs-light: #fefefe;
  --bs-gray: #f6f7f9;
  --bs-dark: #262b32;
  --bs-primary: #3f78e0;
  --bs-secondary: #aab0bc;
  --bs-success: #6bbea3;
  --bs-info: #54a8c7;
  --bs-warning: #fab758;
  --bs-danger: #e2626b;
  --bs-blue-rgb: 63, 120, 224;
  --bs-purple-rgb: 116, 126, 209;
  --bs-violet-rgb: 160, 124, 197;
  --bs-pink-rgb: 209, 107, 134;
  --bs-red-rgb: 226, 98, 107;
  --bs-orange-rgb: 247, 139, 119;
  --bs-yellow-rgb: 250, 183, 88;
  --bs-green-rgb: 107, 190, 163;
  --bs-leaf-rgb: 124, 183, 152;
  --bs-aqua-rgb: 84, 168, 199;
  --bs-navy-rgb: 52, 63, 82;
  --bs-ash-rgb: 148, 153, 163;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 254, 254, 254;
  --bs-gray-rgb: 246, 247, 249;
  --bs-dark-rgb: 38, 43, 50;
  --bs-primary-rgb: 63, 120, 224;
  --bs-secondary-rgb: 170, 176, 188;
  --bs-success-rgb: 107, 190, 163;
  --bs-info-rgb: 84, 168, 199;
  --bs-warning-rgb: 250, 183, 88;
  --bs-danger-rgb: 226, 98, 107;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 96, 105, 123;
  --bs-body-bg-rgb: 254, 254, 254;
  --bs-font-sans-serif: "Manrope", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 20px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.8rem;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.7;
  --bs-body-color: #60697b;
  --bs-body-bg: #fefefe;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 4.5rem 0;
  color: rgba(164, 174, 198, 0.2);
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #343f52;
}

h1,
.h1 {
  font-size: calc(1.27rem + 0.24vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 1.45rem;
  }
}

h2,
.h2 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.3rem;
  }
}

h3,
.h3 {
  font-size: 1.1rem;
}

h4,
.h4 {
  font-size: 0.95rem;
}

h5,
.h5 {
  font-size: 0.9rem;
}

h6,
.h6 {
  font-size: 0.85rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.6rem;
}

mark,
.mark {
  padding: 0.05rem 0.4rem;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--secondaryDark);;
  text-decoration: none;
}

a:hover {
  color: var(--secondaryDark);;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.65rem;
  color: #273444;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.65rem;
  color: #60697b;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #60697b;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  color: #aab0bc;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 0.9rem;
  font-weight: 500;
}

.display-1 {
  font-size: calc(1.365rem + 1.38vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 2.4rem;
  }
}

.display-2 {
  font-size: calc(1.345rem + 1.14vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 2.2rem;
  }
}

.display-3 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: calc(1.305rem + 0.66vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 1.8rem;
  }
}

.display-5 {
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 1.6rem;
  }
}

.display-6 {
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.4rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #aab0bc;
}

.blockquote-footer::before {
  content: "\2014\a0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.row-cols-7>* {
  flex: 0 0 auto;
  width: 14.2857142857%;
}

.row-cols-8>* {
  flex: 0 0 auto;
  width: 12.5%;
}

.row-cols-9>* {
  flex: 0 0 auto;
  width: 11.1111111111%;
}

.row-cols-10>* {
  flex: 0 0 auto;
  width: 10%;
}

.row-cols-11>* {
  flex: 0 0 auto;
  width: 9.0909090909%;
}

.row-cols-12>* {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 3rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 3rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3.5rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3.5rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 4rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 4rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 4.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 4.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 5rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 5rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 6rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 6rem;
}

.g-17,
.gx-17 {
  --bs-gutter-x: 7rem;
}

.g-17,
.gy-17 {
  --bs-gutter-y: 7rem;
}

.g-18,
.gx-18 {
  --bs-gutter-x: 8rem;
}

.g-18,
.gy-18 {
  --bs-gutter-y: 8rem;
}

.g-19,
.gx-19 {
  --bs-gutter-x: 9rem;
}

.g-19,
.gy-19 {
  --bs-gutter-y: 9rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 10rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 10rem;
}

.g-21,
.gx-21 {
  --bs-gutter-x: 12.5rem;
}

.g-21,
.gy-21 {
  --bs-gutter-y: 12.5rem;
}

.g-22,
.gx-22 {
  --bs-gutter-x: 15rem;
}

.g-22,
.gy-22 {
  --bs-gutter-y: 15rem;
}

.g-23,
.gx-23 {
  --bs-gutter-x: 17.5rem;
}

.g-23,
.gy-23 {
  --bs-gutter-y: 17.5rem;
}

.g-24,
.gx-24 {
  --bs-gutter-x: 20rem;
}

.g-24,
.gy-24 {
  --bs-gutter-y: 20rem;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 22.5rem;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 22.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .row-cols-sm-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }

  .row-cols-sm-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-sm-9>* {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }

  .row-cols-sm-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-sm-11>* {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }

  .row-cols-sm-12>* {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-17,
  .gx-sm-17 {
    --bs-gutter-x: 7rem;
  }

  .g-sm-17,
  .gy-sm-17 {
    --bs-gutter-y: 7rem;
  }

  .g-sm-18,
  .gx-sm-18 {
    --bs-gutter-x: 8rem;
  }

  .g-sm-18,
  .gy-sm-18 {
    --bs-gutter-y: 8rem;
  }

  .g-sm-19,
  .gx-sm-19 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-19,
  .gy-sm-19 {
    --bs-gutter-y: 9rem;
  }

  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 10rem;
  }

  .g-sm-21,
  .gx-sm-21 {
    --bs-gutter-x: 12.5rem;
  }

  .g-sm-21,
  .gy-sm-21 {
    --bs-gutter-y: 12.5rem;
  }

  .g-sm-22,
  .gx-sm-22 {
    --bs-gutter-x: 15rem;
  }

  .g-sm-22,
  .gy-sm-22 {
    --bs-gutter-y: 15rem;
  }

  .g-sm-23,
  .gx-sm-23 {
    --bs-gutter-x: 17.5rem;
  }

  .g-sm-23,
  .gy-sm-23 {
    --bs-gutter-y: 17.5rem;
  }

  .g-sm-24,
  .gx-sm-24 {
    --bs-gutter-x: 20rem;
  }

  .g-sm-24,
  .gy-sm-24 {
    --bs-gutter-y: 20rem;
  }

  .g-sm-25,
  .gx-sm-25 {
    --bs-gutter-x: 22.5rem;
  }

  .g-sm-25,
  .gy-sm-25 {
    --bs-gutter-y: 22.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .row-cols-md-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }

  .row-cols-md-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-md-9>* {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }

  .row-cols-md-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-md-11>* {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }

  .row-cols-md-12>* {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 3rem;
  }

  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 3rem;
  }

  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 4rem;
  }

  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 4rem;
  }

  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 5rem;
  }

  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 5rem;
  }

  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 6rem;
  }

  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 6rem;
  }

  .g-md-17,
  .gx-md-17 {
    --bs-gutter-x: 7rem;
  }

  .g-md-17,
  .gy-md-17 {
    --bs-gutter-y: 7rem;
  }

  .g-md-18,
  .gx-md-18 {
    --bs-gutter-x: 8rem;
  }

  .g-md-18,
  .gy-md-18 {
    --bs-gutter-y: 8rem;
  }

  .g-md-19,
  .gx-md-19 {
    --bs-gutter-x: 9rem;
  }

  .g-md-19,
  .gy-md-19 {
    --bs-gutter-y: 9rem;
  }

  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 10rem;
  }

  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 10rem;
  }

  .g-md-21,
  .gx-md-21 {
    --bs-gutter-x: 12.5rem;
  }

  .g-md-21,
  .gy-md-21 {
    --bs-gutter-y: 12.5rem;
  }

  .g-md-22,
  .gx-md-22 {
    --bs-gutter-x: 15rem;
  }

  .g-md-22,
  .gy-md-22 {
    --bs-gutter-y: 15rem;
  }

  .g-md-23,
  .gx-md-23 {
    --bs-gutter-x: 17.5rem;
  }

  .g-md-23,
  .gy-md-23 {
    --bs-gutter-y: 17.5rem;
  }

  .g-md-24,
  .gx-md-24 {
    --bs-gutter-x: 20rem;
  }

  .g-md-24,
  .gy-md-24 {
    --bs-gutter-y: 20rem;
  }

  .g-md-25,
  .gx-md-25 {
    --bs-gutter-x: 22.5rem;
  }

  .g-md-25,
  .gy-md-25 {
    --bs-gutter-y: 22.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .row-cols-lg-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }

  .row-cols-lg-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-lg-9>* {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }

  .row-cols-lg-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-lg-11>* {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }

  .row-cols-lg-12>* {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-17,
  .gx-lg-17 {
    --bs-gutter-x: 7rem;
  }

  .g-lg-17,
  .gy-lg-17 {
    --bs-gutter-y: 7rem;
  }

  .g-lg-18,
  .gx-lg-18 {
    --bs-gutter-x: 8rem;
  }

  .g-lg-18,
  .gy-lg-18 {
    --bs-gutter-y: 8rem;
  }

  .g-lg-19,
  .gx-lg-19 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-19,
  .gy-lg-19 {
    --bs-gutter-y: 9rem;
  }

  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 10rem;
  }

  .g-lg-21,
  .gx-lg-21 {
    --bs-gutter-x: 12.5rem;
  }

  .g-lg-21,
  .gy-lg-21 {
    --bs-gutter-y: 12.5rem;
  }

  .g-lg-22,
  .gx-lg-22 {
    --bs-gutter-x: 15rem;
  }

  .g-lg-22,
  .gy-lg-22 {
    --bs-gutter-y: 15rem;
  }

  .g-lg-23,
  .gx-lg-23 {
    --bs-gutter-x: 17.5rem;
  }

  .g-lg-23,
  .gy-lg-23 {
    --bs-gutter-y: 17.5rem;
  }

  .g-lg-24,
  .gx-lg-24 {
    --bs-gutter-x: 20rem;
  }

  .g-lg-24,
  .gy-lg-24 {
    --bs-gutter-y: 20rem;
  }

  .g-lg-25,
  .gx-lg-25 {
    --bs-gutter-x: 22.5rem;
  }

  .g-lg-25,
  .gy-lg-25 {
    --bs-gutter-y: 22.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .row-cols-xl-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }

  .row-cols-xl-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-xl-9>* {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }

  .row-cols-xl-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-xl-11>* {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }

  .row-cols-xl-12>* {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-17,
  .gx-xl-17 {
    --bs-gutter-x: 7rem;
  }

  .g-xl-17,
  .gy-xl-17 {
    --bs-gutter-y: 7rem;
  }

  .g-xl-18,
  .gx-xl-18 {
    --bs-gutter-x: 8rem;
  }

  .g-xl-18,
  .gy-xl-18 {
    --bs-gutter-y: 8rem;
  }

  .g-xl-19,
  .gx-xl-19 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-19,
  .gy-xl-19 {
    --bs-gutter-y: 9rem;
  }

  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 10rem;
  }

  .g-xl-21,
  .gx-xl-21 {
    --bs-gutter-x: 12.5rem;
  }

  .g-xl-21,
  .gy-xl-21 {
    --bs-gutter-y: 12.5rem;
  }

  .g-xl-22,
  .gx-xl-22 {
    --bs-gutter-x: 15rem;
  }

  .g-xl-22,
  .gy-xl-22 {
    --bs-gutter-y: 15rem;
  }

  .g-xl-23,
  .gx-xl-23 {
    --bs-gutter-x: 17.5rem;
  }

  .g-xl-23,
  .gy-xl-23 {
    --bs-gutter-y: 17.5rem;
  }

  .g-xl-24,
  .gx-xl-24 {
    --bs-gutter-x: 20rem;
  }

  .g-xl-24,
  .gy-xl-24 {
    --bs-gutter-y: 20rem;
  }

  .g-xl-25,
  .gx-xl-25 {
    --bs-gutter-x: 22.5rem;
  }

  .g-xl-25,
  .gy-xl-25 {
    --bs-gutter-y: 22.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .row-cols-xxl-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }

  .row-cols-xxl-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-xxl-9>* {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }

  .row-cols-xxl-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-xxl-11>* {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }

  .row-cols-xxl-12>* {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-17,
  .gx-xxl-17 {
    --bs-gutter-x: 7rem;
  }

  .g-xxl-17,
  .gy-xxl-17 {
    --bs-gutter-y: 7rem;
  }

  .g-xxl-18,
  .gx-xxl-18 {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-18,
  .gy-xxl-18 {
    --bs-gutter-y: 8rem;
  }

  .g-xxl-19,
  .gx-xxl-19 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-19,
  .gy-xxl-19 {
    --bs-gutter-y: 9rem;
  }

  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 10rem;
  }

  .g-xxl-21,
  .gx-xxl-21 {
    --bs-gutter-x: 12.5rem;
  }

  .g-xxl-21,
  .gy-xxl-21 {
    --bs-gutter-y: 12.5rem;
  }

  .g-xxl-22,
  .gx-xxl-22 {
    --bs-gutter-x: 15rem;
  }

  .g-xxl-22,
  .gy-xxl-22 {
    --bs-gutter-y: 15rem;
  }

  .g-xxl-23,
  .gx-xxl-23 {
    --bs-gutter-x: 17.5rem;
  }

  .g-xxl-23,
  .gy-xxl-23 {
    --bs-gutter-y: 17.5rem;
  }

  .g-xxl-24,
  .gx-xxl-24 {
    --bs-gutter-x: 20rem;
  }

  .g-xxl-24,
  .gy-xxl-24 {
    --bs-gutter-y: 20rem;
  }

  .g-xxl-25,
  .gx-xxl-25 {
    --bs-gutter-x: 22.5rem;
  }

  .g-xxl-25,
  .gy-xxl-25 {
    --bs-gutter-y: 22.5rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #60697b;
  --bs-table-striped-bg: rgba(164, 174, 198, 0.07);
  --bs-table-active-color: #60697b;
  --bs-table-active-bg: rgba(164, 174, 198, 0.07);
  --bs-table-hover-color: #60697b;
  --bs-table-hover-bg: rgba(164, 174, 198, 0.07);
  width: 100%;
  margin-bottom: 1rem;
  color: #60697b;
  vertical-align: top;
  border-color: #edeff3;
}

.table> :not(caption)>*>* {
  padding: 0.9rem 0.9rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #edeff3;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c4d7f3;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #c4d7f3;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #c4d7f3;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #c0d2ee;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d6d8db;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #d6d8db;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #d6d8db;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #d1d3d6;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c6dbd3;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #c6dbd3;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #c6dbd3;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #c1d6cf;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c4e7f0;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #c4e7f0;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #c4e7f0;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #c0e2eb;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f1e6c4;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #f1e6c4;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #f1e6c4;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #ebe1c1;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eaccd0;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #eaccd0;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #eaccd0;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #e4c8cc;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #eaecee;
  --bs-table-striped-color: #343f52;
  --bs-table-active-bg: #eaecee;
  --bs-table-active-color: #343f52;
  --bs-table-hover-bg: #eaecee;
  --bs-table-hover-color: #343f52;
  color: #343f52;
  border-color: #e4e6e9;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #313438;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #313438;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #313438;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  color: #959ca9;
}

.col-form-label {
  padding-top: calc(0.6rem + 1px);
  padding-bottom: calc(0.6rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
  color: #959ca9;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.7;
  color: #60697b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(8, 60, 130, 0.06);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #9fbcf0;
  outline: 0;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04), unset;
}

.form-control::-webkit-date-and-time-value {
  height: 1.7em;
}

.form-control::-moz-placeholder {
  color: #959ca9;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #959ca9;
  opacity: 1;
}

.form-control::placeholder {
  color: #959ca9;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.6rem 1rem;
  margin: -0.6rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.6rem 1rem;
  margin: -0.6rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.4rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.7em + 1.2rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.6rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.7em;
  border-radius: 0.4rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.7em;
  border-radius: 0.4rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.6rem 2.25rem 0.6rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.7;
  color: #959ca9;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'%3e%3cpath fill='%2360697b' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 8px 8px;
  border: 1px solid rgba(8, 60, 130, 0.06);
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: rgba(8, 60, 130, 0.1);
  outline: 0;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #959ca9;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.55rem;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.55rem;
}

.form-check-input {
  width: 1.05rem;
  height: 1.05rem;
  margin-top: 0.325rem;
  vertical-align: top;
  background-color: #fefefe;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(8, 60, 130, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: rgba(8, 60, 130, 0.1);
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.6rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control {
  padding: 0.6rem 1rem;
}

.form-floating>.form-control::-moz-placeholder {
  color: transparent;
}

.form-floating>.form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating>.form-control::placeholder {
  color: transparent;
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.form-floating>.form-control:not(:-ms-input-placeholder) {
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.form-floating>.form-select {
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  opacity: 1;
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
  opacity: 1;
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 1;
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: 1;
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.7;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.4rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.4rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'%3e%3cpath fill='%2360697b' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2.25rem;
  background-size: 8px 8px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'%3e%3cpath fill='%2360697b' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2.25rem;
  background-size: 8px 8px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2rem;
  vertical-align: 0.15rem;
  content: "";
  border-top: 0.25rem solid;
  border-right: 0.25rem solid transparent;
  border-bottom: 0;
  border-left: 0.25rem solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 4rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.75rem;
  color: #343f52;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.75rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2rem;
  vertical-align: 0.15rem;
  content: "";
  border-top: 0;
  border-right: 0.25rem solid transparent;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.75rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2rem;
  vertical-align: 0.15rem;
  content: "";
  border-top: 0.25rem solid transparent;
  border-right: 0;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.25rem solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.75rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2rem;
  vertical-align: 0.15rem;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.2rem;
  vertical-align: 0.15rem;
  content: "";
  border-top: 0.25rem solid transparent;
  border-right: 0.25rem solid;
  border-bottom: 0.25rem solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.2rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #343f52;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 3px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #3f78e0;
  background-color: inherit;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #3f78e0;
  text-decoration: none;
  background-color: inherit;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #60697b;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  color: #3f78e0;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.2rem 1.5rem;
  color: #343f52;
}

.dropdown-menu-dark {
  color: #cacaca;
  background-color: #343f52;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #cacaca;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #3f78e0;
  background-color: inherit;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #959ca9;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #cacaca;
}

.dropdown-menu-dark .dropdown-header {
  color: #959ca9;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n+3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 1.05rem;
  padding-left: 1.05rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1.2rem 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  color: #343f52;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #3f78e0;
}

.nav-link.disabled {
  color: #959ca9;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(164, 174, 198, 0.2);
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #959ca9;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3f78e0;
  background-color: #fff;
  border-color: rgba(164, 174, 198, 0.2);
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.4rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #3f78e0;
  background-color: #fff;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  font-size: 0.7rem;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }

  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}

.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #343f52;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #3f78e0;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(52, 63, 82, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #3f78e0;
}

.navbar-light .navbar-toggler {
  color: #343f52;
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'%3e%3cpath fill='%233f78e0' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #343f52;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #3f78e0;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'%3e%3cpath fill='%23fff' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: rgba(255, 255, 255, 0.7);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(164, 174, 198, 0.2);
  border-radius: 0.4rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 2rem 2rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: 2rem;
}

.card-header {
  padding: 1rem 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(164, 174, 198, 0.2);
}

.card-header:first-child {
  border-radius: 0.4rem 0.4rem 0 0;
}

.card-footer {
  padding: 1rem 2rem;
  border-top: 1px solid rgba(164, 174, 198, 0.2);
}

.card-footer:last-child {
  border-radius: 0 0 0.4rem 0.4rem;
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.4rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.card-group>.card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #343f52;
  background-color: #fff;
  border: 1px solid rgba(164, 174, 198, 0.2);
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #3f78e0;
  background-color: #fff;
  border-color: rgba(164, 174, 198, 0.2);
}

.page-link:focus {
  z-index: 3;
  color: #3f78e0;
  background-color: #fff;
  outline: 0;
  box-shadow: unset;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #3f78e0;
  background-color: #fff;
  border-color: rgba(164, 174, 198, 0.2);
}

.page-item.disabled .page-link {
  color: #959ca9;
  pointer-events: none;
  background-color: #fff;
  border-color: rgba(164, 174, 198, 0.2);
}

.page-link {
  padding: 0 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.pagination-lg .page-link {
  padding: 0 0;
  font-size: 1rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0 0;
  font-size: 0.7rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.65em 0.8em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(30, 34, 40, 0.9);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 560px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-sm {
    max-width: 450px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 500;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.98;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #3f78e0;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #3f78e0;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #3f78e0;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #3f78e0;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.7692307692rem;
  color: #fff;
  text-align: center;
  background-color: #3f78e0;
  border-radius: 0.4rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0
    /* rtl:ignore */
  ;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 500;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(164, 174, 198, 0.2);
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(164, 174, 198, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(164, 174, 198, 0.25);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(164, 174, 198, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(164, 174, 198, 0.25);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #343f52;
  background-color: #fff;
  border-bottom: 1px solid rgba(164, 174, 198, 0.2);
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1.25rem;
  color: #60697b;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04) !important;
}

.shadow-sm {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.02) !important;
}

.shadow-lg {
  box-shadow: 0rem 0.25rem 1.75rem rgba(30, 34, 40, 0.07) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-blue {
  border-color: #3f78e0 !important;
}

.border-purple {
  border-color: #747ed1 !important;
}

.border-violet {
  border-color: #a07cc5 !important;
}

.border-pink {
  border-color: #d16b86 !important;
}

.border-red {
  border-color: #e2626b !important;
}

.border-orange {
  border-color: #f78b77 !important;
}

.border-yellow {
  border-color: #fab758 !important;
}

.border-green {
  border-color: #6bbea3 !important;
}

.border-leaf {
  border-color: #7cb798 !important;
}

.border-aqua {
  border-color: #54a8c7 !important;
}

.border-navy {
  border-color: #343f52 !important;
}

.border-ash {
  border-color: #9499a3 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: #fefefe !important;
}

.border-gray {
  border-color: #f6f7f9 !important;
}

.border-dark {
  border-color: #262b32 !important;
}

.border-primary {
  border-color: var(--secondaryDark) !important;
}

.border-secondary {
  border-color: #aab0bc !important;
}

.border-success {
  border-color: #6bbea3 !important;
}

.border-info {
  border-color: #54a8c7 !important;
}

.border-warning {
  border-color: #fab758 !important;
}

.border-danger {
  border-color: #e2626b !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 22.5rem !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-9 {
  width: 2.25rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-11 {
  width: 3rem !important;
}

.w-12 {
  width: 3.5rem !important;
}

.w-13 {
  width: 4rem !important;
}

.w-14 {
  width: 4.5rem !important;
}

.w-15 {
  width: 5rem !important;
}

.w-16 {
  width: 6rem !important;
}

.w-17 {
  width: 7rem !important;
}

.w-18 {
  width: 8rem !important;
}

.w-19 {
  width: 9rem !important;
}

.w-20 {
  width: 10rem !important;
}

.w-21 {
  width: 12.5rem !important;
}

.w-22 {
  width: 15rem !important;
}

.w-23 {
  width: 17.5rem !important;
}

.w-24 {
  width: 20rem !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 22.5rem !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-11 {
  height: 3rem !important;
}

.h-12 {
  height: 3.5rem !important;
}

.h-13 {
  height: 4rem !important;
}

.h-14 {
  height: 4.5rem !important;
}

.h-15 {
  height: 5rem !important;
}

.h-16 {
  height: 6rem !important;
}

.h-17 {
  height: 7rem !important;
}

.h-18 {
  height: 8rem !important;
}

.h-19 {
  height: 9rem !important;
}

.h-20 {
  height: 10rem !important;
}

.h-21 {
  height: 12.5rem !important;
}

.h-22 {
  height: 15rem !important;
}

.h-23 {
  height: 17.5rem !important;
}

.h-24 {
  height: 20rem !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 3rem !important;
}

.gap-12 {
  gap: 3.5rem !important;
}

.gap-13 {
  gap: 4rem !important;
}

.gap-14 {
  gap: 4.5rem !important;
}

.gap-15 {
  gap: 5rem !important;
}

.gap-16 {
  gap: 6rem !important;
}

.gap-17 {
  gap: 7rem !important;
}

.gap-18 {
  gap: 8rem !important;
}

.gap-19 {
  gap: 9rem !important;
}

.gap-20 {
  gap: 10rem !important;
}

.gap-21 {
  gap: 12.5rem !important;
}

.gap-22 {
  gap: 15rem !important;
}

.gap-23 {
  gap: 17.5rem !important;
}

.gap-24 {
  gap: 20rem !important;
}

.gap-25 {
  gap: 22.5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 3rem !important;
}

.m-12 {
  margin: 3.5rem !important;
}

.m-13 {
  margin: 4rem !important;
}

.m-14 {
  margin: 4.5rem !important;
}

.m-15 {
  margin: 5rem !important;
}

.m-16 {
  margin: 6rem !important;
}

.m-17 {
  margin: 7rem !important;
}

.m-18 {
  margin: 8rem !important;
}

.m-19 {
  margin: 9rem !important;
}

.m-20 {
  margin: 10rem !important;
}

.m-21 {
  margin: 12.5rem !important;
}

.m-22 {
  margin: 15rem !important;
}

.m-23 {
  margin: 17.5rem !important;
}

.m-24 {
  margin: 20rem !important;
}

.m-25 {
  margin: 22.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-12 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-13 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-14 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-15 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-16 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-17 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-18 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-19 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-20 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-21 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-22 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-23 {
  margin-right: 17.5rem !important;
  margin-left: 17.5rem !important;
}

.mx-24 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-25 {
  margin-right: 22.5rem !important;
  margin-left: 22.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-12 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-13 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-14 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-15 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-16 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-17 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-18 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-19 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-20 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-21 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-22 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-23 {
  margin-top: 17.5rem !important;
  margin-bottom: 17.5rem !important;
}

.my-24 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-25 {
  margin-top: 22.5rem !important;
  margin-bottom: 22.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 3rem !important;
}

.mt-12 {
  margin-top: 3.5rem !important;
}

.mt-13 {
  margin-top: 4rem !important;
}

.mt-14 {
  margin-top: 4.5rem !important;
}

.mt-15 {
  margin-top: 5rem !important;
}

.mt-16 {
  margin-top: 6rem !important;
}

.mt-17 {
  margin-top: 7rem !important;
}

.mt-18 {
  margin-top: 8rem !important;
}

.mt-19 {
  margin-top: 9rem !important;
}

.mt-20 {
  margin-top: 10rem !important;
}

.mt-21 {
  margin-top: 12.5rem !important;
}

.mt-22 {
  margin-top: 15rem !important;
}

.mt-23 {
  margin-top: 17.5rem !important;
}

.mt-24 {
  margin-top: 20rem !important;
}

.mt-25 {
  margin-top: 22.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 3rem !important;
}

.me-12 {
  margin-right: 3.5rem !important;
}

.me-13 {
  margin-right: 4rem !important;
}

.me-14 {
  margin-right: 4.5rem !important;
}

.me-15 {
  margin-right: 5rem !important;
}

.me-16 {
  margin-right: 6rem !important;
}

.me-17 {
  margin-right: 7rem !important;
}

.me-18 {
  margin-right: 8rem !important;
}

.me-19 {
  margin-right: 9rem !important;
}

.me-20 {
  margin-right: 10rem !important;
}

.me-21 {
  margin-right: 12.5rem !important;
}

.me-22 {
  margin-right: 15rem !important;
}

.me-23 {
  margin-right: 17.5rem !important;
}

.me-24 {
  margin-right: 20rem !important;
}

.me-25 {
  margin-right: 22.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 3rem !important;
}

.mb-12 {
  margin-bottom: 3.5rem !important;
}

.mb-13 {
  margin-bottom: 4rem !important;
}

.mb-14 {
  margin-bottom: 4.5rem !important;
}

.mb-15 {
  margin-bottom: 5rem !important;
}

.mb-16 {
  margin-bottom: 6rem !important;
}

.mb-17 {
  margin-bottom: 7rem !important;
}

.mb-18 {
  margin-bottom: 8rem !important;
}

.mb-19 {
  margin-bottom: 9rem !important;
}

.mb-20 {
  margin-bottom: 10rem !important;
}

.mb-21 {
  margin-bottom: 12.5rem !important;
}

.mb-22 {
  margin-bottom: 15rem !important;
}

.mb-23 {
  margin-bottom: 17.5rem !important;
}

.mb-24 {
  margin-bottom: 20rem !important;
}

.mb-25 {
  margin-bottom: 22.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 3rem !important;
}

.ms-12 {
  margin-left: 3.5rem !important;
}

.ms-13 {
  margin-left: 4rem !important;
}

.ms-14 {
  margin-left: 4.5rem !important;
}

.ms-15 {
  margin-left: 5rem !important;
}

.ms-16 {
  margin-left: 6rem !important;
}

.ms-17 {
  margin-left: 7rem !important;
}

.ms-18 {
  margin-left: 8rem !important;
}

.ms-19 {
  margin-left: 9rem !important;
}

.ms-20 {
  margin-left: 10rem !important;
}

.ms-21 {
  margin-left: 12.5rem !important;
}

.ms-22 {
  margin-left: 15rem !important;
}

.ms-23 {
  margin-left: 17.5rem !important;
}

.ms-24 {
  margin-left: 20rem !important;
}

.ms-25 {
  margin-left: 22.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -3rem !important;
}

.m-n12 {
  margin: -3.5rem !important;
}

.m-n13 {
  margin: -4rem !important;
}

.m-n14 {
  margin: -4.5rem !important;
}

.m-n15 {
  margin: -5rem !important;
}

.m-n16 {
  margin: -6rem !important;
}

.m-n17 {
  margin: -7rem !important;
}

.m-n18 {
  margin: -8rem !important;
}

.m-n19 {
  margin: -9rem !important;
}

.m-n20 {
  margin: -10rem !important;
}

.m-n21 {
  margin: -12.5rem !important;
}

.m-n22 {
  margin: -15rem !important;
}

.m-n23 {
  margin: -17.5rem !important;
}

.m-n24 {
  margin: -20rem !important;
}

.m-n25 {
  margin: -22.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n12 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n13 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n14 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n15 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n16 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n17 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.mx-n18 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n19 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n20 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n21 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.mx-n22 {
  margin-right: -15rem !important;
  margin-left: -15rem !important;
}

.mx-n23 {
  margin-right: -17.5rem !important;
  margin-left: -17.5rem !important;
}

.mx-n24 {
  margin-right: -20rem !important;
  margin-left: -20rem !important;
}

.mx-n25 {
  margin-right: -22.5rem !important;
  margin-left: -22.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n12 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n13 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n14 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n15 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n16 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n17 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.my-n18 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n19 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n20 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n21 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.my-n22 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.my-n23 {
  margin-top: -17.5rem !important;
  margin-bottom: -17.5rem !important;
}

.my-n24 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-n25 {
  margin-top: -22.5rem !important;
  margin-bottom: -22.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -3rem !important;
}

.mt-n12 {
  margin-top: -3.5rem !important;
}

.mt-n13 {
  margin-top: -4rem !important;
}

.mt-n14 {
  margin-top: -4.5rem !important;
}

.mt-n15 {
  margin-top: -5rem !important;
}

.mt-n16 {
  margin-top: -6rem !important;
}

.mt-n17 {
  margin-top: -7rem !important;
}

.mt-n18 {
  margin-top: -8rem !important;
}

.mt-n19 {
  margin-top: -9rem !important;
}

.mt-n20 {
  margin-top: -10rem !important;
}

.mt-n21 {
  margin-top: -12.5rem !important;
}

.mt-n22 {
  margin-top: -15rem !important;
}

.mt-n23 {
  margin-top: -17.5rem !important;
}

.mt-n24 {
  margin-top: -20rem !important;
}

.mt-n25 {
  margin-top: -22.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -3rem !important;
}

.me-n12 {
  margin-right: -3.5rem !important;
}

.me-n13 {
  margin-right: -4rem !important;
}

.me-n14 {
  margin-right: -4.5rem !important;
}

.me-n15 {
  margin-right: -5rem !important;
}

.me-n16 {
  margin-right: -6rem !important;
}

.me-n17 {
  margin-right: -7rem !important;
}

.me-n18 {
  margin-right: -8rem !important;
}

.me-n19 {
  margin-right: -9rem !important;
}

.me-n20 {
  margin-right: -10rem !important;
}

.me-n21 {
  margin-right: -12.5rem !important;
}

.me-n22 {
  margin-right: -15rem !important;
}

.me-n23 {
  margin-right: -17.5rem !important;
}

.me-n24 {
  margin-right: -20rem !important;
}

.me-n25 {
  margin-right: -22.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -3rem !important;
}

.mb-n12 {
  margin-bottom: -3.5rem !important;
}

.mb-n13 {
  margin-bottom: -4rem !important;
}

.mb-n14 {
  margin-bottom: -4.5rem !important;
}

.mb-n15 {
  margin-bottom: -5rem !important;
}

.mb-n16 {
  margin-bottom: -6rem !important;
}

.mb-n17 {
  margin-bottom: -7rem !important;
}

.mb-n18 {
  margin-bottom: -8rem !important;
}

.mb-n19 {
  margin-bottom: -9rem !important;
}

.mb-n20 {
  margin-bottom: -10rem !important;
}

.mb-n21 {
  margin-bottom: -12.5rem !important;
}

.mb-n22 {
  margin-bottom: -15rem !important;
}

.mb-n23 {
  margin-bottom: -17.5rem !important;
}

.mb-n24 {
  margin-bottom: -20rem !important;
}

.mb-n25 {
  margin-bottom: -22.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -3rem !important;
}

.ms-n12 {
  margin-left: -3.5rem !important;
}

.ms-n13 {
  margin-left: -4rem !important;
}

.ms-n14 {
  margin-left: -4.5rem !important;
}

.ms-n15 {
  margin-left: -5rem !important;
}

.ms-n16 {
  margin-left: -6rem !important;
}

.ms-n17 {
  margin-left: -7rem !important;
}

.ms-n18 {
  margin-left: -8rem !important;
}

.ms-n19 {
  margin-left: -9rem !important;
}

.ms-n20 {
  margin-left: -10rem !important;
}

.ms-n21 {
  margin-left: -12.5rem !important;
}

.ms-n22 {
  margin-left: -15rem !important;
}

.ms-n23 {
  margin-left: -17.5rem !important;
}

.ms-n24 {
  margin-left: -20rem !important;
}

.ms-n25 {
  margin-left: -22.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 3rem !important;
}

.p-12 {
  padding: 3.5rem !important;
}

.p-13 {
  padding: 4rem !important;
}

.p-14 {
  padding: 4.5rem !important;
}

.p-15 {
  padding: 5rem !important;
}

.p-16 {
  padding: 6rem !important;
}

.p-17 {
  padding: 7rem !important;
}

.p-18 {
  padding: 8rem !important;
}

.p-19 {
  padding: 9rem !important;
}

.p-20 {
  padding: 10rem !important;
}

.p-21 {
  padding: 12.5rem !important;
}

.p-22 {
  padding: 15rem !important;
}

.p-23 {
  padding: 17.5rem !important;
}

.p-24 {
  padding: 20rem !important;
}

.p-25 {
  padding: 22.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-12 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-13 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-14 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-15 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-16 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-17 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-18 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-19 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-20 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-21 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-22 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.px-23 {
  padding-right: 17.5rem !important;
  padding-left: 17.5rem !important;
}

.px-24 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-25 {
  padding-right: 22.5rem !important;
  padding-left: 22.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-12 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-13 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-14 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-15 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-16 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-17 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-18 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-19 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-20 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-21 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-22 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-23 {
  padding-top: 17.5rem !important;
  padding-bottom: 17.5rem !important;
}

.py-24 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-25 {
  padding-top: 22.5rem !important;
  padding-bottom: 22.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 3rem !important;
}

.pt-12 {
  padding-top: 3.5rem !important;
}

.pt-13 {
  padding-top: 4rem !important;
}

.pt-14 {
  padding-top: 4.5rem !important;
}

.pt-15 {
  padding-top: 5rem !important;
}

.pt-16 {
  padding-top: 6rem !important;
}

.pt-17 {
  padding-top: 7rem !important;
}

.pt-18 {
  padding-top: 8rem !important;
}

.pt-19 {
  padding-top: 9rem !important;
}

.pt-20 {
  padding-top: 10rem !important;
}

.pt-21 {
  padding-top: 12.5rem !important;
}

.pt-22 {
  padding-top: 15rem !important;
}

.pt-23 {
  padding-top: 17.5rem !important;
}

.pt-24 {
  padding-top: 20rem !important;
}

.pt-25 {
  padding-top: 22.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 3rem !important;
}

.pe-12 {
  padding-right: 3.5rem !important;
}

.pe-13 {
  padding-right: 4rem !important;
}

.pe-14 {
  padding-right: 4.5rem !important;
}

.pe-15 {
  padding-right: 5rem !important;
}

.pe-16 {
  padding-right: 6rem !important;
}

.pe-17 {
  padding-right: 7rem !important;
}

.pe-18 {
  padding-right: 8rem !important;
}

.pe-19 {
  padding-right: 9rem !important;
}

.pe-20 {
  padding-right: 10rem !important;
}

.pe-21 {
  padding-right: 12.5rem !important;
}

.pe-22 {
  padding-right: 15rem !important;
}

.pe-23 {
  padding-right: 17.5rem !important;
}

.pe-24 {
  padding-right: 20rem !important;
}

.pe-25 {
  padding-right: 22.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 3rem !important;
}

.pb-12 {
  padding-bottom: 3.5rem !important;
}

.pb-13 {
  padding-bottom: 4rem !important;
}

.pb-14 {
  padding-bottom: 4.5rem !important;
}

.pb-15 {
  padding-bottom: 5rem !important;
}

.pb-16 {
  padding-bottom: 6rem !important;
}

.pb-17 {
  padding-bottom: 7rem !important;
}

.pb-18 {
  padding-bottom: 8rem !important;
}

.pb-19 {
  padding-bottom: 9rem !important;
}

.pb-20 {
  padding-bottom: 10rem !important;
}

.pb-21 {
  padding-bottom: 12.5rem !important;
}

.pb-22 {
  padding-bottom: 15rem !important;
}

.pb-23 {
  padding-bottom: 17.5rem !important;
}

.pb-24 {
  padding-bottom: 20rem !important;
}

.pb-25 {
  padding-bottom: 22.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 3rem !important;
}

.ps-12 {
  padding-left: 3.5rem !important;
}

.ps-13 {
  padding-left: 4rem !important;
}

.ps-14 {
  padding-left: 4.5rem !important;
}

.ps-15 {
  padding-left: 5rem !important;
}

.ps-16 {
  padding-left: 6rem !important;
}

.ps-17 {
  padding-left: 7rem !important;
}

.ps-18 {
  padding-left: 8rem !important;
}

.ps-19 {
  padding-left: 9rem !important;
}

.ps-20 {
  padding-left: 10rem !important;
}

.ps-21 {
  padding-left: 12.5rem !important;
}

.ps-22 {
  padding-left: 15rem !important;
}

.ps-23 {
  padding-left: 17.5rem !important;
}

.ps-24 {
  padding-left: 20rem !important;
}

.ps-25 {
  padding-left: 22.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 400 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-xs {
  line-height: 1.05 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.7 !important;
}

.lh-lg {
  line-height: 1.9 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-blue {
  --bs-text-opacity: 1;
  color: #3f78e0 !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: #747ed1 !important;
}

.text-violet {
  --bs-text-opacity: 1;
  color: #a07cc5 !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: #d16b86 !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: #e2626b !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: #f78b77 !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: #fab758 !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: #6bbea3 !important;
}

.text-leaf {
  --bs-text-opacity: 1;
  color: #7cb798 !important;
}

.text-aqua {
  --bs-text-opacity: 1;
  color: #54a8c7 !important;
}

.text-navy {
  --bs-text-opacity: 1;
  color: #343f52 !important;
}

.text-ash {
  --bs-text-opacity: 1;
  color: #9499a3 !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: #fff !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: #fefefe !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: #f6f7f9 !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: #3f78e0 !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: #aab0bc !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: #6bbea3 !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: #54a8c7 !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: #fab758 !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: #e2626b !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: #60697b !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #aab0bc !important;
}

.text-inverse {
  --bs-text-opacity: 1;
  color: #cacaca !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: #343f52 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-violet {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-violet-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-leaf {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-leaf-rgb), var(--bs-bg-opacity)) !important;
}

.bg-aqua {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-aqua-rgb), var(--bs-bg-opacity)) !important;
}

.bg-navy {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navy-rgb), var(--bs-bg-opacity)) !important;
}

.bg-ash {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-ash-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.4rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.4rem !important;
}

.rounded-3 {
  border-radius: 0.4rem !important;
}

.rounded-4 {
  border-radius: 0.8rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}

.rounded-end {
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.4rem !important;
  border-top-left-radius: 0.4rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 3rem !important;
  }

  .gap-sm-12 {
    gap: 3.5rem !important;
  }

  .gap-sm-13 {
    gap: 4rem !important;
  }

  .gap-sm-14 {
    gap: 4.5rem !important;
  }

  .gap-sm-15 {
    gap: 5rem !important;
  }

  .gap-sm-16 {
    gap: 6rem !important;
  }

  .gap-sm-17 {
    gap: 7rem !important;
  }

  .gap-sm-18 {
    gap: 8rem !important;
  }

  .gap-sm-19 {
    gap: 9rem !important;
  }

  .gap-sm-20 {
    gap: 10rem !important;
  }

  .gap-sm-21 {
    gap: 12.5rem !important;
  }

  .gap-sm-22 {
    gap: 15rem !important;
  }

  .gap-sm-23 {
    gap: 17.5rem !important;
  }

  .gap-sm-24 {
    gap: 20rem !important;
  }

  .gap-sm-25 {
    gap: 22.5rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 3rem !important;
  }

  .m-sm-12 {
    margin: 3.5rem !important;
  }

  .m-sm-13 {
    margin: 4rem !important;
  }

  .m-sm-14 {
    margin: 4.5rem !important;
  }

  .m-sm-15 {
    margin: 5rem !important;
  }

  .m-sm-16 {
    margin: 6rem !important;
  }

  .m-sm-17 {
    margin: 7rem !important;
  }

  .m-sm-18 {
    margin: 8rem !important;
  }

  .m-sm-19 {
    margin: 9rem !important;
  }

  .m-sm-20 {
    margin: 10rem !important;
  }

  .m-sm-21 {
    margin: 12.5rem !important;
  }

  .m-sm-22 {
    margin: 15rem !important;
  }

  .m-sm-23 {
    margin: 17.5rem !important;
  }

  .m-sm-24 {
    margin: 20rem !important;
  }

  .m-sm-25 {
    margin: 22.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-16 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-17 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-sm-18 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-19 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-21 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .mx-sm-22 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-sm-23 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }

  .mx-sm-24 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-sm-25 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-16 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-17 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-sm-18 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-19 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-21 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .my-sm-22 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-sm-23 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }

  .my-sm-24 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-sm-25 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 3rem !important;
  }

  .mt-sm-12 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-13 {
    margin-top: 4rem !important;
  }

  .mt-sm-14 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 5rem !important;
  }

  .mt-sm-16 {
    margin-top: 6rem !important;
  }

  .mt-sm-17 {
    margin-top: 7rem !important;
  }

  .mt-sm-18 {
    margin-top: 8rem !important;
  }

  .mt-sm-19 {
    margin-top: 9rem !important;
  }

  .mt-sm-20 {
    margin-top: 10rem !important;
  }

  .mt-sm-21 {
    margin-top: 12.5rem !important;
  }

  .mt-sm-22 {
    margin-top: 15rem !important;
  }

  .mt-sm-23 {
    margin-top: 17.5rem !important;
  }

  .mt-sm-24 {
    margin-top: 20rem !important;
  }

  .mt-sm-25 {
    margin-top: 22.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 3rem !important;
  }

  .me-sm-12 {
    margin-right: 3.5rem !important;
  }

  .me-sm-13 {
    margin-right: 4rem !important;
  }

  .me-sm-14 {
    margin-right: 4.5rem !important;
  }

  .me-sm-15 {
    margin-right: 5rem !important;
  }

  .me-sm-16 {
    margin-right: 6rem !important;
  }

  .me-sm-17 {
    margin-right: 7rem !important;
  }

  .me-sm-18 {
    margin-right: 8rem !important;
  }

  .me-sm-19 {
    margin-right: 9rem !important;
  }

  .me-sm-20 {
    margin-right: 10rem !important;
  }

  .me-sm-21 {
    margin-right: 12.5rem !important;
  }

  .me-sm-22 {
    margin-right: 15rem !important;
  }

  .me-sm-23 {
    margin-right: 17.5rem !important;
  }

  .me-sm-24 {
    margin-right: 20rem !important;
  }

  .me-sm-25 {
    margin-right: 22.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 7rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-21 {
    margin-bottom: 12.5rem !important;
  }

  .mb-sm-22 {
    margin-bottom: 15rem !important;
  }

  .mb-sm-23 {
    margin-bottom: 17.5rem !important;
  }

  .mb-sm-24 {
    margin-bottom: 20rem !important;
  }

  .mb-sm-25 {
    margin-bottom: 22.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 3rem !important;
  }

  .ms-sm-12 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-13 {
    margin-left: 4rem !important;
  }

  .ms-sm-14 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 5rem !important;
  }

  .ms-sm-16 {
    margin-left: 6rem !important;
  }

  .ms-sm-17 {
    margin-left: 7rem !important;
  }

  .ms-sm-18 {
    margin-left: 8rem !important;
  }

  .ms-sm-19 {
    margin-left: 9rem !important;
  }

  .ms-sm-20 {
    margin-left: 10rem !important;
  }

  .ms-sm-21 {
    margin-left: 12.5rem !important;
  }

  .ms-sm-22 {
    margin-left: 15rem !important;
  }

  .ms-sm-23 {
    margin-left: 17.5rem !important;
  }

  .ms-sm-24 {
    margin-left: 20rem !important;
  }

  .ms-sm-25 {
    margin-left: 22.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -3rem !important;
  }

  .m-sm-n12 {
    margin: -3.5rem !important;
  }

  .m-sm-n13 {
    margin: -4rem !important;
  }

  .m-sm-n14 {
    margin: -4.5rem !important;
  }

  .m-sm-n15 {
    margin: -5rem !important;
  }

  .m-sm-n16 {
    margin: -6rem !important;
  }

  .m-sm-n17 {
    margin: -7rem !important;
  }

  .m-sm-n18 {
    margin: -8rem !important;
  }

  .m-sm-n19 {
    margin: -9rem !important;
  }

  .m-sm-n20 {
    margin: -10rem !important;
  }

  .m-sm-n21 {
    margin: -12.5rem !important;
  }

  .m-sm-n22 {
    margin: -15rem !important;
  }

  .m-sm-n23 {
    margin: -17.5rem !important;
  }

  .m-sm-n24 {
    margin: -20rem !important;
  }

  .m-sm-n25 {
    margin: -22.5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n16 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n17 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-sm-n18 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n19 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-sm-n20 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n21 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .mx-sm-n22 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .mx-sm-n23 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }

  .mx-sm-n24 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-sm-n25 {
    margin-right: -22.5rem !important;
    margin-left: -22.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n16 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n17 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-sm-n18 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n19 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-sm-n20 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n21 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .my-sm-n22 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .my-sm-n23 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }

  .my-sm-n24 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-sm-n25 {
    margin-top: -22.5rem !important;
    margin-bottom: -22.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -3rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n13 {
    margin-top: -4rem !important;
  }

  .mt-sm-n14 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -5rem !important;
  }

  .mt-sm-n16 {
    margin-top: -6rem !important;
  }

  .mt-sm-n17 {
    margin-top: -7rem !important;
  }

  .mt-sm-n18 {
    margin-top: -8rem !important;
  }

  .mt-sm-n19 {
    margin-top: -9rem !important;
  }

  .mt-sm-n20 {
    margin-top: -10rem !important;
  }

  .mt-sm-n21 {
    margin-top: -12.5rem !important;
  }

  .mt-sm-n22 {
    margin-top: -15rem !important;
  }

  .mt-sm-n23 {
    margin-top: -17.5rem !important;
  }

  .mt-sm-n24 {
    margin-top: -20rem !important;
  }

  .mt-sm-n25 {
    margin-top: -22.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -3rem !important;
  }

  .me-sm-n12 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n13 {
    margin-right: -4rem !important;
  }

  .me-sm-n14 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -5rem !important;
  }

  .me-sm-n16 {
    margin-right: -6rem !important;
  }

  .me-sm-n17 {
    margin-right: -7rem !important;
  }

  .me-sm-n18 {
    margin-right: -8rem !important;
  }

  .me-sm-n19 {
    margin-right: -9rem !important;
  }

  .me-sm-n20 {
    margin-right: -10rem !important;
  }

  .me-sm-n21 {
    margin-right: -12.5rem !important;
  }

  .me-sm-n22 {
    margin-right: -15rem !important;
  }

  .me-sm-n23 {
    margin-right: -17.5rem !important;
  }

  .me-sm-n24 {
    margin-right: -20rem !important;
  }

  .me-sm-n25 {
    margin-right: -22.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -7rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -9rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n21 {
    margin-bottom: -12.5rem !important;
  }

  .mb-sm-n22 {
    margin-bottom: -15rem !important;
  }

  .mb-sm-n23 {
    margin-bottom: -17.5rem !important;
  }

  .mb-sm-n24 {
    margin-bottom: -20rem !important;
  }

  .mb-sm-n25 {
    margin-bottom: -22.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -3rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n13 {
    margin-left: -4rem !important;
  }

  .ms-sm-n14 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -5rem !important;
  }

  .ms-sm-n16 {
    margin-left: -6rem !important;
  }

  .ms-sm-n17 {
    margin-left: -7rem !important;
  }

  .ms-sm-n18 {
    margin-left: -8rem !important;
  }

  .ms-sm-n19 {
    margin-left: -9rem !important;
  }

  .ms-sm-n20 {
    margin-left: -10rem !important;
  }

  .ms-sm-n21 {
    margin-left: -12.5rem !important;
  }

  .ms-sm-n22 {
    margin-left: -15rem !important;
  }

  .ms-sm-n23 {
    margin-left: -17.5rem !important;
  }

  .ms-sm-n24 {
    margin-left: -20rem !important;
  }

  .ms-sm-n25 {
    margin-left: -22.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 3rem !important;
  }

  .p-sm-12 {
    padding: 3.5rem !important;
  }

  .p-sm-13 {
    padding: 4rem !important;
  }

  .p-sm-14 {
    padding: 4.5rem !important;
  }

  .p-sm-15 {
    padding: 5rem !important;
  }

  .p-sm-16 {
    padding: 6rem !important;
  }

  .p-sm-17 {
    padding: 7rem !important;
  }

  .p-sm-18 {
    padding: 8rem !important;
  }

  .p-sm-19 {
    padding: 9rem !important;
  }

  .p-sm-20 {
    padding: 10rem !important;
  }

  .p-sm-21 {
    padding: 12.5rem !important;
  }

  .p-sm-22 {
    padding: 15rem !important;
  }

  .p-sm-23 {
    padding: 17.5rem !important;
  }

  .p-sm-24 {
    padding: 20rem !important;
  }

  .p-sm-25 {
    padding: 22.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-16 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-17 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-sm-18 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-19 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-sm-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-21 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .px-sm-22 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-sm-23 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }

  .px-sm-24 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-sm-25 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-16 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-17 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-sm-18 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-sm-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-21 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .py-sm-22 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-sm-23 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }

  .py-sm-24 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-sm-25 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 3rem !important;
  }

  .pt-sm-12 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-13 {
    padding-top: 4rem !important;
  }

  .pt-sm-14 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 5rem !important;
  }

  .pt-sm-16 {
    padding-top: 6rem !important;
  }

  .pt-sm-17 {
    padding-top: 7rem !important;
  }

  .pt-sm-18 {
    padding-top: 8rem !important;
  }

  .pt-sm-19 {
    padding-top: 9rem !important;
  }

  .pt-sm-20 {
    padding-top: 10rem !important;
  }

  .pt-sm-21 {
    padding-top: 12.5rem !important;
  }

  .pt-sm-22 {
    padding-top: 15rem !important;
  }

  .pt-sm-23 {
    padding-top: 17.5rem !important;
  }

  .pt-sm-24 {
    padding-top: 20rem !important;
  }

  .pt-sm-25 {
    padding-top: 22.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 3rem !important;
  }

  .pe-sm-12 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-13 {
    padding-right: 4rem !important;
  }

  .pe-sm-14 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 5rem !important;
  }

  .pe-sm-16 {
    padding-right: 6rem !important;
  }

  .pe-sm-17 {
    padding-right: 7rem !important;
  }

  .pe-sm-18 {
    padding-right: 8rem !important;
  }

  .pe-sm-19 {
    padding-right: 9rem !important;
  }

  .pe-sm-20 {
    padding-right: 10rem !important;
  }

  .pe-sm-21 {
    padding-right: 12.5rem !important;
  }

  .pe-sm-22 {
    padding-right: 15rem !important;
  }

  .pe-sm-23 {
    padding-right: 17.5rem !important;
  }

  .pe-sm-24 {
    padding-right: 20rem !important;
  }

  .pe-sm-25 {
    padding-right: 22.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 7rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 9rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-21 {
    padding-bottom: 12.5rem !important;
  }

  .pb-sm-22 {
    padding-bottom: 15rem !important;
  }

  .pb-sm-23 {
    padding-bottom: 17.5rem !important;
  }

  .pb-sm-24 {
    padding-bottom: 20rem !important;
  }

  .pb-sm-25 {
    padding-bottom: 22.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 3rem !important;
  }

  .ps-sm-12 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-13 {
    padding-left: 4rem !important;
  }

  .ps-sm-14 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 5rem !important;
  }

  .ps-sm-16 {
    padding-left: 6rem !important;
  }

  .ps-sm-17 {
    padding-left: 7rem !important;
  }

  .ps-sm-18 {
    padding-left: 8rem !important;
  }

  .ps-sm-19 {
    padding-left: 9rem !important;
  }

  .ps-sm-20 {
    padding-left: 10rem !important;
  }

  .ps-sm-21 {
    padding-left: 12.5rem !important;
  }

  .ps-sm-22 {
    padding-left: 15rem !important;
  }

  .ps-sm-23 {
    padding-left: 17.5rem !important;
  }

  .ps-sm-24 {
    padding-left: 20rem !important;
  }

  .ps-sm-25 {
    padding-left: 22.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 3rem !important;
  }

  .gap-md-12 {
    gap: 3.5rem !important;
  }

  .gap-md-13 {
    gap: 4rem !important;
  }

  .gap-md-14 {
    gap: 4.5rem !important;
  }

  .gap-md-15 {
    gap: 5rem !important;
  }

  .gap-md-16 {
    gap: 6rem !important;
  }

  .gap-md-17 {
    gap: 7rem !important;
  }

  .gap-md-18 {
    gap: 8rem !important;
  }

  .gap-md-19 {
    gap: 9rem !important;
  }

  .gap-md-20 {
    gap: 10rem !important;
  }

  .gap-md-21 {
    gap: 12.5rem !important;
  }

  .gap-md-22 {
    gap: 15rem !important;
  }

  .gap-md-23 {
    gap: 17.5rem !important;
  }

  .gap-md-24 {
    gap: 20rem !important;
  }

  .gap-md-25 {
    gap: 22.5rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 3rem !important;
  }

  .m-md-12 {
    margin: 3.5rem !important;
  }

  .m-md-13 {
    margin: 4rem !important;
  }

  .m-md-14 {
    margin: 4.5rem !important;
  }

  .m-md-15 {
    margin: 5rem !important;
  }

  .m-md-16 {
    margin: 6rem !important;
  }

  .m-md-17 {
    margin: 7rem !important;
  }

  .m-md-18 {
    margin: 8rem !important;
  }

  .m-md-19 {
    margin: 9rem !important;
  }

  .m-md-20 {
    margin: 10rem !important;
  }

  .m-md-21 {
    margin: 12.5rem !important;
  }

  .m-md-22 {
    margin: 15rem !important;
  }

  .m-md-23 {
    margin: 17.5rem !important;
  }

  .m-md-24 {
    margin: 20rem !important;
  }

  .m-md-25 {
    margin: 22.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-16 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-17 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-md-18 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-19 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-21 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .mx-md-22 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-md-23 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }

  .mx-md-24 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-md-25 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-16 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-17 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-md-18 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-19 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-21 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .my-md-22 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-md-23 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }

  .my-md-24 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-md-25 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 3rem !important;
  }

  .mt-md-12 {
    margin-top: 3.5rem !important;
  }

  .mt-md-13 {
    margin-top: 4rem !important;
  }

  .mt-md-14 {
    margin-top: 4.5rem !important;
  }

  .mt-md-15 {
    margin-top: 5rem !important;
  }

  .mt-md-16 {
    margin-top: 6rem !important;
  }

  .mt-md-17 {
    margin-top: 7rem !important;
  }

  .mt-md-18 {
    margin-top: 8rem !important;
  }

  .mt-md-19 {
    margin-top: 9rem !important;
  }

  .mt-md-20 {
    margin-top: 10rem !important;
  }

  .mt-md-21 {
    margin-top: 12.5rem !important;
  }

  .mt-md-22 {
    margin-top: 15rem !important;
  }

  .mt-md-23 {
    margin-top: 17.5rem !important;
  }

  .mt-md-24 {
    margin-top: 20rem !important;
  }

  .mt-md-25 {
    margin-top: 22.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 3rem !important;
  }

  .me-md-12 {
    margin-right: 3.5rem !important;
  }

  .me-md-13 {
    margin-right: 4rem !important;
  }

  .me-md-14 {
    margin-right: 4.5rem !important;
  }

  .me-md-15 {
    margin-right: 5rem !important;
  }

  .me-md-16 {
    margin-right: 6rem !important;
  }

  .me-md-17 {
    margin-right: 7rem !important;
  }

  .me-md-18 {
    margin-right: 8rem !important;
  }

  .me-md-19 {
    margin-right: 9rem !important;
  }

  .me-md-20 {
    margin-right: 10rem !important;
  }

  .me-md-21 {
    margin-right: 12.5rem !important;
  }

  .me-md-22 {
    margin-right: 15rem !important;
  }

  .me-md-23 {
    margin-right: 17.5rem !important;
  }

  .me-md-24 {
    margin-right: 20rem !important;
  }

  .me-md-25 {
    margin-right: 22.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 3rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-13 {
    margin-bottom: 4rem !important;
  }

  .mb-md-14 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 5rem !important;
  }

  .mb-md-16 {
    margin-bottom: 6rem !important;
  }

  .mb-md-17 {
    margin-bottom: 7rem !important;
  }

  .mb-md-18 {
    margin-bottom: 8rem !important;
  }

  .mb-md-19 {
    margin-bottom: 9rem !important;
  }

  .mb-md-20 {
    margin-bottom: 10rem !important;
  }

  .mb-md-21 {
    margin-bottom: 12.5rem !important;
  }

  .mb-md-22 {
    margin-bottom: 15rem !important;
  }

  .mb-md-23 {
    margin-bottom: 17.5rem !important;
  }

  .mb-md-24 {
    margin-bottom: 20rem !important;
  }

  .mb-md-25 {
    margin-bottom: 22.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 3rem !important;
  }

  .ms-md-12 {
    margin-left: 3.5rem !important;
  }

  .ms-md-13 {
    margin-left: 4rem !important;
  }

  .ms-md-14 {
    margin-left: 4.5rem !important;
  }

  .ms-md-15 {
    margin-left: 5rem !important;
  }

  .ms-md-16 {
    margin-left: 6rem !important;
  }

  .ms-md-17 {
    margin-left: 7rem !important;
  }

  .ms-md-18 {
    margin-left: 8rem !important;
  }

  .ms-md-19 {
    margin-left: 9rem !important;
  }

  .ms-md-20 {
    margin-left: 10rem !important;
  }

  .ms-md-21 {
    margin-left: 12.5rem !important;
  }

  .ms-md-22 {
    margin-left: 15rem !important;
  }

  .ms-md-23 {
    margin-left: 17.5rem !important;
  }

  .ms-md-24 {
    margin-left: 20rem !important;
  }

  .ms-md-25 {
    margin-left: 22.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -3rem !important;
  }

  .m-md-n12 {
    margin: -3.5rem !important;
  }

  .m-md-n13 {
    margin: -4rem !important;
  }

  .m-md-n14 {
    margin: -4.5rem !important;
  }

  .m-md-n15 {
    margin: -5rem !important;
  }

  .m-md-n16 {
    margin: -6rem !important;
  }

  .m-md-n17 {
    margin: -7rem !important;
  }

  .m-md-n18 {
    margin: -8rem !important;
  }

  .m-md-n19 {
    margin: -9rem !important;
  }

  .m-md-n20 {
    margin: -10rem !important;
  }

  .m-md-n21 {
    margin: -12.5rem !important;
  }

  .m-md-n22 {
    margin: -15rem !important;
  }

  .m-md-n23 {
    margin: -17.5rem !important;
  }

  .m-md-n24 {
    margin: -20rem !important;
  }

  .m-md-n25 {
    margin: -22.5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n16 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n17 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-md-n18 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n19 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-md-n20 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n21 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .mx-md-n22 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .mx-md-n23 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }

  .mx-md-n24 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-md-n25 {
    margin-right: -22.5rem !important;
    margin-left: -22.5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n16 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n17 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-md-n18 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n19 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-md-n20 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n21 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .my-md-n22 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .my-md-n23 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }

  .my-md-n24 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-md-n25 {
    margin-top: -22.5rem !important;
    margin-bottom: -22.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -3rem !important;
  }

  .mt-md-n12 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n13 {
    margin-top: -4rem !important;
  }

  .mt-md-n14 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -5rem !important;
  }

  .mt-md-n16 {
    margin-top: -6rem !important;
  }

  .mt-md-n17 {
    margin-top: -7rem !important;
  }

  .mt-md-n18 {
    margin-top: -8rem !important;
  }

  .mt-md-n19 {
    margin-top: -9rem !important;
  }

  .mt-md-n20 {
    margin-top: -10rem !important;
  }

  .mt-md-n21 {
    margin-top: -12.5rem !important;
  }

  .mt-md-n22 {
    margin-top: -15rem !important;
  }

  .mt-md-n23 {
    margin-top: -17.5rem !important;
  }

  .mt-md-n24 {
    margin-top: -20rem !important;
  }

  .mt-md-n25 {
    margin-top: -22.5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -3rem !important;
  }

  .me-md-n12 {
    margin-right: -3.5rem !important;
  }

  .me-md-n13 {
    margin-right: -4rem !important;
  }

  .me-md-n14 {
    margin-right: -4.5rem !important;
  }

  .me-md-n15 {
    margin-right: -5rem !important;
  }

  .me-md-n16 {
    margin-right: -6rem !important;
  }

  .me-md-n17 {
    margin-right: -7rem !important;
  }

  .me-md-n18 {
    margin-right: -8rem !important;
  }

  .me-md-n19 {
    margin-right: -9rem !important;
  }

  .me-md-n20 {
    margin-right: -10rem !important;
  }

  .me-md-n21 {
    margin-right: -12.5rem !important;
  }

  .me-md-n22 {
    margin-right: -15rem !important;
  }

  .me-md-n23 {
    margin-right: -17.5rem !important;
  }

  .me-md-n24 {
    margin-right: -20rem !important;
  }

  .me-md-n25 {
    margin-right: -22.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -7rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -9rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n21 {
    margin-bottom: -12.5rem !important;
  }

  .mb-md-n22 {
    margin-bottom: -15rem !important;
  }

  .mb-md-n23 {
    margin-bottom: -17.5rem !important;
  }

  .mb-md-n24 {
    margin-bottom: -20rem !important;
  }

  .mb-md-n25 {
    margin-bottom: -22.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -3rem !important;
  }

  .ms-md-n12 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n13 {
    margin-left: -4rem !important;
  }

  .ms-md-n14 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -5rem !important;
  }

  .ms-md-n16 {
    margin-left: -6rem !important;
  }

  .ms-md-n17 {
    margin-left: -7rem !important;
  }

  .ms-md-n18 {
    margin-left: -8rem !important;
  }

  .ms-md-n19 {
    margin-left: -9rem !important;
  }

  .ms-md-n20 {
    margin-left: -10rem !important;
  }

  .ms-md-n21 {
    margin-left: -12.5rem !important;
  }

  .ms-md-n22 {
    margin-left: -15rem !important;
  }

  .ms-md-n23 {
    margin-left: -17.5rem !important;
  }

  .ms-md-n24 {
    margin-left: -20rem !important;
  }

  .ms-md-n25 {
    margin-left: -22.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 3rem !important;
  }

  .p-md-12 {
    padding: 3.5rem !important;
  }

  .p-md-13 {
    padding: 4rem !important;
  }

  .p-md-14 {
    padding: 4.5rem !important;
  }

  .p-md-15 {
    padding: 5rem !important;
  }

  .p-md-16 {
    padding: 6rem !important;
  }

  .p-md-17 {
    padding: 7rem !important;
  }

  .p-md-18 {
    padding: 8rem !important;
  }

  .p-md-19 {
    padding: 9rem !important;
  }

  .p-md-20 {
    padding: 10rem !important;
  }

  .p-md-21 {
    padding: 12.5rem !important;
  }

  .p-md-22 {
    padding: 15rem !important;
  }

  .p-md-23 {
    padding: 17.5rem !important;
  }

  .p-md-24 {
    padding: 20rem !important;
  }

  .p-md-25 {
    padding: 22.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-16 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-17 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-md-18 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-19 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-md-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-21 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .px-md-22 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-md-23 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }

  .px-md-24 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-md-25 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-16 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-17 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-md-18 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-md-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-21 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .py-md-22 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-md-23 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }

  .py-md-24 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-md-25 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 3rem !important;
  }

  .pt-md-12 {
    padding-top: 3.5rem !important;
  }

  .pt-md-13 {
    padding-top: 4rem !important;
  }

  .pt-md-14 {
    padding-top: 4.5rem !important;
  }

  .pt-md-15 {
    padding-top: 5rem !important;
  }

  .pt-md-16 {
    padding-top: 6rem !important;
  }

  .pt-md-17 {
    padding-top: 7rem !important;
  }

  .pt-md-18 {
    padding-top: 8rem !important;
  }

  .pt-md-19 {
    padding-top: 9rem !important;
  }

  .pt-md-20 {
    padding-top: 10rem !important;
  }

  .pt-md-21 {
    padding-top: 12.5rem !important;
  }

  .pt-md-22 {
    padding-top: 15rem !important;
  }

  .pt-md-23 {
    padding-top: 17.5rem !important;
  }

  .pt-md-24 {
    padding-top: 20rem !important;
  }

  .pt-md-25 {
    padding-top: 22.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 3rem !important;
  }

  .pe-md-12 {
    padding-right: 3.5rem !important;
  }

  .pe-md-13 {
    padding-right: 4rem !important;
  }

  .pe-md-14 {
    padding-right: 4.5rem !important;
  }

  .pe-md-15 {
    padding-right: 5rem !important;
  }

  .pe-md-16 {
    padding-right: 6rem !important;
  }

  .pe-md-17 {
    padding-right: 7rem !important;
  }

  .pe-md-18 {
    padding-right: 8rem !important;
  }

  .pe-md-19 {
    padding-right: 9rem !important;
  }

  .pe-md-20 {
    padding-right: 10rem !important;
  }

  .pe-md-21 {
    padding-right: 12.5rem !important;
  }

  .pe-md-22 {
    padding-right: 15rem !important;
  }

  .pe-md-23 {
    padding-right: 17.5rem !important;
  }

  .pe-md-24 {
    padding-right: 20rem !important;
  }

  .pe-md-25 {
    padding-right: 22.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 3rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-13 {
    padding-bottom: 4rem !important;
  }

  .pb-md-14 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 5rem !important;
  }

  .pb-md-16 {
    padding-bottom: 6rem !important;
  }

  .pb-md-17 {
    padding-bottom: 7rem !important;
  }

  .pb-md-18 {
    padding-bottom: 8rem !important;
  }

  .pb-md-19 {
    padding-bottom: 9rem !important;
  }

  .pb-md-20 {
    padding-bottom: 10rem !important;
  }

  .pb-md-21 {
    padding-bottom: 12.5rem !important;
  }

  .pb-md-22 {
    padding-bottom: 15rem !important;
  }

  .pb-md-23 {
    padding-bottom: 17.5rem !important;
  }

  .pb-md-24 {
    padding-bottom: 20rem !important;
  }

  .pb-md-25 {
    padding-bottom: 22.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 3rem !important;
  }

  .ps-md-12 {
    padding-left: 3.5rem !important;
  }

  .ps-md-13 {
    padding-left: 4rem !important;
  }

  .ps-md-14 {
    padding-left: 4.5rem !important;
  }

  .ps-md-15 {
    padding-left: 5rem !important;
  }

  .ps-md-16 {
    padding-left: 6rem !important;
  }

  .ps-md-17 {
    padding-left: 7rem !important;
  }

  .ps-md-18 {
    padding-left: 8rem !important;
  }

  .ps-md-19 {
    padding-left: 9rem !important;
  }

  .ps-md-20 {
    padding-left: 10rem !important;
  }

  .ps-md-21 {
    padding-left: 12.5rem !important;
  }

  .ps-md-22 {
    padding-left: 15rem !important;
  }

  .ps-md-23 {
    padding-left: 17.5rem !important;
  }

  .ps-md-24 {
    padding-left: 20rem !important;
  }

  .ps-md-25 {
    padding-left: 22.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 3rem !important;
  }

  .gap-lg-12 {
    gap: 3.5rem !important;
  }

  .gap-lg-13 {
    gap: 4rem !important;
  }

  .gap-lg-14 {
    gap: 4.5rem !important;
  }

  .gap-lg-15 {
    gap: 5rem !important;
  }

  .gap-lg-16 {
    gap: 6rem !important;
  }

  .gap-lg-17 {
    gap: 7rem !important;
  }

  .gap-lg-18 {
    gap: 8rem !important;
  }

  .gap-lg-19 {
    gap: 9rem !important;
  }

  .gap-lg-20 {
    gap: 10rem !important;
  }

  .gap-lg-21 {
    gap: 12.5rem !important;
  }

  .gap-lg-22 {
    gap: 15rem !important;
  }

  .gap-lg-23 {
    gap: 17.5rem !important;
  }

  .gap-lg-24 {
    gap: 20rem !important;
  }

  .gap-lg-25 {
    gap: 22.5rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 3rem !important;
  }

  .m-lg-12 {
    margin: 3.5rem !important;
  }

  .m-lg-13 {
    margin: 4rem !important;
  }

  .m-lg-14 {
    margin: 4.5rem !important;
  }

  .m-lg-15 {
    margin: 5rem !important;
  }

  .m-lg-16 {
    margin: 6rem !important;
  }

  .m-lg-17 {
    margin: 7rem !important;
  }

  .m-lg-18 {
    margin: 8rem !important;
  }

  .m-lg-19 {
    margin: 9rem !important;
  }

  .m-lg-20 {
    margin: 10rem !important;
  }

  .m-lg-21 {
    margin: 12.5rem !important;
  }

  .m-lg-22 {
    margin: 15rem !important;
  }

  .m-lg-23 {
    margin: 17.5rem !important;
  }

  .m-lg-24 {
    margin: 20rem !important;
  }

  .m-lg-25 {
    margin: 22.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-16 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-17 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-lg-18 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-19 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-21 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .mx-lg-22 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-lg-23 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }

  .mx-lg-24 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-lg-25 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-16 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-17 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-lg-18 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-19 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-21 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .my-lg-22 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-lg-23 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }

  .my-lg-24 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-lg-25 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 3rem !important;
  }

  .mt-lg-12 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-13 {
    margin-top: 4rem !important;
  }

  .mt-lg-14 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 5rem !important;
  }

  .mt-lg-16 {
    margin-top: 6rem !important;
  }

  .mt-lg-17 {
    margin-top: 7rem !important;
  }

  .mt-lg-18 {
    margin-top: 8rem !important;
  }

  .mt-lg-19 {
    margin-top: 9rem !important;
  }

  .mt-lg-20 {
    margin-top: 10rem !important;
  }

  .mt-lg-21 {
    margin-top: 12.5rem !important;
  }

  .mt-lg-22 {
    margin-top: 15rem !important;
  }

  .mt-lg-23 {
    margin-top: 17.5rem !important;
  }

  .mt-lg-24 {
    margin-top: 20rem !important;
  }

  .mt-lg-25 {
    margin-top: 22.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 3rem !important;
  }

  .me-lg-12 {
    margin-right: 3.5rem !important;
  }

  .me-lg-13 {
    margin-right: 4rem !important;
  }

  .me-lg-14 {
    margin-right: 4.5rem !important;
  }

  .me-lg-15 {
    margin-right: 5rem !important;
  }

  .me-lg-16 {
    margin-right: 6rem !important;
  }

  .me-lg-17 {
    margin-right: 7rem !important;
  }

  .me-lg-18 {
    margin-right: 8rem !important;
  }

  .me-lg-19 {
    margin-right: 9rem !important;
  }

  .me-lg-20 {
    margin-right: 10rem !important;
  }

  .me-lg-21 {
    margin-right: 12.5rem !important;
  }

  .me-lg-22 {
    margin-right: 15rem !important;
  }

  .me-lg-23 {
    margin-right: 17.5rem !important;
  }

  .me-lg-24 {
    margin-right: 20rem !important;
  }

  .me-lg-25 {
    margin-right: 22.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 7rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-21 {
    margin-bottom: 12.5rem !important;
  }

  .mb-lg-22 {
    margin-bottom: 15rem !important;
  }

  .mb-lg-23 {
    margin-bottom: 17.5rem !important;
  }

  .mb-lg-24 {
    margin-bottom: 20rem !important;
  }

  .mb-lg-25 {
    margin-bottom: 22.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 3rem !important;
  }

  .ms-lg-12 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-13 {
    margin-left: 4rem !important;
  }

  .ms-lg-14 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 5rem !important;
  }

  .ms-lg-16 {
    margin-left: 6rem !important;
  }

  .ms-lg-17 {
    margin-left: 7rem !important;
  }

  .ms-lg-18 {
    margin-left: 8rem !important;
  }

  .ms-lg-19 {
    margin-left: 9rem !important;
  }

  .ms-lg-20 {
    margin-left: 10rem !important;
  }

  .ms-lg-21 {
    margin-left: 12.5rem !important;
  }

  .ms-lg-22 {
    margin-left: 15rem !important;
  }

  .ms-lg-23 {
    margin-left: 17.5rem !important;
  }

  .ms-lg-24 {
    margin-left: 20rem !important;
  }

  .ms-lg-25 {
    margin-left: 22.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -3rem !important;
  }

  .m-lg-n12 {
    margin: -3.5rem !important;
  }

  .m-lg-n13 {
    margin: -4rem !important;
  }

  .m-lg-n14 {
    margin: -4.5rem !important;
  }

  .m-lg-n15 {
    margin: -5rem !important;
  }

  .m-lg-n16 {
    margin: -6rem !important;
  }

  .m-lg-n17 {
    margin: -7rem !important;
  }

  .m-lg-n18 {
    margin: -8rem !important;
  }

  .m-lg-n19 {
    margin: -9rem !important;
  }

  .m-lg-n20 {
    margin: -10rem !important;
  }

  .m-lg-n21 {
    margin: -12.5rem !important;
  }

  .m-lg-n22 {
    margin: -15rem !important;
  }

  .m-lg-n23 {
    margin: -17.5rem !important;
  }

  .m-lg-n24 {
    margin: -20rem !important;
  }

  .m-lg-n25 {
    margin: -22.5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n16 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n17 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-lg-n18 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n19 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-lg-n20 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n21 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .mx-lg-n22 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .mx-lg-n23 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }

  .mx-lg-n24 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-lg-n25 {
    margin-right: -22.5rem !important;
    margin-left: -22.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n16 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n17 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-lg-n18 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n19 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-lg-n20 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n21 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .my-lg-n22 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .my-lg-n23 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }

  .my-lg-n24 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-lg-n25 {
    margin-top: -22.5rem !important;
    margin-bottom: -22.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -3rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n13 {
    margin-top: -4rem !important;
  }

  .mt-lg-n14 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -5rem !important;
  }

  .mt-lg-n16 {
    margin-top: -6rem !important;
  }

  .mt-lg-n17 {
    margin-top: -7rem !important;
  }

  .mt-lg-n18 {
    margin-top: -8rem !important;
  }

  .mt-lg-n19 {
    margin-top: -9rem !important;
  }

  .mt-lg-n20 {
    margin-top: -10rem !important;
  }

  .mt-lg-n21 {
    margin-top: -12.5rem !important;
  }

  .mt-lg-n22 {
    margin-top: -15rem !important;
  }

  .mt-lg-n23 {
    margin-top: -17.5rem !important;
  }

  .mt-lg-n24 {
    margin-top: -20rem !important;
  }

  .mt-lg-n25 {
    margin-top: -22.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -3rem !important;
  }

  .me-lg-n12 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n13 {
    margin-right: -4rem !important;
  }

  .me-lg-n14 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -5rem !important;
  }

  .me-lg-n16 {
    margin-right: -6rem !important;
  }

  .me-lg-n17 {
    margin-right: -7rem !important;
  }

  .me-lg-n18 {
    margin-right: -8rem !important;
  }

  .me-lg-n19 {
    margin-right: -9rem !important;
  }

  .me-lg-n20 {
    margin-right: -10rem !important;
  }

  .me-lg-n21 {
    margin-right: -12.5rem !important;
  }

  .me-lg-n22 {
    margin-right: -15rem !important;
  }

  .me-lg-n23 {
    margin-right: -17.5rem !important;
  }

  .me-lg-n24 {
    margin-right: -20rem !important;
  }

  .me-lg-n25 {
    margin-right: -22.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -7rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -9rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n21 {
    margin-bottom: -12.5rem !important;
  }

  .mb-lg-n22 {
    margin-bottom: -15rem !important;
  }

  .mb-lg-n23 {
    margin-bottom: -17.5rem !important;
  }

  .mb-lg-n24 {
    margin-bottom: -20rem !important;
  }

  .mb-lg-n25 {
    margin-bottom: -22.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -3rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n13 {
    margin-left: -4rem !important;
  }

  .ms-lg-n14 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -5rem !important;
  }

  .ms-lg-n16 {
    margin-left: -6rem !important;
  }

  .ms-lg-n17 {
    margin-left: -7rem !important;
  }

  .ms-lg-n18 {
    margin-left: -8rem !important;
  }

  .ms-lg-n19 {
    margin-left: -9rem !important;
  }

  .ms-lg-n20 {
    margin-left: -10rem !important;
  }

  .ms-lg-n21 {
    margin-left: -12.5rem !important;
  }

  .ms-lg-n22 {
    margin-left: -15rem !important;
  }

  .ms-lg-n23 {
    margin-left: -17.5rem !important;
  }

  .ms-lg-n24 {
    margin-left: -20rem !important;
  }

  .ms-lg-n25 {
    margin-left: -22.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 3rem !important;
  }

  .p-lg-12 {
    padding: 3.5rem !important;
  }

  .p-lg-13 {
    padding: 4rem !important;
  }

  .p-lg-14 {
    padding: 4.5rem !important;
  }

  .p-lg-15 {
    padding: 5rem !important;
  }

  .p-lg-16 {
    padding: 6rem !important;
  }

  .p-lg-17 {
    padding: 7rem !important;
  }

  .p-lg-18 {
    padding: 8rem !important;
  }

  .p-lg-19 {
    padding: 9rem !important;
  }

  .p-lg-20 {
    padding: 10rem !important;
  }

  .p-lg-21 {
    padding: 12.5rem !important;
  }

  .p-lg-22 {
    padding: 15rem !important;
  }

  .p-lg-23 {
    padding: 17.5rem !important;
  }

  .p-lg-24 {
    padding: 20rem !important;
  }

  .p-lg-25 {
    padding: 22.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-16 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-17 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-lg-18 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-19 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-lg-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-21 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .px-lg-22 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-lg-23 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }

  .px-lg-24 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-lg-25 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-16 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-17 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-lg-18 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-lg-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-21 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .py-lg-22 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-lg-23 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }

  .py-lg-24 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-lg-25 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 3rem !important;
  }

  .pt-lg-12 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-13 {
    padding-top: 4rem !important;
  }

  .pt-lg-14 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 5rem !important;
  }

  .pt-lg-16 {
    padding-top: 6rem !important;
  }

  .pt-lg-17 {
    padding-top: 7rem !important;
  }

  .pt-lg-18 {
    padding-top: 8rem !important;
  }

  .pt-lg-19 {
    padding-top: 9rem !important;
  }

  .pt-lg-20 {
    padding-top: 10rem !important;
  }

  .pt-lg-21 {
    padding-top: 12.5rem !important;
  }

  .pt-lg-22 {
    padding-top: 15rem !important;
  }

  .pt-lg-23 {
    padding-top: 17.5rem !important;
  }

  .pt-lg-24 {
    padding-top: 20rem !important;
  }

  .pt-lg-25 {
    padding-top: 22.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 3rem !important;
  }

  .pe-lg-12 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-13 {
    padding-right: 4rem !important;
  }

  .pe-lg-14 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 5rem !important;
  }

  .pe-lg-16 {
    padding-right: 6rem !important;
  }

  .pe-lg-17 {
    padding-right: 7rem !important;
  }

  .pe-lg-18 {
    padding-right: 8rem !important;
  }

  .pe-lg-19 {
    padding-right: 9rem !important;
  }

  .pe-lg-20 {
    padding-right: 10rem !important;
  }

  .pe-lg-21 {
    padding-right: 12.5rem !important;
  }

  .pe-lg-22 {
    padding-right: 15rem !important;
  }

  .pe-lg-23 {
    padding-right: 17.5rem !important;
  }

  .pe-lg-24 {
    padding-right: 20rem !important;
  }

  .pe-lg-25 {
    padding-right: 22.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 7rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 9rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-21 {
    padding-bottom: 12.5rem !important;
  }

  .pb-lg-22 {
    padding-bottom: 15rem !important;
  }

  .pb-lg-23 {
    padding-bottom: 17.5rem !important;
  }

  .pb-lg-24 {
    padding-bottom: 20rem !important;
  }

  .pb-lg-25 {
    padding-bottom: 22.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 3rem !important;
  }

  .ps-lg-12 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-13 {
    padding-left: 4rem !important;
  }

  .ps-lg-14 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 5rem !important;
  }

  .ps-lg-16 {
    padding-left: 6rem !important;
  }

  .ps-lg-17 {
    padding-left: 7rem !important;
  }

  .ps-lg-18 {
    padding-left: 8rem !important;
  }

  .ps-lg-19 {
    padding-left: 9rem !important;
  }

  .ps-lg-20 {
    padding-left: 10rem !important;
  }

  .ps-lg-21 {
    padding-left: 12.5rem !important;
  }

  .ps-lg-22 {
    padding-left: 15rem !important;
  }

  .ps-lg-23 {
    padding-left: 17.5rem !important;
  }

  .ps-lg-24 {
    padding-left: 20rem !important;
  }

  .ps-lg-25 {
    padding-left: 22.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 3rem !important;
  }

  .gap-xl-12 {
    gap: 3.5rem !important;
  }

  .gap-xl-13 {
    gap: 4rem !important;
  }

  .gap-xl-14 {
    gap: 4.5rem !important;
  }

  .gap-xl-15 {
    gap: 5rem !important;
  }

  .gap-xl-16 {
    gap: 6rem !important;
  }

  .gap-xl-17 {
    gap: 7rem !important;
  }

  .gap-xl-18 {
    gap: 8rem !important;
  }

  .gap-xl-19 {
    gap: 9rem !important;
  }

  .gap-xl-20 {
    gap: 10rem !important;
  }

  .gap-xl-21 {
    gap: 12.5rem !important;
  }

  .gap-xl-22 {
    gap: 15rem !important;
  }

  .gap-xl-23 {
    gap: 17.5rem !important;
  }

  .gap-xl-24 {
    gap: 20rem !important;
  }

  .gap-xl-25 {
    gap: 22.5rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 3rem !important;
  }

  .m-xl-12 {
    margin: 3.5rem !important;
  }

  .m-xl-13 {
    margin: 4rem !important;
  }

  .m-xl-14 {
    margin: 4.5rem !important;
  }

  .m-xl-15 {
    margin: 5rem !important;
  }

  .m-xl-16 {
    margin: 6rem !important;
  }

  .m-xl-17 {
    margin: 7rem !important;
  }

  .m-xl-18 {
    margin: 8rem !important;
  }

  .m-xl-19 {
    margin: 9rem !important;
  }

  .m-xl-20 {
    margin: 10rem !important;
  }

  .m-xl-21 {
    margin: 12.5rem !important;
  }

  .m-xl-22 {
    margin: 15rem !important;
  }

  .m-xl-23 {
    margin: 17.5rem !important;
  }

  .m-xl-24 {
    margin: 20rem !important;
  }

  .m-xl-25 {
    margin: 22.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-16 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-17 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xl-18 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-19 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-21 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .mx-xl-22 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-xl-23 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }

  .mx-xl-24 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xl-25 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-16 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-17 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xl-18 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-19 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-21 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .my-xl-22 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-xl-23 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }

  .my-xl-24 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xl-25 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 3rem !important;
  }

  .mt-xl-12 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-13 {
    margin-top: 4rem !important;
  }

  .mt-xl-14 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 5rem !important;
  }

  .mt-xl-16 {
    margin-top: 6rem !important;
  }

  .mt-xl-17 {
    margin-top: 7rem !important;
  }

  .mt-xl-18 {
    margin-top: 8rem !important;
  }

  .mt-xl-19 {
    margin-top: 9rem !important;
  }

  .mt-xl-20 {
    margin-top: 10rem !important;
  }

  .mt-xl-21 {
    margin-top: 12.5rem !important;
  }

  .mt-xl-22 {
    margin-top: 15rem !important;
  }

  .mt-xl-23 {
    margin-top: 17.5rem !important;
  }

  .mt-xl-24 {
    margin-top: 20rem !important;
  }

  .mt-xl-25 {
    margin-top: 22.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 3rem !important;
  }

  .me-xl-12 {
    margin-right: 3.5rem !important;
  }

  .me-xl-13 {
    margin-right: 4rem !important;
  }

  .me-xl-14 {
    margin-right: 4.5rem !important;
  }

  .me-xl-15 {
    margin-right: 5rem !important;
  }

  .me-xl-16 {
    margin-right: 6rem !important;
  }

  .me-xl-17 {
    margin-right: 7rem !important;
  }

  .me-xl-18 {
    margin-right: 8rem !important;
  }

  .me-xl-19 {
    margin-right: 9rem !important;
  }

  .me-xl-20 {
    margin-right: 10rem !important;
  }

  .me-xl-21 {
    margin-right: 12.5rem !important;
  }

  .me-xl-22 {
    margin-right: 15rem !important;
  }

  .me-xl-23 {
    margin-right: 17.5rem !important;
  }

  .me-xl-24 {
    margin-right: 20rem !important;
  }

  .me-xl-25 {
    margin-right: 22.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 7rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-21 {
    margin-bottom: 12.5rem !important;
  }

  .mb-xl-22 {
    margin-bottom: 15rem !important;
  }

  .mb-xl-23 {
    margin-bottom: 17.5rem !important;
  }

  .mb-xl-24 {
    margin-bottom: 20rem !important;
  }

  .mb-xl-25 {
    margin-bottom: 22.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 3rem !important;
  }

  .ms-xl-12 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-13 {
    margin-left: 4rem !important;
  }

  .ms-xl-14 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 5rem !important;
  }

  .ms-xl-16 {
    margin-left: 6rem !important;
  }

  .ms-xl-17 {
    margin-left: 7rem !important;
  }

  .ms-xl-18 {
    margin-left: 8rem !important;
  }

  .ms-xl-19 {
    margin-left: 9rem !important;
  }

  .ms-xl-20 {
    margin-left: 10rem !important;
  }

  .ms-xl-21 {
    margin-left: 12.5rem !important;
  }

  .ms-xl-22 {
    margin-left: 15rem !important;
  }

  .ms-xl-23 {
    margin-left: 17.5rem !important;
  }

  .ms-xl-24 {
    margin-left: 20rem !important;
  }

  .ms-xl-25 {
    margin-left: 22.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -3rem !important;
  }

  .m-xl-n12 {
    margin: -3.5rem !important;
  }

  .m-xl-n13 {
    margin: -4rem !important;
  }

  .m-xl-n14 {
    margin: -4.5rem !important;
  }

  .m-xl-n15 {
    margin: -5rem !important;
  }

  .m-xl-n16 {
    margin: -6rem !important;
  }

  .m-xl-n17 {
    margin: -7rem !important;
  }

  .m-xl-n18 {
    margin: -8rem !important;
  }

  .m-xl-n19 {
    margin: -9rem !important;
  }

  .m-xl-n20 {
    margin: -10rem !important;
  }

  .m-xl-n21 {
    margin: -12.5rem !important;
  }

  .m-xl-n22 {
    margin: -15rem !important;
  }

  .m-xl-n23 {
    margin: -17.5rem !important;
  }

  .m-xl-n24 {
    margin: -20rem !important;
  }

  .m-xl-n25 {
    margin: -22.5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n16 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n17 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-xl-n18 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n19 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-xl-n20 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n21 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .mx-xl-n22 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .mx-xl-n23 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }

  .mx-xl-n24 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-xl-n25 {
    margin-right: -22.5rem !important;
    margin-left: -22.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n16 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n17 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-xl-n18 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n19 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-xl-n20 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n21 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .my-xl-n22 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .my-xl-n23 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }

  .my-xl-n24 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xl-n25 {
    margin-top: -22.5rem !important;
    margin-bottom: -22.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -3rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n13 {
    margin-top: -4rem !important;
  }

  .mt-xl-n14 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -5rem !important;
  }

  .mt-xl-n16 {
    margin-top: -6rem !important;
  }

  .mt-xl-n17 {
    margin-top: -7rem !important;
  }

  .mt-xl-n18 {
    margin-top: -8rem !important;
  }

  .mt-xl-n19 {
    margin-top: -9rem !important;
  }

  .mt-xl-n20 {
    margin-top: -10rem !important;
  }

  .mt-xl-n21 {
    margin-top: -12.5rem !important;
  }

  .mt-xl-n22 {
    margin-top: -15rem !important;
  }

  .mt-xl-n23 {
    margin-top: -17.5rem !important;
  }

  .mt-xl-n24 {
    margin-top: -20rem !important;
  }

  .mt-xl-n25 {
    margin-top: -22.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -3rem !important;
  }

  .me-xl-n12 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n13 {
    margin-right: -4rem !important;
  }

  .me-xl-n14 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -5rem !important;
  }

  .me-xl-n16 {
    margin-right: -6rem !important;
  }

  .me-xl-n17 {
    margin-right: -7rem !important;
  }

  .me-xl-n18 {
    margin-right: -8rem !important;
  }

  .me-xl-n19 {
    margin-right: -9rem !important;
  }

  .me-xl-n20 {
    margin-right: -10rem !important;
  }

  .me-xl-n21 {
    margin-right: -12.5rem !important;
  }

  .me-xl-n22 {
    margin-right: -15rem !important;
  }

  .me-xl-n23 {
    margin-right: -17.5rem !important;
  }

  .me-xl-n24 {
    margin-right: -20rem !important;
  }

  .me-xl-n25 {
    margin-right: -22.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -7rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -9rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n21 {
    margin-bottom: -12.5rem !important;
  }

  .mb-xl-n22 {
    margin-bottom: -15rem !important;
  }

  .mb-xl-n23 {
    margin-bottom: -17.5rem !important;
  }

  .mb-xl-n24 {
    margin-bottom: -20rem !important;
  }

  .mb-xl-n25 {
    margin-bottom: -22.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -3rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n13 {
    margin-left: -4rem !important;
  }

  .ms-xl-n14 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -5rem !important;
  }

  .ms-xl-n16 {
    margin-left: -6rem !important;
  }

  .ms-xl-n17 {
    margin-left: -7rem !important;
  }

  .ms-xl-n18 {
    margin-left: -8rem !important;
  }

  .ms-xl-n19 {
    margin-left: -9rem !important;
  }

  .ms-xl-n20 {
    margin-left: -10rem !important;
  }

  .ms-xl-n21 {
    margin-left: -12.5rem !important;
  }

  .ms-xl-n22 {
    margin-left: -15rem !important;
  }

  .ms-xl-n23 {
    margin-left: -17.5rem !important;
  }

  .ms-xl-n24 {
    margin-left: -20rem !important;
  }

  .ms-xl-n25 {
    margin-left: -22.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 3rem !important;
  }

  .p-xl-12 {
    padding: 3.5rem !important;
  }

  .p-xl-13 {
    padding: 4rem !important;
  }

  .p-xl-14 {
    padding: 4.5rem !important;
  }

  .p-xl-15 {
    padding: 5rem !important;
  }

  .p-xl-16 {
    padding: 6rem !important;
  }

  .p-xl-17 {
    padding: 7rem !important;
  }

  .p-xl-18 {
    padding: 8rem !important;
  }

  .p-xl-19 {
    padding: 9rem !important;
  }

  .p-xl-20 {
    padding: 10rem !important;
  }

  .p-xl-21 {
    padding: 12.5rem !important;
  }

  .p-xl-22 {
    padding: 15rem !important;
  }

  .p-xl-23 {
    padding: 17.5rem !important;
  }

  .p-xl-24 {
    padding: 20rem !important;
  }

  .p-xl-25 {
    padding: 22.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-16 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-17 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xl-18 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-19 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xl-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-21 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .px-xl-22 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-xl-23 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }

  .px-xl-24 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-xl-25 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-16 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-17 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xl-18 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xl-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-21 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .py-xl-22 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-xl-23 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }

  .py-xl-24 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xl-25 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 3rem !important;
  }

  .pt-xl-12 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-13 {
    padding-top: 4rem !important;
  }

  .pt-xl-14 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 5rem !important;
  }

  .pt-xl-16 {
    padding-top: 6rem !important;
  }

  .pt-xl-17 {
    padding-top: 7rem !important;
  }

  .pt-xl-18 {
    padding-top: 8rem !important;
  }

  .pt-xl-19 {
    padding-top: 9rem !important;
  }

  .pt-xl-20 {
    padding-top: 10rem !important;
  }

  .pt-xl-21 {
    padding-top: 12.5rem !important;
  }

  .pt-xl-22 {
    padding-top: 15rem !important;
  }

  .pt-xl-23 {
    padding-top: 17.5rem !important;
  }

  .pt-xl-24 {
    padding-top: 20rem !important;
  }

  .pt-xl-25 {
    padding-top: 22.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 3rem !important;
  }

  .pe-xl-12 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-13 {
    padding-right: 4rem !important;
  }

  .pe-xl-14 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 5rem !important;
  }

  .pe-xl-16 {
    padding-right: 6rem !important;
  }

  .pe-xl-17 {
    padding-right: 7rem !important;
  }

  .pe-xl-18 {
    padding-right: 8rem !important;
  }

  .pe-xl-19 {
    padding-right: 9rem !important;
  }

  .pe-xl-20 {
    padding-right: 10rem !important;
  }

  .pe-xl-21 {
    padding-right: 12.5rem !important;
  }

  .pe-xl-22 {
    padding-right: 15rem !important;
  }

  .pe-xl-23 {
    padding-right: 17.5rem !important;
  }

  .pe-xl-24 {
    padding-right: 20rem !important;
  }

  .pe-xl-25 {
    padding-right: 22.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 7rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 9rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-21 {
    padding-bottom: 12.5rem !important;
  }

  .pb-xl-22 {
    padding-bottom: 15rem !important;
  }

  .pb-xl-23 {
    padding-bottom: 17.5rem !important;
  }

  .pb-xl-24 {
    padding-bottom: 20rem !important;
  }

  .pb-xl-25 {
    padding-bottom: 22.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 3rem !important;
  }

  .ps-xl-12 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-13 {
    padding-left: 4rem !important;
  }

  .ps-xl-14 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 5rem !important;
  }

  .ps-xl-16 {
    padding-left: 6rem !important;
  }

  .ps-xl-17 {
    padding-left: 7rem !important;
  }

  .ps-xl-18 {
    padding-left: 8rem !important;
  }

  .ps-xl-19 {
    padding-left: 9rem !important;
  }

  .ps-xl-20 {
    padding-left: 10rem !important;
  }

  .ps-xl-21 {
    padding-left: 12.5rem !important;
  }

  .ps-xl-22 {
    padding-left: 15rem !important;
  }

  .ps-xl-23 {
    padding-left: 17.5rem !important;
  }

  .ps-xl-24 {
    padding-left: 20rem !important;
  }

  .ps-xl-25 {
    padding-left: 22.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 3rem !important;
  }

  .gap-xxl-12 {
    gap: 3.5rem !important;
  }

  .gap-xxl-13 {
    gap: 4rem !important;
  }

  .gap-xxl-14 {
    gap: 4.5rem !important;
  }

  .gap-xxl-15 {
    gap: 5rem !important;
  }

  .gap-xxl-16 {
    gap: 6rem !important;
  }

  .gap-xxl-17 {
    gap: 7rem !important;
  }

  .gap-xxl-18 {
    gap: 8rem !important;
  }

  .gap-xxl-19 {
    gap: 9rem !important;
  }

  .gap-xxl-20 {
    gap: 10rem !important;
  }

  .gap-xxl-21 {
    gap: 12.5rem !important;
  }

  .gap-xxl-22 {
    gap: 15rem !important;
  }

  .gap-xxl-23 {
    gap: 17.5rem !important;
  }

  .gap-xxl-24 {
    gap: 20rem !important;
  }

  .gap-xxl-25 {
    gap: 22.5rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 3rem !important;
  }

  .m-xxl-12 {
    margin: 3.5rem !important;
  }

  .m-xxl-13 {
    margin: 4rem !important;
  }

  .m-xxl-14 {
    margin: 4.5rem !important;
  }

  .m-xxl-15 {
    margin: 5rem !important;
  }

  .m-xxl-16 {
    margin: 6rem !important;
  }

  .m-xxl-17 {
    margin: 7rem !important;
  }

  .m-xxl-18 {
    margin: 8rem !important;
  }

  .m-xxl-19 {
    margin: 9rem !important;
  }

  .m-xxl-20 {
    margin: 10rem !important;
  }

  .m-xxl-21 {
    margin: 12.5rem !important;
  }

  .m-xxl-22 {
    margin: 15rem !important;
  }

  .m-xxl-23 {
    margin: 17.5rem !important;
  }

  .m-xxl-24 {
    margin: 20rem !important;
  }

  .m-xxl-25 {
    margin: 22.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-16 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-17 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xxl-18 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-19 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-20 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-21 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }

  .mx-xxl-22 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-xxl-23 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }

  .mx-xxl-24 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xxl-25 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-16 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-17 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xxl-18 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-19 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-21 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }

  .my-xxl-22 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-xxl-23 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }

  .my-xxl-24 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xxl-25 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 3rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-13 {
    margin-top: 4rem !important;
  }

  .mt-xxl-14 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 5rem !important;
  }

  .mt-xxl-16 {
    margin-top: 6rem !important;
  }

  .mt-xxl-17 {
    margin-top: 7rem !important;
  }

  .mt-xxl-18 {
    margin-top: 8rem !important;
  }

  .mt-xxl-19 {
    margin-top: 9rem !important;
  }

  .mt-xxl-20 {
    margin-top: 10rem !important;
  }

  .mt-xxl-21 {
    margin-top: 12.5rem !important;
  }

  .mt-xxl-22 {
    margin-top: 15rem !important;
  }

  .mt-xxl-23 {
    margin-top: 17.5rem !important;
  }

  .mt-xxl-24 {
    margin-top: 20rem !important;
  }

  .mt-xxl-25 {
    margin-top: 22.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 3rem !important;
  }

  .me-xxl-12 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-13 {
    margin-right: 4rem !important;
  }

  .me-xxl-14 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 5rem !important;
  }

  .me-xxl-16 {
    margin-right: 6rem !important;
  }

  .me-xxl-17 {
    margin-right: 7rem !important;
  }

  .me-xxl-18 {
    margin-right: 8rem !important;
  }

  .me-xxl-19 {
    margin-right: 9rem !important;
  }

  .me-xxl-20 {
    margin-right: 10rem !important;
  }

  .me-xxl-21 {
    margin-right: 12.5rem !important;
  }

  .me-xxl-22 {
    margin-right: 15rem !important;
  }

  .me-xxl-23 {
    margin-right: 17.5rem !important;
  }

  .me-xxl-24 {
    margin-right: 20rem !important;
  }

  .me-xxl-25 {
    margin-right: 22.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 7rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-21 {
    margin-bottom: 12.5rem !important;
  }

  .mb-xxl-22 {
    margin-bottom: 15rem !important;
  }

  .mb-xxl-23 {
    margin-bottom: 17.5rem !important;
  }

  .mb-xxl-24 {
    margin-bottom: 20rem !important;
  }

  .mb-xxl-25 {
    margin-bottom: 22.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 3rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-13 {
    margin-left: 4rem !important;
  }

  .ms-xxl-14 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 5rem !important;
  }

  .ms-xxl-16 {
    margin-left: 6rem !important;
  }

  .ms-xxl-17 {
    margin-left: 7rem !important;
  }

  .ms-xxl-18 {
    margin-left: 8rem !important;
  }

  .ms-xxl-19 {
    margin-left: 9rem !important;
  }

  .ms-xxl-20 {
    margin-left: 10rem !important;
  }

  .ms-xxl-21 {
    margin-left: 12.5rem !important;
  }

  .ms-xxl-22 {
    margin-left: 15rem !important;
  }

  .ms-xxl-23 {
    margin-left: 17.5rem !important;
  }

  .ms-xxl-24 {
    margin-left: 20rem !important;
  }

  .ms-xxl-25 {
    margin-left: 22.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -3rem !important;
  }

  .m-xxl-n12 {
    margin: -3.5rem !important;
  }

  .m-xxl-n13 {
    margin: -4rem !important;
  }

  .m-xxl-n14 {
    margin: -4.5rem !important;
  }

  .m-xxl-n15 {
    margin: -5rem !important;
  }

  .m-xxl-n16 {
    margin: -6rem !important;
  }

  .m-xxl-n17 {
    margin: -7rem !important;
  }

  .m-xxl-n18 {
    margin: -8rem !important;
  }

  .m-xxl-n19 {
    margin: -9rem !important;
  }

  .m-xxl-n20 {
    margin: -10rem !important;
  }

  .m-xxl-n21 {
    margin: -12.5rem !important;
  }

  .m-xxl-n22 {
    margin: -15rem !important;
  }

  .m-xxl-n23 {
    margin: -17.5rem !important;
  }

  .m-xxl-n24 {
    margin: -20rem !important;
  }

  .m-xxl-n25 {
    margin: -22.5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xxl-n21 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }

  .mx-xxl-n22 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

  .mx-xxl-n23 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }

  .mx-xxl-n24 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-xxl-n25 {
    margin-right: -22.5rem !important;
    margin-left: -22.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n16 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n17 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-xxl-n18 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n19 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-xxl-n20 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n21 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }

  .my-xxl-n22 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .my-xxl-n23 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }

  .my-xxl-n24 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xxl-n25 {
    margin-top: -22.5rem !important;
    margin-bottom: -22.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -7rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -9rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n21 {
    margin-top: -12.5rem !important;
  }

  .mt-xxl-n22 {
    margin-top: -15rem !important;
  }

  .mt-xxl-n23 {
    margin-top: -17.5rem !important;
  }

  .mt-xxl-n24 {
    margin-top: -20rem !important;
  }

  .mt-xxl-n25 {
    margin-top: -22.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -3rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n13 {
    margin-right: -4rem !important;
  }

  .me-xxl-n14 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -5rem !important;
  }

  .me-xxl-n16 {
    margin-right: -6rem !important;
  }

  .me-xxl-n17 {
    margin-right: -7rem !important;
  }

  .me-xxl-n18 {
    margin-right: -8rem !important;
  }

  .me-xxl-n19 {
    margin-right: -9rem !important;
  }

  .me-xxl-n20 {
    margin-right: -10rem !important;
  }

  .me-xxl-n21 {
    margin-right: -12.5rem !important;
  }

  .me-xxl-n22 {
    margin-right: -15rem !important;
  }

  .me-xxl-n23 {
    margin-right: -17.5rem !important;
  }

  .me-xxl-n24 {
    margin-right: -20rem !important;
  }

  .me-xxl-n25 {
    margin-right: -22.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -7rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -9rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n21 {
    margin-bottom: -12.5rem !important;
  }

  .mb-xxl-n22 {
    margin-bottom: -15rem !important;
  }

  .mb-xxl-n23 {
    margin-bottom: -17.5rem !important;
  }

  .mb-xxl-n24 {
    margin-bottom: -20rem !important;
  }

  .mb-xxl-n25 {
    margin-bottom: -22.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -7rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -9rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n21 {
    margin-left: -12.5rem !important;
  }

  .ms-xxl-n22 {
    margin-left: -15rem !important;
  }

  .ms-xxl-n23 {
    margin-left: -17.5rem !important;
  }

  .ms-xxl-n24 {
    margin-left: -20rem !important;
  }

  .ms-xxl-n25 {
    margin-left: -22.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 3rem !important;
  }

  .p-xxl-12 {
    padding: 3.5rem !important;
  }

  .p-xxl-13 {
    padding: 4rem !important;
  }

  .p-xxl-14 {
    padding: 4.5rem !important;
  }

  .p-xxl-15 {
    padding: 5rem !important;
  }

  .p-xxl-16 {
    padding: 6rem !important;
  }

  .p-xxl-17 {
    padding: 7rem !important;
  }

  .p-xxl-18 {
    padding: 8rem !important;
  }

  .p-xxl-19 {
    padding: 9rem !important;
  }

  .p-xxl-20 {
    padding: 10rem !important;
  }

  .p-xxl-21 {
    padding: 12.5rem !important;
  }

  .p-xxl-22 {
    padding: 15rem !important;
  }

  .p-xxl-23 {
    padding: 17.5rem !important;
  }

  .p-xxl-24 {
    padding: 20rem !important;
  }

  .p-xxl-25 {
    padding: 22.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-16 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-17 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xxl-18 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-19 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxl-20 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-21 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }

  .px-xxl-22 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-xxl-23 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }

  .px-xxl-24 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-xxl-25 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-16 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-17 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xxl-18 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxl-20 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-21 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  .py-xxl-22 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-xxl-23 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }

  .py-xxl-24 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xxl-25 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 3rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-13 {
    padding-top: 4rem !important;
  }

  .pt-xxl-14 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 5rem !important;
  }

  .pt-xxl-16 {
    padding-top: 6rem !important;
  }

  .pt-xxl-17 {
    padding-top: 7rem !important;
  }

  .pt-xxl-18 {
    padding-top: 8rem !important;
  }

  .pt-xxl-19 {
    padding-top: 9rem !important;
  }

  .pt-xxl-20 {
    padding-top: 10rem !important;
  }

  .pt-xxl-21 {
    padding-top: 12.5rem !important;
  }

  .pt-xxl-22 {
    padding-top: 15rem !important;
  }

  .pt-xxl-23 {
    padding-top: 17.5rem !important;
  }

  .pt-xxl-24 {
    padding-top: 20rem !important;
  }

  .pt-xxl-25 {
    padding-top: 22.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 3rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-13 {
    padding-right: 4rem !important;
  }

  .pe-xxl-14 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 5rem !important;
  }

  .pe-xxl-16 {
    padding-right: 6rem !important;
  }

  .pe-xxl-17 {
    padding-right: 7rem !important;
  }

  .pe-xxl-18 {
    padding-right: 8rem !important;
  }

  .pe-xxl-19 {
    padding-right: 9rem !important;
  }

  .pe-xxl-20 {
    padding-right: 10rem !important;
  }

  .pe-xxl-21 {
    padding-right: 12.5rem !important;
  }

  .pe-xxl-22 {
    padding-right: 15rem !important;
  }

  .pe-xxl-23 {
    padding-right: 17.5rem !important;
  }

  .pe-xxl-24 {
    padding-right: 20rem !important;
  }

  .pe-xxl-25 {
    padding-right: 22.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 7rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 9rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-21 {
    padding-bottom: 12.5rem !important;
  }

  .pb-xxl-22 {
    padding-bottom: 15rem !important;
  }

  .pb-xxl-23 {
    padding-bottom: 17.5rem !important;
  }

  .pb-xxl-24 {
    padding-bottom: 20rem !important;
  }

  .pb-xxl-25 {
    padding-bottom: 22.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 3rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-13 {
    padding-left: 4rem !important;
  }

  .ps-xxl-14 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 5rem !important;
  }

  .ps-xxl-16 {
    padding-left: 6rem !important;
  }

  .ps-xxl-17 {
    padding-left: 7rem !important;
  }

  .ps-xxl-18 {
    padding-left: 8rem !important;
  }

  .ps-xxl-19 {
    padding-left: 9rem !important;
  }

  .ps-xxl-20 {
    padding-left: 10rem !important;
  }

  .ps-xxl-21 {
    padding-left: 12.5rem !important;
  }

  .ps-xxl-22 {
    padding-left: 15rem !important;
  }

  .ps-xxl-23 {
    padding-left: 17.5rem !important;
  }

  .ps-xxl-24 {
    padding-left: 20rem !important;
  }

  .ps-xxl-25 {
    padding-left: 22.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

body {
  word-spacing: 0.05rem;
  overflow-x: hidden !important;
}

::-moz-selection {
  background: rgba(63, 120, 224, 0.7);
  color: #fff;
}

::selection {
  background: rgba(63, 120, 224, 0.7);
  color: #fff;
}

button:focus {
  outline: 0;
}

a {
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

a.hover {
  position: relative;
}

a.hover::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

a.hover::before {
  content: "";
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

a.hover:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.text-reset a {
  color: inherit !important;
}

.link-dark:hover,
a.text-reset:hover,
.text-reset a:hover {
  color: var(--secondaryDark) !important;
}

[class*=text-] .text-reset:hover {
  color: inherit !important;
}

.link-body {
  color: #60697b;
}

.link-inverse {
  color: #cacaca;
}

.text-inverse,
.link-inverse,
.text-inverse a:not(.btn) {
  color: #fff;
}

.bg-dark.text-inverse,
.bg-dark.link-inverse,
.bg-dark.text-inverse a:not(.btn):not([class*=link-]),
[class*=offcanvas-].text-inverse a:not(.btn):not([class*=link-]) {
  color: #cacaca;
}

.text-inverse a:not(.btn):hover,
.bg-dark.text-inverse a:not(.btn):not([class*=link-]):hover,
[class*=offcanvas-].text-inverse a:not(.btn):not([class*=link-]):hover {
  color: #3f78e0;
}

.text-inverse a:not(.btn):hover {
  border-color: #3f78e0;
}

.badge.bg-light {
  background: rgba(164, 174, 198, 0.2) !important;
}

.more {
  font-weight: 700;
  letter-spacing: -0.01rem;
  display: inline-block;
}

.more:after {
  font-family: "Custom";
  content: "\e902";
  padding-left: 0.05rem;
  font-size: 0.85rem;
  font-weight: normal;
  vertical-align: -2px;
  margin-right: -0.25rem;
  display: inline-block;
}

hr.double {
  background: none;
  border-style: double;
  border-color: rgba(164, 174, 198, 0.2);
}

_:default:not(:root:root),
hr.double {
  border-bottom-width: 3px;
}

.divider-icon {
  border: 0;
  position: relative;
  margin: 4.5rem 0;
}

.divider-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: rgba(164, 174, 198, 0.2);
}

.divider-icon:before,
.divider-icon:after {
  position: absolute;
  top: 50%;
  content: "";
  border-top: 1px solid rgba(164, 174, 198, 0.2);
  width: calc(50% - 1.5rem);
}

.divider-icon:before {
  left: 0;
}

.divider-icon:after {
  right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #343f52;
  word-spacing: 0.1rem;
  letter-spacing: -0.01rem;
}

h1.text-uppercase,
h2.text-uppercase,
h3.text-uppercase,
h4.text-uppercase,
h5.text-uppercase,
h6.text-uppercase,
.text-uppercase.h1,
.text-uppercase.h2,
.text-uppercase.h3,
.text-uppercase.h4,
.text-uppercase.h5,
.text-uppercase.h6 {
  letter-spacing: 0.02rem;
}

h1,
.h1 {
  line-height: 1.3;
}

h2,
.h2 {
  line-height: 1.35;
}

h3,
.h3 {
  line-height: 1.4;
}

h4,
.h4 {
  line-height: 1.45;
}

h5,
.h5 {
  line-height: 1.5;
}

h6,
.h6 {
  line-height: 1.55;
}

.display-1 {
  line-height: 1.2;
}

.display-2,
.display-3 {
  line-height: 1.25;
}

.display-4,
.display-5 {
  line-height: 1.3;
}

.display-6 {
  line-height: 1.35;
}

.ls-sm {
  letter-spacing: -0.02rem !important;
}

.ls-md {
  letter-spacing: -0.01rem !important;
}

.ls-lg {
  letter-spacing: 0.02rem !important;
}

.ls-xl {
  letter-spacing: 0.05rem !important;
}

.ls-normal {
  letter-spacing: normal;
}

.lead {
  line-height: 1.65;
}

.lead.fs-lg {
  font-size: 1.05rem;
  line-height: 1.6;
}

.text-line {
  position: relative;
  vertical-align: top;
  padding-left: 1.4rem;
}

.text-line:not(.text-gradient) {
  display: inline-flex;
}

.text-line:before {
  content: "";
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-60%);
  left: 0;
  width: 0.75rem;
  height: 0.05rem;
  background: #3f78e0;
}

.text-line.text-blue:before {
  background-color: #3f78e0;
}

.text-line.text-purple:before {
  background-color: #747ed1;
}

.text-line.text-violet:before {
  background-color: #a07cc5;
}

.text-line.text-pink:before {
  background-color: #d16b86;
}

.text-line.text-red:before {
  background-color: #e2626b;
}

.text-line.text-orange:before {
  background-color: #f78b77;
}

.text-line.text-yellow:before {
  background-color: #fab758;
}

.text-line.text-green:before {
  background-color: #6bbea3;
}

.text-line.text-leaf:before {
  background-color: #7cb798;
}

.text-line.text-aqua:before {
  background-color: #54a8c7;
}

.text-line.text-navy:before {
  background-color: #343f52;
}

.text-line.text-ash:before {
  background-color: #9499a3;
}

.text-line.text-white:before {
  background-color: #fff;
}

.text-line.text-light:before {
  background-color: #fefefe;
}

.text-line.text-gray:before {
  background-color: #f6f7f9;
}

.text-line.text-dark:before {
  background-color: #262b32;
}

.text-line.text-primary:before {
  background-color: #3f78e0;
}

.text-line.text-muted:before {
  background-color: #aab0bc;
}

.underline {
  position: relative;
  z-index: 2;
  white-space: nowrap;
}

.underline:after {
  content: "";
  display: block;
  background: rgba(63, 120, 224, 0.12);
  border-radius: 5rem;
  position: absolute;
  width: calc(100% + 2.5%);
  height: 30%;
  left: -1.5%;
  bottom: 9%;
  z-index: -1;
  margin-top: 0;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .underline:after {
    transition: none;
  }
}

.underline.blue:after {
  background: rgba(63, 120, 224, 0.12);
}

.underline.purple:after {
  background: rgba(116, 126, 209, 0.12);
}

.underline.violet:after {
  background: rgba(160, 124, 197, 0.12);
}

.underline.pink:after {
  background: rgba(209, 107, 134, 0.12);
}

.underline.red:after {
  background: rgba(226, 98, 107, 0.12);
}

.underline.orange:after {
  background: rgba(247, 139, 119, 0.12);
}

.underline.yellow:after {
  background: rgba(250, 183, 88, 0.12);
}

.underline.green:after {
  background: rgba(107, 190, 163, 0.12);
}

.underline.leaf:after {
  background: rgba(124, 183, 152, 0.12);
}

.underline.aqua:after {
  background: rgba(84, 168, 199, 0.12);
}

.underline.navy:after {
  background: rgba(52, 63, 82, 0.12);
}

.underline.ash:after {
  background: rgba(148, 153, 163, 0.12);
}

.underline.white:after {
  background: rgba(255, 255, 255, 0.12);
}

.underline.light:after {
  background: rgba(254, 254, 254, 0.12);
}

.underline.gray:after {
  background: rgba(246, 247, 249, 0.12);
}

.underline.dark:after {
  background: rgba(38, 43, 50, 0.12);
}

.underline.primary:after {
  background: rgba(63, 120, 224, 0.12);
}

.underline-2 {
  position: relative;
  z-index: 2;
  white-space: nowrap;
}

.underline-2:after {
  content: "";
  display: block;
  background: #3f78e0;
  border-radius: 5rem;
  position: absolute;
  width: calc(100% + 2.5%);
  height: 20%;
  left: -1.5%;
  bottom: -10%;
  z-index: -1;
  margin-top: 0;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .underline-2:after {
    transition: none;
  }
}

.underline-2.blue:after {
  background: #3f78e0;
}

.underline-2.purple:after {
  background: #747ed1;
}

.underline-2.violet:after {
  background: #a07cc5;
}

.underline-2.pink:after {
  background: #d16b86;
}

.underline-2.red:after {
  background: #e2626b;
}

.underline-2.orange:after {
  background: #f78b77;
}

.underline-2.yellow:after {
  background: #fab758;
}

.underline-2.green:after {
  background: #6bbea3;
}

.underline-2.leaf:after {
  background: #7cb798;
}

.underline-2.aqua:after {
  background: #54a8c7;
}

.underline-2.navy:after {
  background: #343f52;
}

.underline-2.ash:after {
  background: #9499a3;
}

.underline-2.white:after {
  background: #fff;
}

.underline-2.light:after {
  background: #fefefe;
}

.underline-2.gray:after {
  background: #f6f7f9;
}

.underline-2.dark:after {
  background: #262b32;
}

.underline-2.primary:after {
  background: #3f78e0;
}

.underline-gradient-1:after {
  background: linear-gradient(120deg, #f857a6 25%, #e9314d 100%);
}

.underline-gradient-2:after {
  background: linear-gradient(40deg, #f5b161 0.4%, #ec366e 100.2%);
}

.underline-gradient-3:after {
  background: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.underline-gradient-4:after {
  background: linear-gradient(125deg, #9040db, #ff72c2 50%, #ffd84f);
}

.underline-gradient-5:after {
  background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.underline-gradient-6:after {
  background: linear-gradient(45deg, #08AEEA 0%, #2AF598 100%);
}

.underline-gradient-7:after {
  background: linear-gradient(100deg, #0093e9 0, #80d0c7 95%);
}

.underline-gradient-8:after {
  background: linear-gradient(45deg, #ffdee9, #b5fffc);
}

.mark,
mark {
  color: inherit;
  border-radius: 0.4rem;
}

.rounded img {
  border-radius: 0.4rem;
}

@media (min-width: 992px) {

  .map.rounded-lg-start iframe,
  .image-wrapper.rounded-lg-start {
    border-bottom-left-radius: 0.4rem !important;
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .image-wrapper.rounded-4-lg-start {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 991.98px) {

  .map.rounded-top iframe,
  .image-wrapper.rounded-top {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important;
  }

  .image-wrapper.rounded-4-top {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important;
  }

  .image-wrapper.bg-cover {
    min-height: 15rem;
  }
}

figure {
  margin: 0;
  padding: 0;
}

figure img {
  width: 100%;
  max-width: 100%;
  height: auto !important;
}

.img-blob img {
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  height: auto;
  width: 100%;
  max-width: 100%;
  -webkit-mask-size: auto auto;
}

.img-blob.blob1 img {
  -webkit-mask-image: url(/img/blob.svg);
  mask-image: url(/img/blob.svg);
}

.img-blob.blob2 img {
  -webkit-mask-image: url(/img/blob2.svg);
  mask-image: url(/img/blob2.svg);
}

.map iframe {
  display: block;
}

.map.map-full {
  height: 100%;
  min-height: 15rem;
}

[class*=mt-n],
[class*=mb-n] {
  position: relative;
  z-index: 3;
}

.mb-n50p {
  transform: translateY(50%);
}

.mt-n50p {
  transform: translateY(-50%);
}

@media screen and (min-width: 576px) {
  .mb-sm-n50p {
    transform: translateY(50%);
  }

  .mt-sm-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 768px) {
  .mb-md-n50p {
    transform: translateY(50%);
  }

  .mt-md-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 992px) {
  .mb-lg-n50p {
    transform: translateY(50%);
  }

  .mt-lg-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1200px) {
  .mb-xl-n50p {
    transform: translateY(50%);
  }

  .mt-xl-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1400px) {
  .mb-xxl-n50p {
    transform: translateY(50%);
  }

  .mt-xxl-n50p {
    transform: translateY(-50%);
  }
}

@media (max-width: 1199.98px) {
  .border-radius-lg-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

@media (min-width: 992px) {
  .cc-2 {
    -moz-column-count: 2;
    column-count: 2;
  }

  .cc-2 li {
    display: inline-block;
    width: 100%;
  }

  .cc-3 {
    -moz-column-count: 3;
    column-count: 3;
  }

  .cc-3 li {
    display: inline-block;
    width: 100%;
  }
}

.bg-soft-blue {
  background-color: #f1f5fd !important;
}

.bg-soft-purple {
  background-color: #f5f5fc !important;
}

.bg-soft-violet {
  background-color: #f8f5fb !important;
}

.bg-soft-pink {
  background-color: #fcf4f6 !important;
}

.bg-soft-red {
  background-color: #fdf3f4 !important;
}

.bg-soft-orange {
  background-color: #fef6f5 !important;
}

.bg-soft-green {
  background-color: #f4faf8 !important;
}

.bg-soft-leaf {
  background-color: #f5faf7 !important;
}

.bg-soft-aqua {
  background-color: #f2f8fb !important;
}

.bg-soft-navy {
  background-color: #f0f1f2 !important;
}

.bg-soft-ash {
  background-color: #f7f7f8 !important;
}

.bg-soft-gray {
  background-color: #fefeff !important;
}

.bg-soft-dark {
  background-color: #efeff0 !important;
}

.bg-soft-primary {
  background-color: #f1f5fd !important;
}

.bg-soft-gray {
  background: rgba(164, 174, 198, 0.2);
}

.bg-pale-blue {
  background-color: #e0e9fa !important;
}

.bg-pale-purple {
  background-color: #e9eaf8 !important;
}

.bg-pale-violet {
  background-color: #f0eaf6 !important;
}

.bg-pale-pink {
  background-color: #f8e7ec !important;
}

.bg-pale-red {
  background-color: #fae6e7 !important;
}

.bg-pale-orange {
  background-color: #feece9 !important;
}

.bg-pale-yellow {
  background-color: #fef3e4 !important;
}

.bg-pale-green {
  background-color: #e7f5f0 !important;
}

.bg-pale-leaf {
  background-color: #eaf3ef !important;
}

.bg-pale-aqua {
  background-color: #e4f1f6 !important;
}

.bg-pale-navy {
  background-color: #dfe0e3 !important;
}

.bg-pale-ash {
  background-color: #eeeff0 !important;
}

.bg-pale-dark {
  background-color: #dcddde !important;
}

.bg-pale-primary {
  background-color: #e0e9fa !important;
}

.bg-gradient-blue {
  background: linear-gradient(180deg, #f1f5fd, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-blue {
  background: linear-gradient(0deg, #f1f5fd, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-purple {
  background: linear-gradient(180deg, #f5f5fc, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-purple {
  background: linear-gradient(0deg, #f5f5fc, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-violet {
  background: linear-gradient(180deg, #f8f5fb, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-violet {
  background: linear-gradient(0deg, #f8f5fb, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-pink {
  background: linear-gradient(180deg, #fcf4f6, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-pink {
  background: linear-gradient(0deg, #fcf4f6, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-red {
  background: linear-gradient(180deg, #fdf3f4, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-red {
  background: linear-gradient(0deg, #fdf3f4, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-orange {
  background: linear-gradient(180deg, #fef6f5, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-orange {
  background: linear-gradient(0deg, #fef6f5, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-green {
  background: linear-gradient(180deg, #f4faf8, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-green {
  background: linear-gradient(0deg, #f4faf8, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-leaf {
  background: linear-gradient(180deg, #f5faf7, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-leaf {
  background: linear-gradient(0deg, #f5faf7, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-aqua {
  background: linear-gradient(180deg, #f2f8fb, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-aqua {
  background: linear-gradient(0deg, #f2f8fb, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-navy {
  background: linear-gradient(180deg, #f0f1f2, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-navy {
  background: linear-gradient(0deg, #f0f1f2, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-ash {
  background: linear-gradient(180deg, #f7f7f8, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-ash {
  background: linear-gradient(0deg, #f7f7f8, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-gray {
  background: linear-gradient(180deg, #fefeff, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-gray {
  background: linear-gradient(0deg, #fefeff, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-dark {
  background: linear-gradient(180deg, #efeff0, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-dark {
  background: linear-gradient(0deg, #efeff0, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-primary {
  background: linear-gradient(180deg, #f1f5fd, rgba(255, 255, 255, 0) 100%);
}

.bg-gradient-reverse-primary {
  background: linear-gradient(0deg, #f1f5fd, rgba(255, 255, 255, 0) 100%);
}

.wrapper.bg-blue.angled.lower-start:after,
.wrapper.bg-blue.angled.upper-start:before {
  border-left-color: #3f78e0;
}

.wrapper.bg-blue.angled.lower-end:after,
.wrapper.bg-blue.angled.upper-end:before {
  border-right-color: #3f78e0;
}

.wrapper.bg-soft-blue.angled.lower-start:after,
.wrapper.bg-soft-blue.angled.upper-start:before {
  border-left-color: #f1f5fd;
}

.wrapper.bg-soft-blue.angled.lower-end:after,
.wrapper.bg-soft-blue.angled.upper-end:before {
  border-right-color: #f1f5fd;
}

.wrapper.bg-pale-blue.angled.lower-start:after,
.wrapper.bg-pale-blue.angled.upper-start:before {
  border-left-color: #e0e9fa;
}

.wrapper.bg-pale-blue.angled.lower-end:after,
.wrapper.bg-pale-blue.angled.upper-end:before {
  border-right-color: #e0e9fa;
}

.wrapper.bg-purple.angled.lower-start:after,
.wrapper.bg-purple.angled.upper-start:before {
  border-left-color: #747ed1;
}

.wrapper.bg-purple.angled.lower-end:after,
.wrapper.bg-purple.angled.upper-end:before {
  border-right-color: #747ed1;
}

.wrapper.bg-soft-purple.angled.lower-start:after,
.wrapper.bg-soft-purple.angled.upper-start:before {
  border-left-color: #f5f5fc;
}

.wrapper.bg-soft-purple.angled.lower-end:after,
.wrapper.bg-soft-purple.angled.upper-end:before {
  border-right-color: #f5f5fc;
}

.wrapper.bg-pale-purple.angled.lower-start:after,
.wrapper.bg-pale-purple.angled.upper-start:before {
  border-left-color: #e9eaf8;
}

.wrapper.bg-pale-purple.angled.lower-end:after,
.wrapper.bg-pale-purple.angled.upper-end:before {
  border-right-color: #e9eaf8;
}

.wrapper.bg-violet.angled.lower-start:after,
.wrapper.bg-violet.angled.upper-start:before {
  border-left-color: #a07cc5;
}

.wrapper.bg-violet.angled.lower-end:after,
.wrapper.bg-violet.angled.upper-end:before {
  border-right-color: #a07cc5;
}

.wrapper.bg-soft-violet.angled.lower-start:after,
.wrapper.bg-soft-violet.angled.upper-start:before {
  border-left-color: #f8f5fb;
}

.wrapper.bg-soft-violet.angled.lower-end:after,
.wrapper.bg-soft-violet.angled.upper-end:before {
  border-right-color: #f8f5fb;
}

.wrapper.bg-pale-violet.angled.lower-start:after,
.wrapper.bg-pale-violet.angled.upper-start:before {
  border-left-color: #f0eaf6;
}

.wrapper.bg-pale-violet.angled.lower-end:after,
.wrapper.bg-pale-violet.angled.upper-end:before {
  border-right-color: #f0eaf6;
}

.wrapper.bg-pink.angled.lower-start:after,
.wrapper.bg-pink.angled.upper-start:before {
  border-left-color: #d16b86;
}

.wrapper.bg-pink.angled.lower-end:after,
.wrapper.bg-pink.angled.upper-end:before {
  border-right-color: #d16b86;
}

.wrapper.bg-soft-pink.angled.lower-start:after,
.wrapper.bg-soft-pink.angled.upper-start:before {
  border-left-color: #fcf4f6;
}

.wrapper.bg-soft-pink.angled.lower-end:after,
.wrapper.bg-soft-pink.angled.upper-end:before {
  border-right-color: #fcf4f6;
}

.wrapper.bg-pale-pink.angled.lower-start:after,
.wrapper.bg-pale-pink.angled.upper-start:before {
  border-left-color: #f8e7ec;
}

.wrapper.bg-pale-pink.angled.lower-end:after,
.wrapper.bg-pale-pink.angled.upper-end:before {
  border-right-color: #f8e7ec;
}

.wrapper.bg-red.angled.lower-start:after,
.wrapper.bg-red.angled.upper-start:before {
  border-left-color: #e2626b;
}

.wrapper.bg-red.angled.lower-end:after,
.wrapper.bg-red.angled.upper-end:before {
  border-right-color: #e2626b;
}

.wrapper.bg-soft-red.angled.lower-start:after,
.wrapper.bg-soft-red.angled.upper-start:before {
  border-left-color: #fdf3f4;
}

.wrapper.bg-soft-red.angled.lower-end:after,
.wrapper.bg-soft-red.angled.upper-end:before {
  border-right-color: #fdf3f4;
}

.wrapper.bg-pale-red.angled.lower-start:after,
.wrapper.bg-pale-red.angled.upper-start:before {
  border-left-color: #fae6e7;
}

.wrapper.bg-pale-red.angled.lower-end:after,
.wrapper.bg-pale-red.angled.upper-end:before {
  border-right-color: #fae6e7;
}

.wrapper.bg-orange.angled.lower-start:after,
.wrapper.bg-orange.angled.upper-start:before {
  border-left-color: #f78b77;
}

.wrapper.bg-orange.angled.lower-end:after,
.wrapper.bg-orange.angled.upper-end:before {
  border-right-color: #f78b77;
}

.wrapper.bg-soft-orange.angled.lower-start:after,
.wrapper.bg-soft-orange.angled.upper-start:before {
  border-left-color: #fef6f5;
}

.wrapper.bg-soft-orange.angled.lower-end:after,
.wrapper.bg-soft-orange.angled.upper-end:before {
  border-right-color: #fef6f5;
}

.wrapper.bg-pale-orange.angled.lower-start:after,
.wrapper.bg-pale-orange.angled.upper-start:before {
  border-left-color: #feece9;
}

.wrapper.bg-pale-orange.angled.lower-end:after,
.wrapper.bg-pale-orange.angled.upper-end:before {
  border-right-color: #feece9;
}

.wrapper.bg-yellow.angled.lower-start:after,
.wrapper.bg-yellow.angled.upper-start:before {
  border-left-color: #fab758;
}

.wrapper.bg-yellow.angled.lower-end:after,
.wrapper.bg-yellow.angled.upper-end:before {
  border-right-color: #fab758;
}

.wrapper.bg-soft-yellow.angled.lower-start:after,
.wrapper.bg-soft-yellow.angled.upper-start:before {
  border-left-color: #fffaf2;
}

.wrapper.bg-soft-yellow.angled.lower-end:after,
.wrapper.bg-soft-yellow.angled.upper-end:before {
  border-right-color: #fffaf2;
}

.wrapper.bg-pale-yellow.angled.lower-start:after,
.wrapper.bg-pale-yellow.angled.upper-start:before {
  border-left-color: #fef3e4;
}

.wrapper.bg-pale-yellow.angled.lower-end:after,
.wrapper.bg-pale-yellow.angled.upper-end:before {
  border-right-color: #fef3e4;
}

.wrapper.bg-green.angled.lower-start:after,
.wrapper.bg-green.angled.upper-start:before {
  border-left-color: #6bbea3;
}

.wrapper.bg-green.angled.lower-end:after,
.wrapper.bg-green.angled.upper-end:before {
  border-right-color: #6bbea3;
}

.wrapper.bg-soft-green.angled.lower-start:after,
.wrapper.bg-soft-green.angled.upper-start:before {
  border-left-color: #f4faf8;
}

.wrapper.bg-soft-green.angled.lower-end:after,
.wrapper.bg-soft-green.angled.upper-end:before {
  border-right-color: #f4faf8;
}

.wrapper.bg-pale-green.angled.lower-start:after,
.wrapper.bg-pale-green.angled.upper-start:before {
  border-left-color: #e7f5f0;
}

.wrapper.bg-pale-green.angled.lower-end:after,
.wrapper.bg-pale-green.angled.upper-end:before {
  border-right-color: #e7f5f0;
}

.wrapper.bg-leaf.angled.lower-start:after,
.wrapper.bg-leaf.angled.upper-start:before {
  border-left-color: #7cb798;
}

.wrapper.bg-leaf.angled.lower-end:after,
.wrapper.bg-leaf.angled.upper-end:before {
  border-right-color: #7cb798;
}

.wrapper.bg-soft-leaf.angled.lower-start:after,
.wrapper.bg-soft-leaf.angled.upper-start:before {
  border-left-color: #f5faf7;
}

.wrapper.bg-soft-leaf.angled.lower-end:after,
.wrapper.bg-soft-leaf.angled.upper-end:before {
  border-right-color: #f5faf7;
}

.wrapper.bg-pale-leaf.angled.lower-start:after,
.wrapper.bg-pale-leaf.angled.upper-start:before {
  border-left-color: #eaf3ef;
}

.wrapper.bg-pale-leaf.angled.lower-end:after,
.wrapper.bg-pale-leaf.angled.upper-end:before {
  border-right-color: #eaf3ef;
}

.wrapper.bg-aqua.angled.lower-start:after,
.wrapper.bg-aqua.angled.upper-start:before {
  border-left-color: #54a8c7;
}

.wrapper.bg-aqua.angled.lower-end:after,
.wrapper.bg-aqua.angled.upper-end:before {
  border-right-color: #54a8c7;
}

.wrapper.bg-soft-aqua.angled.lower-start:after,
.wrapper.bg-soft-aqua.angled.upper-start:before {
  border-left-color: #f2f8fb;
}

.wrapper.bg-soft-aqua.angled.lower-end:after,
.wrapper.bg-soft-aqua.angled.upper-end:before {
  border-right-color: #f2f8fb;
}

.wrapper.bg-pale-aqua.angled.lower-start:after,
.wrapper.bg-pale-aqua.angled.upper-start:before {
  border-left-color: #e4f1f6;
}

.wrapper.bg-pale-aqua.angled.lower-end:after,
.wrapper.bg-pale-aqua.angled.upper-end:before {
  border-right-color: #e4f1f6;
}

.wrapper.bg-navy.angled.lower-start:after,
.wrapper.bg-navy.angled.upper-start:before {
  border-left-color: #343f52;
}

.wrapper.bg-navy.angled.lower-end:after,
.wrapper.bg-navy.angled.upper-end:before {
  border-right-color: #343f52;
}

.wrapper.bg-soft-navy.angled.lower-start:after,
.wrapper.bg-soft-navy.angled.upper-start:before {
  border-left-color: #f0f1f2;
}

.wrapper.bg-soft-navy.angled.lower-end:after,
.wrapper.bg-soft-navy.angled.upper-end:before {
  border-right-color: #f0f1f2;
}

.wrapper.bg-pale-navy.angled.lower-start:after,
.wrapper.bg-pale-navy.angled.upper-start:before {
  border-left-color: #dfe0e3;
}

.wrapper.bg-pale-navy.angled.lower-end:after,
.wrapper.bg-pale-navy.angled.upper-end:before {
  border-right-color: #dfe0e3;
}

.wrapper.bg-ash.angled.lower-start:after,
.wrapper.bg-ash.angled.upper-start:before {
  border-left-color: #9499a3;
}

.wrapper.bg-ash.angled.lower-end:after,
.wrapper.bg-ash.angled.upper-end:before {
  border-right-color: #9499a3;
}

.wrapper.bg-soft-ash.angled.lower-start:after,
.wrapper.bg-soft-ash.angled.upper-start:before {
  border-left-color: #f7f7f8;
}

.wrapper.bg-soft-ash.angled.lower-end:after,
.wrapper.bg-soft-ash.angled.upper-end:before {
  border-right-color: #f7f7f8;
}

.wrapper.bg-pale-ash.angled.lower-start:after,
.wrapper.bg-pale-ash.angled.upper-start:before {
  border-left-color: #eeeff0;
}

.wrapper.bg-pale-ash.angled.lower-end:after,
.wrapper.bg-pale-ash.angled.upper-end:before {
  border-right-color: #eeeff0;
}

.wrapper.bg-white.angled.lower-start:after,
.wrapper.bg-white.angled.upper-start:before {
  border-left-color: #fff;
}

.wrapper.bg-white.angled.lower-end:after,
.wrapper.bg-white.angled.upper-end:before {
  border-right-color: #fff;
}

.wrapper.bg-soft-white.angled.lower-start:after,
.wrapper.bg-soft-white.angled.upper-start:before {
  border-left-color: white;
}

.wrapper.bg-soft-white.angled.lower-end:after,
.wrapper.bg-soft-white.angled.upper-end:before {
  border-right-color: white;
}

.wrapper.bg-pale-white.angled.lower-start:after,
.wrapper.bg-pale-white.angled.upper-start:before {
  border-left-color: white;
}

.wrapper.bg-pale-white.angled.lower-end:after,
.wrapper.bg-pale-white.angled.upper-end:before {
  border-right-color: white;
}

.wrapper.bg-light.angled.lower-start:after,
.wrapper.bg-light.angled.upper-start:before {
  border-left-color: #fefefe;
}

.wrapper.bg-light.angled.lower-end:after,
.wrapper.bg-light.angled.upper-end:before {
  border-right-color: #fefefe;
}

.wrapper.bg-soft-light.angled.lower-start:after,
.wrapper.bg-soft-light.angled.upper-start:before {
  border-left-color: white;
}

.wrapper.bg-soft-light.angled.lower-end:after,
.wrapper.bg-soft-light.angled.upper-end:before {
  border-right-color: white;
}

.wrapper.bg-pale-light.angled.lower-start:after,
.wrapper.bg-pale-light.angled.upper-start:before {
  border-left-color: white;
}

.wrapper.bg-pale-light.angled.lower-end:after,
.wrapper.bg-pale-light.angled.upper-end:before {
  border-right-color: white;
}

.wrapper.bg-gray.angled.lower-start:after,
.wrapper.bg-gray.angled.upper-start:before {
  border-left-color: #f6f7f9;
}

.wrapper.bg-gray.angled.lower-end:after,
.wrapper.bg-gray.angled.upper-end:before {
  border-right-color: #f6f7f9;
}

.wrapper.bg-soft-gray.angled.lower-start:after,
.wrapper.bg-soft-gray.angled.upper-start:before {
  border-left-color: #fefeff;
}

.wrapper.bg-soft-gray.angled.lower-end:after,
.wrapper.bg-soft-gray.angled.upper-end:before {
  border-right-color: #fefeff;
}

.wrapper.bg-pale-gray.angled.lower-start:after,
.wrapper.bg-pale-gray.angled.upper-start:before {
  border-left-color: #fefefe;
}

.wrapper.bg-pale-gray.angled.lower-end:after,
.wrapper.bg-pale-gray.angled.upper-end:before {
  border-right-color: #fefefe;
}

.wrapper.bg-dark.angled.lower-start:after,
.wrapper.bg-dark.angled.upper-start:before {
  border-left-color: #262b32;
}

.wrapper.bg-dark.angled.lower-end:after,
.wrapper.bg-dark.angled.upper-end:before {
  border-right-color: #262b32;
}

.wrapper.bg-soft-dark.angled.lower-start:after,
.wrapper.bg-soft-dark.angled.upper-start:before {
  border-left-color: #efeff0;
}

.wrapper.bg-soft-dark.angled.lower-end:after,
.wrapper.bg-soft-dark.angled.upper-end:before {
  border-right-color: #efeff0;
}

.wrapper.bg-pale-dark.angled.lower-start:after,
.wrapper.bg-pale-dark.angled.upper-start:before {
  border-left-color: #dcddde;
}

.wrapper.bg-pale-dark.angled.lower-end:after,
.wrapper.bg-pale-dark.angled.upper-end:before {
  border-right-color: #dcddde;
}

.wrapper.bg-primary.angled.lower-start:after,
.wrapper.bg-primary.angled.upper-start:before {
  border-left-color: #3f78e0;
}

.wrapper.bg-primary.angled.lower-end:after,
.wrapper.bg-primary.angled.upper-end:before {
  border-right-color: #3f78e0;
}

.wrapper.bg-soft-primary.angled.lower-start:after,
.wrapper.bg-soft-primary.angled.upper-start:before {
  border-left-color: #f1f5fd;
}

.wrapper.bg-soft-primary.angled.lower-end:after,
.wrapper.bg-soft-primary.angled.upper-end:before {
  border-right-color: #f1f5fd;
}

.wrapper.bg-pale-primary.angled.lower-start:after,
.wrapper.bg-pale-primary.angled.upper-start:before {
  border-left-color: #e0e9fa;
}

.wrapper.bg-pale-primary.angled.lower-end:after,
.wrapper.bg-pale-primary.angled.upper-end:before {
  border-right-color: #e0e9fa;
}

.bg-soft-yellow {
  background-color: #fff8ef !important;
}

.bg-gradient-yellow {
  background: linear-gradient(180deg, #fff8ef, rgba(255, 255, 255, 0) 100%) !important;
}

.bg-gradient-reverse-yellow {
  background: linear-gradient(0deg, #fff8ef, rgba(255, 255, 255, 0) 100%) !important;
}

.wrapper.bg-soft-yellow.angled.lower-start:after,
.wrapper.bg-soft-yellow.angled.upper-start:before {
  border-left-color: #fff8ef !important;
}

.wrapper.bg-soft-yellow.angled.lower-end:after,
.wrapper.bg-soft-yellow.angled.upper-end:before {
  border-right-color: #fff8ef !important;
}

.bg-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

.link-blue {
  color: #3f78e0;
}

.link-blue:hover,
.link-blue:focus {
  color: #3f78e0;
}

.link-purple {
  color: #747ed1;
}

.link-purple:hover,
.link-purple:focus {
  color: #747ed1;
}

.link-violet {
  color: #a07cc5;
}

.link-violet:hover,
.link-violet:focus {
  color: #a07cc5;
}

.link-pink {
  color: #d16b86;
}

.link-pink:hover,
.link-pink:focus {
  color: #d16b86;
}

.link-red {
  color: #e2626b;
}

.link-red:hover,
.link-red:focus {
  color: #e2626b;
}

.link-orange {
  color: #f78b77;
}

.link-orange:hover,
.link-orange:focus {
  color: #f78b77;
}

.link-yellow {
  color: #fab758;
}

.link-yellow:hover,
.link-yellow:focus {
  color: #fab758;
}

.link-green {
  color: #6bbea3;
}

.link-green:hover,
.link-green:focus {
  color: #6bbea3;
}

.link-leaf {
  color: #7cb798;
}

.link-leaf:hover,
.link-leaf:focus {
  color: #7cb798;
}

.link-aqua {
  color: #54a8c7;
}

.link-aqua:hover,
.link-aqua:focus {
  color: #54a8c7;
}

.link-navy {
  color: #343f52;
}

.link-navy:hover,
.link-navy:focus {
  color: #343f52;
}

.link-ash {
  color: #9499a3;
}

.link-ash:hover,
.link-ash:focus {
  color: #9499a3;
}

.link-white {
  color: #fff;
}

.link-white:hover,
.link-white:focus {
  color: #fff;
}

.link-light {
  color: #fefefe;
}

.link-light:hover,
.link-light:focus {
  color: #fefefe;
}

.link-gray {
  color: #f6f7f9;
}

.link-gray:hover,
.link-gray:focus {
  color: #f6f7f9;
}

.link-primary {
  color: var(--secondaryDark);
}

.link-primary:hover,
.link-primary:focus {
  color: var(--secondaryDark);
}

.link-secondary {
  color: #aab0bc;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #aab0bc;
}

.link-success {
  color: #6bbea3;
}

.link-success:hover,
.link-success:focus {
  color: #6bbea3;
}

.link-info {
  color: #54a8c7;
}

.link-info:hover,
.link-info:focus {
  color: #54a8c7;
}

.link-warning {
  color: #fab758;
}

.link-warning:hover,
.link-warning:focus {
  color: #fab758;
}

.link-danger {
  color: #e2626b;
}

.link-danger:hover,
.link-danger:focus {
  color: #e2626b;
}

.link-dark {
  color: #343f52;
}

blockquote {
  border-left: 0.15rem solid #3f78e0;
  padding-left: 1rem;
  font-size: 0.85rem;
  line-height: 1.7;
  font-weight: 500;
}

blockquote.border-0 {
  padding-left: 0;
}

blockquote.icon {
  position: relative;
  border: 0;
  padding: 0;
}

blockquote.icon:before {
  content: "\201d";
  position: absolute;
  top: -1.5rem;
  left: -0.9rem;
  color: rgba(52, 63, 82, 0.05);
  font-size: 10rem;
  line-height: 1;
  z-index: 1;
}

blockquote.icon-top {
  padding-top: 3.75rem;
  position: relative;
}

blockquote.icon-top:before {
  content: "\201c";
  color: #aab0bc;
  opacity: 0.3;
  font-size: 6.5rem;
  font-weight: normal;
  top: 0;
  left: -0.3rem;
}

blockquote.icon-top.text-white:before {
  color: #fff;
}

blockquote.text-center.icon-top:before {
  left: 50%;
  transform: translateX(-52%);
}

.blockquote-footer {
  font-weight: 700;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}

.blockquote-details {
  display: flex;
  align-items: center;
  text-align: left;
}

.blockquote-details p {
  font-size: 0.8rem;
}

.blockquote-details .info {
  padding-left: 1rem;
}

.blockquote-details .icon-img img {
  width: 3.5rem !important;
}

.dropcap {
  display: block;
  float: left;
  font-size: calc(1.375rem + 1.5vw);
  line-height: 1;
  font-weight: 500;
  padding: 0;
  margin: 0.2rem 0.6rem 0 0;
}

@media (min-width: 1200px) {
  .dropcap {
    font-size: 2.5rem;
  }
}

.dropcap.rounded-circle {
  font-size: calc(1.265rem + 0.18vw);
  height: 2.3rem;
  width: 2.3rem;
  margin: 0.25rem 0.5rem 0 0;
  padding-bottom: 0.15rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) {
  .dropcap.rounded-circle {
    font-size: 1.4rem;
  }
}

.unordered-list {
  padding-left: 0;
  list-style: none;
}

.unordered-list li {
  position: relative;
  padding-left: 1rem;
}

.unordered-list>li:before {
  font-family: sans-serif;
  position: absolute;
  top: -0.15rem;
  left: 0;
  font-size: 1rem;
  content: "\2022";
}

.unordered-list.bullet-blue li:before {
  color: #3f78e0;
}

.unordered-list.bullet-purple li:before {
  color: #747ed1;
}

.unordered-list.bullet-violet li:before {
  color: #a07cc5;
}

.unordered-list.bullet-pink li:before {
  color: #d16b86;
}

.unordered-list.bullet-red li:before {
  color: #e2626b;
}

.unordered-list.bullet-orange li:before {
  color: #f78b77;
}

.unordered-list.bullet-yellow li:before {
  color: #fab758;
}

.unordered-list.bullet-green li:before {
  color: #6bbea3;
}

.unordered-list.bullet-leaf li:before {
  color: #7cb798;
}

.unordered-list.bullet-aqua li:before {
  color: #54a8c7;
}

.unordered-list.bullet-navy li:before {
  color: #343f52;
}

.unordered-list.bullet-ash li:before {
  color: #9499a3;
}

.unordered-list.bullet-white li:before {
  color: #fff;
}

.unordered-list.bullet-light li:before {
  color: #fefefe;
}

.unordered-list.bullet-gray li:before {
  color: #f6f7f9;
}

.unordered-list.bullet-dark li:before {
  color: #262b32;
}

.unordered-list.bullet-primary li:before {
  color: #3f78e0;
}

.icon-list {
  padding-left: 0;
  list-style: none;
}

.icon-list li {
  position: relative;
  padding-left: 1.25rem;
}

.icon-list.bullet-bg li {
  padding-left: 1.5rem;
}

.icon-list.bullet-soft-blue i {
  color: #3f78e0;
}

.icon-list.bullet-soft-blue.bullet-bg i {
  background-color: #dce7f9;
}

.icon-list.bullet-bg i[class*=bullet-soft-blue] {
  background-color: #dce7f9 !important;
  color: #3f78e0 !important;
}

.icon-list.bullet-soft-purple i {
  color: #747ed1;
}

.icon-list.bullet-soft-purple.bullet-bg i {
  background-color: #e6e8f7;
}

.icon-list.bullet-bg i[class*=bullet-soft-purple] {
  background-color: #e6e8f7 !important;
  color: #747ed1 !important;
}

.icon-list.bullet-soft-violet i {
  color: #a07cc5;
}

.icon-list.bullet-soft-violet.bullet-bg i {
  background-color: #eee7f5;
}

.icon-list.bullet-bg i[class*=bullet-soft-violet] {
  background-color: #eee7f5 !important;
  color: #a07cc5 !important;
}

.icon-list.bullet-soft-pink i {
  color: #d16b86;
}

.icon-list.bullet-soft-pink.bullet-bg i {
  background-color: #f7e4e9;
}

.icon-list.bullet-bg i[class*=bullet-soft-pink] {
  background-color: #f7e4e9 !important;
  color: #d16b86 !important;
}

.icon-list.bullet-soft-red i {
  color: #e2626b;
}

.icon-list.bullet-soft-red.bullet-bg i {
  background-color: #fae3e4;
}

.icon-list.bullet-bg i[class*=bullet-soft-red] {
  background-color: #fae3e4 !important;
  color: #e2626b !important;
}

.icon-list.bullet-soft-orange i {
  color: #f78b77;
}

.icon-list.bullet-soft-orange.bullet-bg i {
  background-color: #feeae7;
}

.icon-list.bullet-bg i[class*=bullet-soft-orange] {
  background-color: #feeae7 !important;
  color: #f78b77 !important;
}

.icon-list.bullet-soft-green i {
  color: #6bbea3;
}

.icon-list.bullet-soft-green.bullet-bg i {
  background-color: #e4f3ee;
}

.icon-list.bullet-bg i[class*=bullet-soft-green] {
  background-color: #e4f3ee !important;
  color: #6bbea3 !important;
}

.icon-list.bullet-soft-leaf i {
  color: #7cb798;
}

.icon-list.bullet-soft-leaf.bullet-bg i {
  background-color: #e7f2ec;
}

.icon-list.bullet-bg i[class*=bullet-soft-leaf] {
  background-color: #e7f2ec !important;
  color: #7cb798 !important;
}

.icon-list.bullet-soft-aqua i {
  color: #54a8c7;
}

.icon-list.bullet-soft-aqua.bullet-bg i {
  background-color: #e0eff5;
}

.icon-list.bullet-bg i[class*=bullet-soft-aqua] {
  background-color: #e0eff5 !important;
  color: #54a8c7 !important;
}

.icon-list.bullet-soft-navy i {
  color: #343f52;
}

.icon-list.bullet-soft-navy.bullet-bg i {
  background-color: #dadce0;
}

.icon-list.bullet-bg i[class*=bullet-soft-navy] {
  background-color: #dadce0 !important;
  color: #343f52 !important;
}

.icon-list.bullet-soft-ash i {
  color: #9499a3;
}

.icon-list.bullet-soft-ash.bullet-bg i {
  background-color: #ecedee;
}

.icon-list.bullet-bg i[class*=bullet-soft-ash] {
  background-color: #ecedee !important;
  color: #9499a3 !important;
}

.icon-list.bullet-soft-white i {
  color: #fff;
}

.icon-list.bullet-soft-white.bullet-bg i {
  background-color: white;
}

.icon-list.bullet-bg i[class*=bullet-soft-white] {
  background-color: white !important;
  color: #fff !important;
}

.icon-list.bullet-soft-light i {
  color: #fefefe;
}

.icon-list.bullet-soft-light.bullet-bg i {
  background-color: white;
}

.icon-list.bullet-bg i[class*=bullet-soft-light] {
  background-color: white !important;
  color: #fefefe !important;
}

.icon-list.bullet-soft-gray i {
  color: #f6f7f9;
}

.icon-list.bullet-soft-gray.bullet-bg i {
  background-color: #fdfefe;
}

.icon-list.bullet-bg i[class*=bullet-soft-gray] {
  background-color: #fdfefe !important;
  color: #f6f7f9 !important;
}

.icon-list.bullet-soft-dark i {
  color: #262b32;
}

.icon-list.bullet-soft-dark.bullet-bg i {
  background-color: #d8d9da;
}

.icon-list.bullet-bg i[class*=bullet-soft-dark] {
  background-color: #d8d9da !important;
  color: #262b32 !important;
}

.icon-list.bullet-soft-primary i {
  color: #3f78e0;
}

.icon-list.bullet-soft-primary.bullet-bg i {
  background-color: #dce7f9;
}

.icon-list.bullet-bg i[class*=bullet-soft-primary] {
  background-color: #dce7f9 !important;
  color: #3f78e0 !important;
}

.icon-list.bullet-soft-yellow i,
.icon-list.bullet-bg i[class*=bullet-soft-yellow] {
  color: #fab758;
}

.icon-list.bullet-soft-yellow.bullet-bg i,
.icon-list.bullet-bg i[class*=bullet-soft-yellow] {
  background-color: #feeed9;
}

.icon-list.bullet-blue i {
  color: #3f78e0;
}

.icon-list.bullet-blue.bullet-bg i {
  background-color: #3f78e0;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-blue] {
  background-color: #3f78e0 !important;
  color: white !important;
}

.icon-list.bullet-purple i {
  color: #747ed1;
}

.icon-list.bullet-purple.bullet-bg i {
  background-color: #747ed1;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-purple] {
  background-color: #747ed1 !important;
  color: white !important;
}

.icon-list.bullet-violet i {
  color: #a07cc5;
}

.icon-list.bullet-violet.bullet-bg i {
  background-color: #a07cc5;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-violet] {
  background-color: #a07cc5 !important;
  color: white !important;
}

.icon-list.bullet-pink i {
  color: #d16b86;
}

.icon-list.bullet-pink.bullet-bg i {
  background-color: #d16b86;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-pink] {
  background-color: #d16b86 !important;
  color: white !important;
}

.icon-list.bullet-red i {
  color: #e2626b;
}

.icon-list.bullet-red.bullet-bg i {
  background-color: #e2626b;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-red] {
  background-color: #e2626b !important;
  color: white !important;
}

.icon-list.bullet-orange i {
  color: #f78b77;
}

.icon-list.bullet-orange.bullet-bg i {
  background-color: #f78b77;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-orange] {
  background-color: #f78b77 !important;
  color: white !important;
}

.icon-list.bullet-yellow i {
  color: #fab758;
}

.icon-list.bullet-yellow.bullet-bg i {
  background-color: #fab758;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-yellow] {
  background-color: #fab758 !important;
  color: white !important;
}

.icon-list.bullet-green i {
  color: #6bbea3;
}

.icon-list.bullet-green.bullet-bg i {
  background-color: #6bbea3;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-green] {
  background-color: #6bbea3 !important;
  color: white !important;
}

.icon-list.bullet-leaf i {
  color: #7cb798;
}

.icon-list.bullet-leaf.bullet-bg i {
  background-color: #7cb798;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-leaf] {
  background-color: #7cb798 !important;
  color: white !important;
}

.icon-list.bullet-aqua i {
  color: #54a8c7;
}

.icon-list.bullet-aqua.bullet-bg i {
  background-color: #54a8c7;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-aqua] {
  background-color: #54a8c7 !important;
  color: white !important;
}

.icon-list.bullet-navy i {
  color: #343f52;
}

.icon-list.bullet-navy.bullet-bg i {
  background-color: #343f52;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-navy] {
  background-color: #343f52 !important;
  color: white !important;
}

.icon-list.bullet-ash i {
  color: #9499a3;
}

.icon-list.bullet-ash.bullet-bg i {
  background-color: #9499a3;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-ash] {
  background-color: #9499a3 !important;
  color: white !important;
}

.icon-list.bullet-white i {
  color: #fff;
}

.icon-list.bullet-white.bullet-bg i {
  background-color: #fff;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-white] {
  background-color: #fff !important;
  color: white !important;
}

.icon-list.bullet-light i {
  color: #fefefe;
}

.icon-list.bullet-light.bullet-bg i {
  background-color: #fefefe;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-light] {
  background-color: #fefefe !important;
  color: white !important;
}

.icon-list.bullet-gray i {
  color: #f6f7f9;
}

.icon-list.bullet-gray.bullet-bg i {
  background-color: #f6f7f9;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-gray] {
  background-color: #f6f7f9 !important;
  color: white !important;
}

.icon-list.bullet-dark i {
  color: #262b32;
}

.icon-list.bullet-dark.bullet-bg i {
  background-color: #262b32;
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-dark] {
  background-color: #262b32 !important;
  color: white !important;
}

.icon-list.bullet-primary i {
  color: var(--secondaryDark);
}

.icon-list.bullet-primary.bullet-bg i {
  background-color: var(--secondaryDark);
  color: #fff;
}

.icon-list.bullet-bg i[class*=bullet-primary] {
  background-color: var(--secondaryDark) !important;
  color: white !important;
}

.icon-list.bullet-white i,
.icon-list.bullet-bg i.bullet-white {
  color: var(--secondaryDark) !important;
}

.icon-list i {
  position: absolute;
  top: -0.2rem;
  left: 0;
  font-size: 1rem;
}

.icon-list.bullet-bg i {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  top: 0.2rem;
  font-size: 0.8rem;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  display: table;
}

.icon-list.bullet-bg i:before {
  vertical-align: middle;
  display: table-cell;
}

.widget .list-unstyled:not(.tag-list) li+li,
.unordered-list li+li,
.icon-list:not(.row) li+li {
  margin-top: 0.35rem;
}

.list-unstyled li a.active {
  color: #3f78e0 !important;
}

.filter:not(.basic-filter) p {
  margin: 0 1rem 0 0;
  display: inline;
}

.filter:not(.basic-filter) ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

.filter:not(.basic-filter) ul li {
  display: inline;
}

.filter:not(.basic-filter) ul li+li:before {
  content: "";
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 100%;
  margin: 0 0.8rem 0 0.5rem;
  vertical-align: 0.15rem;
  background: rgba(30, 34, 40, 0.2);
}

.filter:not(.basic-filter) ul li a {
  cursor: pointer;
}

.filter:not(.basic-filter) ul li a.active,
.filter:not(.basic-filter) ul li a:hover {
  color: #3f78e0;
}

.filter:not(.basic-filter).dark-filter ul li+li:before {
  background: rgba(255, 255, 255, 0.2);
}

.filter:not(.basic-filter).dark-filter ul li a.active,
.filter:not(.basic-filter).dark-filter ul li a:hover {
  color: #fff;
}

.isotope-filter {
  position: relative;
  z-index: 5;
}

.filter.basic-filter p {
  margin: 0 1rem 0 0;
  display: inline;
  color: #343f52;
  font-weight: 700;
}

.filter.basic-filter ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

.filter.basic-filter ul li {
  display: inline;
  margin-right: 1rem;
}

.filter.basic-filter ul li a {
  cursor: pointer;
  color: #60697b;
}

.filter.basic-filter ul li a.active,
.filter.basic-filter ul li a:hover {
  color: #3f78e0;
}

.filter.basic-filter.dark-filter ul li a.active,
.filter.basic-filter.dark-filter ul li a:hover {
  color: #fff;
}

.image-list {
  padding: 0;
  margin: 0;
}

.image-list p,
.image-list .meta {
  margin: 0;
}

.image-list:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.image-list>li {
  clear: both;
  display: block;
  overflow: hidden;
}

.image-list>li+li {
  margin-top: 1rem;
}

.image-list figure {
  float: left;
  width: 3.5rem;
  height: 3.5rem;
}

.image-list .post-content {
  margin-left: 4.25rem;
  margin-bottom: 0;
  hyphens: auto;
}

.tag-list li {
  display: inline-block;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
}

.tag-list li a:before {
  font-style: normal;
  content: "#";
  font-weight: normal;
  padding-right: 0.2rem;
}

.widget .tag-list li,
.blog.single .post .tag-list li {
  margin-top: 0;
  margin-bottom: 0.45rem;
}

.widget .tag-list li a {
  display: flex;
  align-items: center;
}

.meta,
.post-category,
.filter:not(.basic-filter),
.filter:not(.basic-filter) ul li a {
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-size: 0.7rem;
  font-weight: 700;
  color: #aab0bc;
}

.meta {
  font-size: 0.65rem;
}

.post-category {
  margin-bottom: 0.4rem;
}

.post-meta {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.7rem;
  color: #aab0bc;
}

.post-meta li {
  display: inline-block;
}

.post-meta li a {
  color: #aab0bc;
}

.post-meta li a:hover {
  color: var(--secondaryDark);
  border-color: var(--secondaryDark);
}

.post-meta li:before {
  content: "";
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 100%;
  margin: 0 0.6rem 0;
  vertical-align: 0.15rem;
  background: #aab0bc;
  opacity: 0.5;
}

.post-meta li i {
  padding-right: 0.2rem;
  vertical-align: -0.05rem;
}

.post-meta.text-white li:before {
  background: #fff;
}

.post-header .post-meta {
  font-size: 0.75rem;
}

.post-header .post-meta li:before {
  margin: 0 0.6rem 0 0.4rem;
}

.post-meta li:first-child:before,
article .post-meta li.ms-auto:before {
  display: none;
}

@media (max-width: 767px) {

  .card-footer .post-meta li.post-author,
  .card-footer .post-meta li.post-comments span {
    display: none;
  }

  .post-header .post-meta li.post-author,
  .post-header .post-meta li.post-comments span,
  .post-header .post-meta li.post-likes span {
    display: none;
  }
}

.glyphs {
  padding: 0;
}

.glyphs li {
  display: block;
}

.glyphs li svg {
  margin-bottom: 0.3rem;
  display: inline-block;
}

.glyphs li i:before {
  margin: 0;
  padding: 0;
  color: #343f52;
  font-size: calc(1.285rem + 0.42vw);
}

@media (min-width: 1200px) {
  .glyphs li i:before {
    font-size: 1.6rem;
  }
}

.glyphs li span {
  margin: 0;
  display: block;
  color: #aab0bc;
  font-size: 0.7rem;
}

.glyphs li .card-body {
  padding: 0.5rem 0.2rem 1rem;
}

.glyphs li .card-footer {
  padding: 0.5rem 0.2rem;
  cursor: pointer;
  font-size: 0.7rem;
}

.glyphs-svg li .card-body {
  padding-top: 1rem;
}

.code-wrapper {
  margin-bottom: 0;
  position: relative;
}

.code-wrapper .btn-clipboard {
  font-family: sans-serif;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  transform: none;
  font-size: 0.65rem;
  padding: 0.15rem 0.6rem 0.2rem;
  z-index: 3;
}

.code-wrapper pre {
  margin-bottom: 0;
}

pre[class*=language-] {
  position: relative;
  padding-top: 2rem;
}

pre[class*=language-]:focus {
  outline: none;
}

:not(pre)>code[class*=language-],
pre[class*=language-],
pre {
  margin: 0;
  background: none;
  padding: 2rem;
  font-size: 0.7rem;
}

.external,
.internal,
.code,
.terminal,
.kbd,
.folder,
.file,
mark.doc,
.doc.mark {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0.275rem 0.1rem;
  vertical-align: 2px;
  padding: 0.05rem 0.4rem 0.05rem 1.5rem;
  font-size: 0.65rem;
  border-radius: 0.2rem;
  background: rgba(164, 174, 198, 0.15);
  box-shadow: none;
  border: 0;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

mark.doc,
.doc.mark {
  padding: 0.05rem 0.4rem;
}

.external:after,
.internal:after,
.code:before,
.terminal:before,
.kbd:before,
.folder:before,
.file:before {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  padding-top: 0.05rem;
  margin-right: 0.4rem;
  text-align: center;
  color: inherit;
  font-family: "Unicons";
  font-size: 0.7rem;
  border-radius: 0.2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.external,
.internal {
  color: #60697b;
  padding: 0.05rem 1.6rem 0.05rem 0.4rem;
}

.external:after,
.internal:after {
  color: #60697b;
  font-size: 0.65rem;
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.4rem;
}

.external.text-white:after,
.internal.text-white:after {
  color: #fff;
}

.external:hover,
.internal:hover {
  color: #3f78e0;
}

.external.my-0:after {
  padding-top: 0;
}

.external:after {
  content: "\eac6";
}

.internal {
  padding-right: 1.4rem;
}

.internal:after {
  content: "\e9d2";
  font-size: 0.8rem;
}

.code:before {
  font-size: 0.85rem;
  content: "\e9b9";
}

.terminal:before {
  font-family: "Custom";
  content: "\e905";
  font-size: 0.8rem;
}

.folder:before {
  content: "\eb21";
}

.file:before {
  content: "\eadf";
}

.kbd:before {
  content: "\eb82";
  font-size: 0.8rem;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.text-gradient.text-line.gradient-1:before {
  background: #f857a6;
}

.text-gradient.text-line.gradient-2:before {
  background: #f5b161;
}

.text-gradient.text-line.gradient-3:before {
  background: #FBDA61;
}

.text-gradient.text-line.gradient-4:before {
  background: #9040db;
}

.text-gradient.text-line.gradient-5:before {
  background: #4158D0;
}

.text-gradient.text-line.gradient-6:before {
  background: #08AEEA;
}

.text-gradient.text-line.gradient-7:before {
  background: #0093e9;
}

.fs-sm {
  font-size: 0.7rem;
}

.fs-lg {
  font-size: 1rem;
}

.fs-1 {
  font-size: 0.05rem !important;
}

.fs-2 {
  font-size: 0.1rem !important;
}

.fs-3 {
  font-size: 0.15rem !important;
}

.fs-4 {
  font-size: 0.2rem !important;
}

.fs-5 {
  font-size: 0.25rem !important;
}

.fs-6 {
  font-size: 0.3rem !important;
}

.fs-7 {
  font-size: 0.35rem !important;
}

.fs-8 {
  font-size: 0.4rem !important;
}

.fs-9 {
  font-size: 0.45rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-11 {
  font-size: 0.55rem !important;
}

.fs-12 {
  font-size: 0.6rem !important;
}

.fs-13 {
  font-size: 0.65rem !important;
}

.fs-14 {
  font-size: 0.7rem !important;
}

.fs-15 {
  font-size: 0.75rem !important;
}

.fs-16 {
  font-size: 0.8rem !important;
}

.fs-17 {
  font-size: 0.85rem !important;
}

.fs-18 {
  font-size: 0.9rem !important;
}

.fs-19 {
  font-size: 0.95rem !important;
}

.fs-20 {
  font-size: 1rem !important;
}

.fs-21 {
  font-size: 1.05rem !important;
}

.fs-22 {
  font-size: 1.1rem !important;
}

.fs-23 {
  font-size: 1.15rem !important;
}

.fs-24 {
  font-size: 1.2rem !important;
}

.fs-25 {
  font-size: 1.25rem !important;
}

.fs-26 {
  font-size: calc(1.255rem + 0.06vw) !important;
}

@media (min-width: 1200px) {
  .fs-26 {
    font-size: 1.3rem !important;
  }
}

.fs-27 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

@media (min-width: 1200px) {
  .fs-27 {
    font-size: 1.35rem !important;
  }
}

.fs-28 {
  font-size: calc(1.265rem + 0.18vw) !important;
}

@media (min-width: 1200px) {
  .fs-28 {
    font-size: 1.4rem !important;
  }
}

.fs-29 {
  font-size: calc(1.27rem + 0.24vw) !important;
}

@media (min-width: 1200px) {
  .fs-29 {
    font-size: 1.45rem !important;
  }
}

.fs-30 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

@media (min-width: 1200px) {
  .fs-30 {
    font-size: 1.5rem !important;
  }
}

.fs-31 {
  font-size: calc(1.28rem + 0.36vw) !important;
}

@media (min-width: 1200px) {
  .fs-31 {
    font-size: 1.55rem !important;
  }
}

.fs-32 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

@media (min-width: 1200px) {
  .fs-32 {
    font-size: 1.6rem !important;
  }
}

.fs-33 {
  font-size: calc(1.29rem + 0.48vw) !important;
}

@media (min-width: 1200px) {
  .fs-33 {
    font-size: 1.65rem !important;
  }
}

.fs-34 {
  font-size: calc(1.295rem + 0.54vw) !important;
}

@media (min-width: 1200px) {
  .fs-34 {
    font-size: 1.7rem !important;
  }
}

.fs-35 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

@media (min-width: 1200px) {
  .fs-35 {
    font-size: 1.75rem !important;
  }
}

.fs-36 {
  font-size: calc(1.305rem + 0.66vw) !important;
}

@media (min-width: 1200px) {
  .fs-36 {
    font-size: 1.8rem !important;
  }
}

.fs-37 {
  font-size: calc(1.31rem + 0.72vw) !important;
}

@media (min-width: 1200px) {
  .fs-37 {
    font-size: 1.85rem !important;
  }
}

.fs-38 {
  font-size: calc(1.315rem + 0.78vw) !important;
}

@media (min-width: 1200px) {
  .fs-38 {
    font-size: 1.9rem !important;
  }
}

.fs-39 {
  font-size: calc(1.32rem + 0.84vw) !important;
}

@media (min-width: 1200px) {
  .fs-39 {
    font-size: 1.95rem !important;
  }
}

.fs-40 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

@media (min-width: 1200px) {
  .fs-40 {
    font-size: 2rem !important;
  }
}

.fs-41 {
  font-size: calc(1.33rem + 0.96vw) !important;
}

@media (min-width: 1200px) {
  .fs-41 {
    font-size: 2.05rem !important;
  }
}

.fs-42 {
  font-size: calc(1.335rem + 1.02vw) !important;
}

@media (min-width: 1200px) {
  .fs-42 {
    font-size: 2.1rem !important;
  }
}

.fs-43 {
  font-size: calc(1.34rem + 1.08vw) !important;
}

@media (min-width: 1200px) {
  .fs-43 {
    font-size: 2.15rem !important;
  }
}

.fs-44 {
  font-size: calc(1.345rem + 1.14vw) !important;
}

@media (min-width: 1200px) {
  .fs-44 {
    font-size: 2.2rem !important;
  }
}

.fs-45 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

@media (min-width: 1200px) {
  .fs-45 {
    font-size: 2.25rem !important;
  }
}

.fs-46 {
  font-size: calc(1.355rem + 1.26vw) !important;
}

@media (min-width: 1200px) {
  .fs-46 {
    font-size: 2.3rem !important;
  }
}

.fs-47 {
  font-size: calc(1.36rem + 1.32vw) !important;
}

@media (min-width: 1200px) {
  .fs-47 {
    font-size: 2.35rem !important;
  }
}

.fs-48 {
  font-size: calc(1.365rem + 1.38vw) !important;
}

@media (min-width: 1200px) {
  .fs-48 {
    font-size: 2.4rem !important;
  }
}

.fs-49 {
  font-size: calc(1.37rem + 1.44vw) !important;
}

@media (min-width: 1200px) {
  .fs-49 {
    font-size: 2.45rem !important;
  }
}

.fs-50 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

@media (min-width: 1200px) {
  .fs-50 {
    font-size: 2.5rem !important;
  }
}

.fs-51 {
  font-size: calc(1.38rem + 1.56vw) !important;
}

@media (min-width: 1200px) {
  .fs-51 {
    font-size: 2.55rem !important;
  }
}

.fs-52 {
  font-size: calc(1.385rem + 1.62vw) !important;
}

@media (min-width: 1200px) {
  .fs-52 {
    font-size: 2.6rem !important;
  }
}

.fs-53 {
  font-size: calc(1.39rem + 1.68vw) !important;
}

@media (min-width: 1200px) {
  .fs-53 {
    font-size: 2.65rem !important;
  }
}

.fs-54 {
  font-size: calc(1.395rem + 1.74vw) !important;
}

@media (min-width: 1200px) {
  .fs-54 {
    font-size: 2.7rem !important;
  }
}

.fs-55 {
  font-size: calc(1.4rem + 1.8vw) !important;
}

@media (min-width: 1200px) {
  .fs-55 {
    font-size: 2.75rem !important;
  }
}

.fs-56 {
  font-size: calc(1.405rem + 1.86vw) !important;
}

@media (min-width: 1200px) {
  .fs-56 {
    font-size: 2.8rem !important;
  }
}

.fs-57 {
  font-size: calc(1.41rem + 1.92vw) !important;
}

@media (min-width: 1200px) {
  .fs-57 {
    font-size: 2.85rem !important;
  }
}

.fs-58 {
  font-size: calc(1.415rem + 1.98vw) !important;
}

@media (min-width: 1200px) {
  .fs-58 {
    font-size: 2.9rem !important;
  }
}

.fs-59 {
  font-size: calc(1.42rem + 2.04vw) !important;
}

@media (min-width: 1200px) {
  .fs-59 {
    font-size: 2.95rem !important;
  }
}

.fs-60 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

@media (min-width: 1200px) {
  .fs-60 {
    font-size: 3rem !important;
  }
}

.fs-61 {
  font-size: calc(1.43rem + 2.16vw) !important;
}

@media (min-width: 1200px) {
  .fs-61 {
    font-size: 3.05rem !important;
  }
}

.fs-62 {
  font-size: calc(1.435rem + 2.22vw) !important;
}

@media (min-width: 1200px) {
  .fs-62 {
    font-size: 3.1rem !important;
  }
}

.fs-63 {
  font-size: calc(1.44rem + 2.28vw) !important;
}

@media (min-width: 1200px) {
  .fs-63 {
    font-size: 3.15rem !important;
  }
}

.fs-64 {
  font-size: calc(1.445rem + 2.34vw) !important;
}

@media (min-width: 1200px) {
  .fs-64 {
    font-size: 3.2rem !important;
  }
}

.fs-65 {
  font-size: calc(1.45rem + 2.4vw) !important;
}

@media (min-width: 1200px) {
  .fs-65 {
    font-size: 3.25rem !important;
  }
}

.fs-66 {
  font-size: calc(1.455rem + 2.46vw) !important;
}

@media (min-width: 1200px) {
  .fs-66 {
    font-size: 3.3rem !important;
  }
}

.fs-67 {
  font-size: calc(1.46rem + 2.52vw) !important;
}

@media (min-width: 1200px) {
  .fs-67 {
    font-size: 3.35rem !important;
  }
}

.fs-68 {
  font-size: calc(1.465rem + 2.58vw) !important;
}

@media (min-width: 1200px) {
  .fs-68 {
    font-size: 3.4rem !important;
  }
}

.fs-69 {
  font-size: calc(1.47rem + 2.64vw) !important;
}

@media (min-width: 1200px) {
  .fs-69 {
    font-size: 3.45rem !important;
  }
}

.fs-70 {
  font-size: calc(1.475rem + 2.7vw) !important;
}

@media (min-width: 1200px) {
  .fs-70 {
    font-size: 3.5rem !important;
  }
}

.fs-71 {
  font-size: calc(1.48rem + 2.76vw) !important;
}

@media (min-width: 1200px) {
  .fs-71 {
    font-size: 3.55rem !important;
  }
}

.fs-72 {
  font-size: calc(1.485rem + 2.82vw) !important;
}

@media (min-width: 1200px) {
  .fs-72 {
    font-size: 3.6rem !important;
  }
}

.fs-73 {
  font-size: calc(1.49rem + 2.88vw) !important;
}

@media (min-width: 1200px) {
  .fs-73 {
    font-size: 3.65rem !important;
  }
}

.fs-74 {
  font-size: calc(1.495rem + 2.94vw) !important;
}

@media (min-width: 1200px) {
  .fs-74 {
    font-size: 3.7rem !important;
  }
}

.fs-75 {
  font-size: calc(1.5rem + 3vw) !important;
}

@media (min-width: 1200px) {
  .fs-75 {
    font-size: 3.75rem !important;
  }
}

.fs-76 {
  font-size: calc(1.505rem + 3.06vw) !important;
}

@media (min-width: 1200px) {
  .fs-76 {
    font-size: 3.8rem !important;
  }
}

.fs-77 {
  font-size: calc(1.51rem + 3.12vw) !important;
}

@media (min-width: 1200px) {
  .fs-77 {
    font-size: 3.85rem !important;
  }
}

.fs-78 {
  font-size: calc(1.515rem + 3.18vw) !important;
}

@media (min-width: 1200px) {
  .fs-78 {
    font-size: 3.9rem !important;
  }
}

.fs-79 {
  font-size: calc(1.52rem + 3.24vw) !important;
}

@media (min-width: 1200px) {
  .fs-79 {
    font-size: 3.95rem !important;
  }
}

.fs-80 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

@media (min-width: 1200px) {
  .fs-80 {
    font-size: 4rem !important;
  }
}

.fs-81 {
  font-size: calc(1.53rem + 3.36vw) !important;
}

@media (min-width: 1200px) {
  .fs-81 {
    font-size: 4.05rem !important;
  }
}

.fs-82 {
  font-size: calc(1.535rem + 3.42vw) !important;
}

@media (min-width: 1200px) {
  .fs-82 {
    font-size: 4.1rem !important;
  }
}

.fs-83 {
  font-size: calc(1.54rem + 3.48vw) !important;
}

@media (min-width: 1200px) {
  .fs-83 {
    font-size: 4.15rem !important;
  }
}

.fs-84 {
  font-size: calc(1.545rem + 3.54vw) !important;
}

@media (min-width: 1200px) {
  .fs-84 {
    font-size: 4.2rem !important;
  }
}

.fs-85 {
  font-size: calc(1.55rem + 3.6vw) !important;
}

@media (min-width: 1200px) {
  .fs-85 {
    font-size: 4.25rem !important;
  }
}

.fs-86 {
  font-size: calc(1.555rem + 3.66vw) !important;
}

@media (min-width: 1200px) {
  .fs-86 {
    font-size: 4.3rem !important;
  }
}

.fs-87 {
  font-size: calc(1.56rem + 3.72vw) !important;
}

@media (min-width: 1200px) {
  .fs-87 {
    font-size: 4.35rem !important;
  }
}

.fs-88 {
  font-size: calc(1.565rem + 3.78vw) !important;
}

@media (min-width: 1200px) {
  .fs-88 {
    font-size: 4.4rem !important;
  }
}

.fs-89 {
  font-size: calc(1.57rem + 3.84vw) !important;
}

@media (min-width: 1200px) {
  .fs-89 {
    font-size: 4.45rem !important;
  }
}

.fs-90 {
  font-size: calc(1.575rem + 3.9vw) !important;
}

@media (min-width: 1200px) {
  .fs-90 {
    font-size: 4.5rem !important;
  }
}

.fs-91 {
  font-size: calc(1.58rem + 3.96vw) !important;
}

@media (min-width: 1200px) {
  .fs-91 {
    font-size: 4.55rem !important;
  }
}

.fs-92 {
  font-size: calc(1.585rem + 4.02vw) !important;
}

@media (min-width: 1200px) {
  .fs-92 {
    font-size: 4.6rem !important;
  }
}

.fs-93 {
  font-size: calc(1.59rem + 4.08vw) !important;
}

@media (min-width: 1200px) {
  .fs-93 {
    font-size: 4.65rem !important;
  }
}

.fs-94 {
  font-size: calc(1.595rem + 4.14vw) !important;
}

@media (min-width: 1200px) {
  .fs-94 {
    font-size: 4.7rem !important;
  }
}

.fs-95 {
  font-size: calc(1.6rem + 4.2vw) !important;
}

@media (min-width: 1200px) {
  .fs-95 {
    font-size: 4.75rem !important;
  }
}

.fs-96 {
  font-size: calc(1.605rem + 4.26vw) !important;
}

@media (min-width: 1200px) {
  .fs-96 {
    font-size: 4.8rem !important;
  }
}

.fs-97 {
  font-size: calc(1.61rem + 4.32vw) !important;
}

@media (min-width: 1200px) {
  .fs-97 {
    font-size: 4.85rem !important;
  }
}

.fs-98 {
  font-size: calc(1.615rem + 4.38vw) !important;
}

@media (min-width: 1200px) {
  .fs-98 {
    font-size: 4.9rem !important;
  }
}

.fs-99 {
  font-size: calc(1.62rem + 4.44vw) !important;
}

@media (min-width: 1200px) {
  .fs-99 {
    font-size: 4.95rem !important;
  }
}

.fs-100 {
  font-size: calc(1.625rem + 4.5vw) !important;
}

@media (min-width: 1200px) {
  .fs-100 {
    font-size: 5rem !important;
  }
}

.fs-101 {
  font-size: calc(1.63rem + 4.56vw) !important;
}

@media (min-width: 1200px) {
  .fs-101 {
    font-size: 5.05rem !important;
  }
}

.fs-102 {
  font-size: calc(1.635rem + 4.62vw) !important;
}

@media (min-width: 1200px) {
  .fs-102 {
    font-size: 5.1rem !important;
  }
}

.fs-103 {
  font-size: calc(1.64rem + 4.68vw) !important;
}

@media (min-width: 1200px) {
  .fs-103 {
    font-size: 5.15rem !important;
  }
}

.fs-104 {
  font-size: calc(1.645rem + 4.74vw) !important;
}

@media (min-width: 1200px) {
  .fs-104 {
    font-size: 5.2rem !important;
  }
}

.fs-105 {
  font-size: calc(1.65rem + 4.8vw) !important;
}

@media (min-width: 1200px) {
  .fs-105 {
    font-size: 5.25rem !important;
  }
}

.fs-106 {
  font-size: calc(1.655rem + 4.86vw) !important;
}

@media (min-width: 1200px) {
  .fs-106 {
    font-size: 5.3rem !important;
  }
}

.fs-107 {
  font-size: calc(1.66rem + 4.92vw) !important;
}

@media (min-width: 1200px) {
  .fs-107 {
    font-size: 5.35rem !important;
  }
}

.fs-108 {
  font-size: calc(1.665rem + 4.98vw) !important;
}

@media (min-width: 1200px) {
  .fs-108 {
    font-size: 5.4rem !important;
  }
}

.fs-109 {
  font-size: calc(1.67rem + 5.04vw) !important;
}

@media (min-width: 1200px) {
  .fs-109 {
    font-size: 5.45rem !important;
  }
}

.fs-110 {
  font-size: calc(1.675rem + 5.1vw) !important;
}

@media (min-width: 1200px) {
  .fs-110 {
    font-size: 5.5rem !important;
  }
}

.fs-111 {
  font-size: calc(1.68rem + 5.16vw) !important;
}

@media (min-width: 1200px) {
  .fs-111 {
    font-size: 5.55rem !important;
  }
}

.fs-112 {
  font-size: calc(1.685rem + 5.22vw) !important;
}

@media (min-width: 1200px) {
  .fs-112 {
    font-size: 5.6rem !important;
  }
}

.fs-113 {
  font-size: calc(1.69rem + 5.28vw) !important;
}

@media (min-width: 1200px) {
  .fs-113 {
    font-size: 5.65rem !important;
  }
}

.fs-114 {
  font-size: calc(1.695rem + 5.34vw) !important;
}

@media (min-width: 1200px) {
  .fs-114 {
    font-size: 5.7rem !important;
  }
}

.fs-115 {
  font-size: calc(1.7rem + 5.4vw) !important;
}

@media (min-width: 1200px) {
  .fs-115 {
    font-size: 5.75rem !important;
  }
}

.fs-116 {
  font-size: calc(1.705rem + 5.46vw) !important;
}

@media (min-width: 1200px) {
  .fs-116 {
    font-size: 5.8rem !important;
  }
}

.fs-117 {
  font-size: calc(1.71rem + 5.52vw) !important;
}

@media (min-width: 1200px) {
  .fs-117 {
    font-size: 5.85rem !important;
  }
}

.fs-118 {
  font-size: calc(1.715rem + 5.58vw) !important;
}

@media (min-width: 1200px) {
  .fs-118 {
    font-size: 5.9rem !important;
  }
}

.fs-119 {
  font-size: calc(1.72rem + 5.64vw) !important;
}

@media (min-width: 1200px) {
  .fs-119 {
    font-size: 5.95rem !important;
  }
}

.fs-120 {
  font-size: calc(1.725rem + 5.7vw) !important;
}

@media (min-width: 1200px) {
  .fs-120 {
    font-size: 6rem !important;
  }
}

.fs-121 {
  font-size: calc(1.73rem + 5.76vw) !important;
}

@media (min-width: 1200px) {
  .fs-121 {
    font-size: 6.05rem !important;
  }
}

.fs-122 {
  font-size: calc(1.735rem + 5.82vw) !important;
}

@media (min-width: 1200px) {
  .fs-122 {
    font-size: 6.1rem !important;
  }
}

.fs-123 {
  font-size: calc(1.74rem + 5.88vw) !important;
}

@media (min-width: 1200px) {
  .fs-123 {
    font-size: 6.15rem !important;
  }
}

.fs-124 {
  font-size: calc(1.745rem + 5.94vw) !important;
}

@media (min-width: 1200px) {
  .fs-124 {
    font-size: 6.2rem !important;
  }
}

.fs-125 {
  font-size: calc(1.75rem + 6vw) !important;
}

@media (min-width: 1200px) {
  .fs-125 {
    font-size: 6.25rem !important;
  }
}

.fs-126 {
  font-size: calc(1.755rem + 6.06vw) !important;
}

@media (min-width: 1200px) {
  .fs-126 {
    font-size: 6.3rem !important;
  }
}

.fs-127 {
  font-size: calc(1.76rem + 6.12vw) !important;
}

@media (min-width: 1200px) {
  .fs-127 {
    font-size: 6.35rem !important;
  }
}

.fs-128 {
  font-size: calc(1.765rem + 6.18vw) !important;
}

@media (min-width: 1200px) {
  .fs-128 {
    font-size: 6.4rem !important;
  }
}

.fs-129 {
  font-size: calc(1.77rem + 6.24vw) !important;
}

@media (min-width: 1200px) {
  .fs-129 {
    font-size: 6.45rem !important;
  }
}

.fs-130 {
  font-size: calc(1.775rem + 6.3vw) !important;
}

@media (min-width: 1200px) {
  .fs-130 {
    font-size: 6.5rem !important;
  }
}

.fs-131 {
  font-size: calc(1.78rem + 6.36vw) !important;
}

@media (min-width: 1200px) {
  .fs-131 {
    font-size: 6.55rem !important;
  }
}

.fs-132 {
  font-size: calc(1.785rem + 6.42vw) !important;
}

@media (min-width: 1200px) {
  .fs-132 {
    font-size: 6.6rem !important;
  }
}

.fs-133 {
  font-size: calc(1.79rem + 6.48vw) !important;
}

@media (min-width: 1200px) {
  .fs-133 {
    font-size: 6.65rem !important;
  }
}

.fs-134 {
  font-size: calc(1.795rem + 6.54vw) !important;
}

@media (min-width: 1200px) {
  .fs-134 {
    font-size: 6.7rem !important;
  }
}

.fs-135 {
  font-size: calc(1.8rem + 6.6vw) !important;
}

@media (min-width: 1200px) {
  .fs-135 {
    font-size: 6.75rem !important;
  }
}

.fs-136 {
  font-size: calc(1.805rem + 6.66vw) !important;
}

@media (min-width: 1200px) {
  .fs-136 {
    font-size: 6.8rem !important;
  }
}

.fs-137 {
  font-size: calc(1.81rem + 6.72vw) !important;
}

@media (min-width: 1200px) {
  .fs-137 {
    font-size: 6.85rem !important;
  }
}

.fs-138 {
  font-size: calc(1.815rem + 6.78vw) !important;
}

@media (min-width: 1200px) {
  .fs-138 {
    font-size: 6.9rem !important;
  }
}

.fs-139 {
  font-size: calc(1.82rem + 6.84vw) !important;
}

@media (min-width: 1200px) {
  .fs-139 {
    font-size: 6.95rem !important;
  }
}

.fs-140 {
  font-size: calc(1.825rem + 6.9vw) !important;
}

@media (min-width: 1200px) {
  .fs-140 {
    font-size: 7rem !important;
  }
}

.fs-141 {
  font-size: calc(1.83rem + 6.96vw) !important;
}

@media (min-width: 1200px) {
  .fs-141 {
    font-size: 7.05rem !important;
  }
}

.fs-142 {
  font-size: calc(1.835rem + 7.02vw) !important;
}

@media (min-width: 1200px) {
  .fs-142 {
    font-size: 7.1rem !important;
  }
}

.fs-143 {
  font-size: calc(1.84rem + 7.08vw) !important;
}

@media (min-width: 1200px) {
  .fs-143 {
    font-size: 7.15rem !important;
  }
}

.fs-144 {
  font-size: calc(1.845rem + 7.14vw) !important;
}

@media (min-width: 1200px) {
  .fs-144 {
    font-size: 7.2rem !important;
  }
}

.fs-145 {
  font-size: calc(1.85rem + 7.2vw) !important;
}

@media (min-width: 1200px) {
  .fs-145 {
    font-size: 7.25rem !important;
  }
}

.fs-146 {
  font-size: calc(1.855rem + 7.26vw) !important;
}

@media (min-width: 1200px) {
  .fs-146 {
    font-size: 7.3rem !important;
  }
}

.fs-147 {
  font-size: calc(1.86rem + 7.32vw) !important;
}

@media (min-width: 1200px) {
  .fs-147 {
    font-size: 7.35rem !important;
  }
}

.fs-148 {
  font-size: calc(1.865rem + 7.38vw) !important;
}

@media (min-width: 1200px) {
  .fs-148 {
    font-size: 7.4rem !important;
  }
}

.fs-149 {
  font-size: calc(1.87rem + 7.44vw) !important;
}

@media (min-width: 1200px) {
  .fs-149 {
    font-size: 7.45rem !important;
  }
}

.fs-150 {
  font-size: calc(1.875rem + 7.5vw) !important;
}

@media (min-width: 1200px) {
  .fs-150 {
    font-size: 7.5rem !important;
  }
}

.fs-151 {
  font-size: calc(1.88rem + 7.56vw) !important;
}

@media (min-width: 1200px) {
  .fs-151 {
    font-size: 7.55rem !important;
  }
}

.fs-152 {
  font-size: calc(1.885rem + 7.62vw) !important;
}

@media (min-width: 1200px) {
  .fs-152 {
    font-size: 7.6rem !important;
  }
}

.fs-153 {
  font-size: calc(1.89rem + 7.68vw) !important;
}

@media (min-width: 1200px) {
  .fs-153 {
    font-size: 7.65rem !important;
  }
}

.fs-154 {
  font-size: calc(1.895rem + 7.74vw) !important;
}

@media (min-width: 1200px) {
  .fs-154 {
    font-size: 7.7rem !important;
  }
}

.fs-155 {
  font-size: calc(1.9rem + 7.8vw) !important;
}

@media (min-width: 1200px) {
  .fs-155 {
    font-size: 7.75rem !important;
  }
}

.fs-156 {
  font-size: calc(1.905rem + 7.86vw) !important;
}

@media (min-width: 1200px) {
  .fs-156 {
    font-size: 7.8rem !important;
  }
}

.fs-157 {
  font-size: calc(1.91rem + 7.92vw) !important;
}

@media (min-width: 1200px) {
  .fs-157 {
    font-size: 7.85rem !important;
  }
}

.fs-158 {
  font-size: calc(1.915rem + 7.98vw) !important;
}

@media (min-width: 1200px) {
  .fs-158 {
    font-size: 7.9rem !important;
  }
}

.fs-159 {
  font-size: calc(1.92rem + 8.04vw) !important;
}

@media (min-width: 1200px) {
  .fs-159 {
    font-size: 7.95rem !important;
  }
}

.fs-160 {
  font-size: calc(1.925rem + 8.1vw) !important;
}

@media (min-width: 1200px) {
  .fs-160 {
    font-size: 8rem !important;
  }
}

.fs-161 {
  font-size: calc(1.93rem + 8.16vw) !important;
}

@media (min-width: 1200px) {
  .fs-161 {
    font-size: 8.05rem !important;
  }
}

.fs-162 {
  font-size: calc(1.935rem + 8.22vw) !important;
}

@media (min-width: 1200px) {
  .fs-162 {
    font-size: 8.1rem !important;
  }
}

.fs-163 {
  font-size: calc(1.94rem + 8.28vw) !important;
}

@media (min-width: 1200px) {
  .fs-163 {
    font-size: 8.15rem !important;
  }
}

.fs-164 {
  font-size: calc(1.945rem + 8.34vw) !important;
}

@media (min-width: 1200px) {
  .fs-164 {
    font-size: 8.2rem !important;
  }
}

.fs-165 {
  font-size: calc(1.95rem + 8.4vw) !important;
}

@media (min-width: 1200px) {
  .fs-165 {
    font-size: 8.25rem !important;
  }
}

.fs-166 {
  font-size: calc(1.955rem + 8.46vw) !important;
}

@media (min-width: 1200px) {
  .fs-166 {
    font-size: 8.3rem !important;
  }
}

.fs-167 {
  font-size: calc(1.96rem + 8.52vw) !important;
}

@media (min-width: 1200px) {
  .fs-167 {
    font-size: 8.35rem !important;
  }
}

.fs-168 {
  font-size: calc(1.965rem + 8.58vw) !important;
}

@media (min-width: 1200px) {
  .fs-168 {
    font-size: 8.4rem !important;
  }
}

.fs-169 {
  font-size: calc(1.97rem + 8.64vw) !important;
}

@media (min-width: 1200px) {
  .fs-169 {
    font-size: 8.45rem !important;
  }
}

.fs-170 {
  font-size: calc(1.975rem + 8.7vw) !important;
}

@media (min-width: 1200px) {
  .fs-170 {
    font-size: 8.5rem !important;
  }
}

.fs-171 {
  font-size: calc(1.98rem + 8.76vw) !important;
}

@media (min-width: 1200px) {
  .fs-171 {
    font-size: 8.55rem !important;
  }
}

.fs-172 {
  font-size: calc(1.985rem + 8.82vw) !important;
}

@media (min-width: 1200px) {
  .fs-172 {
    font-size: 8.6rem !important;
  }
}

.fs-173 {
  font-size: calc(1.99rem + 8.88vw) !important;
}

@media (min-width: 1200px) {
  .fs-173 {
    font-size: 8.65rem !important;
  }
}

.fs-174 {
  font-size: calc(1.995rem + 8.94vw) !important;
}

@media (min-width: 1200px) {
  .fs-174 {
    font-size: 8.7rem !important;
  }
}

.fs-175 {
  font-size: calc(2rem + 9vw) !important;
}

@media (min-width: 1200px) {
  .fs-175 {
    font-size: 8.75rem !important;
  }
}

.fs-176 {
  font-size: calc(2.005rem + 9.06vw) !important;
}

@media (min-width: 1200px) {
  .fs-176 {
    font-size: 8.8rem !important;
  }
}

.fs-177 {
  font-size: calc(2.01rem + 9.12vw) !important;
}

@media (min-width: 1200px) {
  .fs-177 {
    font-size: 8.85rem !important;
  }
}

.fs-178 {
  font-size: calc(2.015rem + 9.18vw) !important;
}

@media (min-width: 1200px) {
  .fs-178 {
    font-size: 8.9rem !important;
  }
}

.fs-179 {
  font-size: calc(2.02rem + 9.24vw) !important;
}

@media (min-width: 1200px) {
  .fs-179 {
    font-size: 8.95rem !important;
  }
}

.fs-180 {
  font-size: calc(2.025rem + 9.3vw) !important;
}

@media (min-width: 1200px) {
  .fs-180 {
    font-size: 9rem !important;
  }
}

.fs-181 {
  font-size: calc(2.03rem + 9.36vw) !important;
}

@media (min-width: 1200px) {
  .fs-181 {
    font-size: 9.05rem !important;
  }
}

.fs-182 {
  font-size: calc(2.035rem + 9.42vw) !important;
}

@media (min-width: 1200px) {
  .fs-182 {
    font-size: 9.1rem !important;
  }
}

.fs-183 {
  font-size: calc(2.04rem + 9.48vw) !important;
}

@media (min-width: 1200px) {
  .fs-183 {
    font-size: 9.15rem !important;
  }
}

.fs-184 {
  font-size: calc(2.045rem + 9.54vw) !important;
}

@media (min-width: 1200px) {
  .fs-184 {
    font-size: 9.2rem !important;
  }
}

.fs-185 {
  font-size: calc(2.05rem + 9.6vw) !important;
}

@media (min-width: 1200px) {
  .fs-185 {
    font-size: 9.25rem !important;
  }
}

.fs-186 {
  font-size: calc(2.055rem + 9.66vw) !important;
}

@media (min-width: 1200px) {
  .fs-186 {
    font-size: 9.3rem !important;
  }
}

.fs-187 {
  font-size: calc(2.06rem + 9.72vw) !important;
}

@media (min-width: 1200px) {
  .fs-187 {
    font-size: 9.35rem !important;
  }
}

.fs-188 {
  font-size: calc(2.065rem + 9.78vw) !important;
}

@media (min-width: 1200px) {
  .fs-188 {
    font-size: 9.4rem !important;
  }
}

.fs-189 {
  font-size: calc(2.07rem + 9.84vw) !important;
}

@media (min-width: 1200px) {
  .fs-189 {
    font-size: 9.45rem !important;
  }
}

.fs-190 {
  font-size: calc(2.075rem + 9.9vw) !important;
}

@media (min-width: 1200px) {
  .fs-190 {
    font-size: 9.5rem !important;
  }
}

.fs-191 {
  font-size: calc(2.08rem + 9.96vw) !important;
}

@media (min-width: 1200px) {
  .fs-191 {
    font-size: 9.55rem !important;
  }
}

.fs-192 {
  font-size: calc(2.085rem + 10.02vw) !important;
}

@media (min-width: 1200px) {
  .fs-192 {
    font-size: 9.6rem !important;
  }
}

.fs-193 {
  font-size: calc(2.09rem + 10.08vw) !important;
}

@media (min-width: 1200px) {
  .fs-193 {
    font-size: 9.65rem !important;
  }
}

.fs-194 {
  font-size: calc(2.095rem + 10.14vw) !important;
}

@media (min-width: 1200px) {
  .fs-194 {
    font-size: 9.7rem !important;
  }
}

.fs-195 {
  font-size: calc(2.1rem + 10.2vw) !important;
}

@media (min-width: 1200px) {
  .fs-195 {
    font-size: 9.75rem !important;
  }
}

.fs-196 {
  font-size: calc(2.105rem + 10.26vw) !important;
}

@media (min-width: 1200px) {
  .fs-196 {
    font-size: 9.8rem !important;
  }
}

.fs-197 {
  font-size: calc(2.11rem + 10.32vw) !important;
}

@media (min-width: 1200px) {
  .fs-197 {
    font-size: 9.85rem !important;
  }
}

.fs-198 {
  font-size: calc(2.115rem + 10.38vw) !important;
}

@media (min-width: 1200px) {
  .fs-198 {
    font-size: 9.9rem !important;
  }
}

.fs-199 {
  font-size: calc(2.12rem + 10.44vw) !important;
}

@media (min-width: 1200px) {
  .fs-199 {
    font-size: 9.95rem !important;
  }
}

.fs-200 {
  font-size: calc(2.125rem + 10.5vw) !important;
}

@media (min-width: 1200px) {
  .fs-200 {
    font-size: 10rem !important;
  }
}

input,
textarea,
select {
  -webkit-appearance: none !important;
}

.form-floating>label {
  color: #959ca9;
  font-size: 0.75rem;
}

.search-form {
  position: relative;
}

.search-form .form-control {
  margin: 0;
  padding-right: 2.25rem !important;
}

.search-form:before {
  content: "\ec92";
  font-family: "Unicons";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
  font-size: 0.9rem;
  color: #959ca9;
  z-index: 1;
}

.form-check-input {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  margin-top: 0.1rem;
}

.form-select {
  background-clip: padding-box;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 0;
}

form.dark-fields .form-control {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.1);
  color: #cacaca;
}

form.dark-fields .form-control:focus {
  border-color: rgba(63, 120, 224, 0.5);
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: -0.01rem;
  color: #60697b;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  transform: translateY(0);
  padding: 0.5rem 1.2rem;
  font-size: 0.8rem;
  border-radius: 0.4rem;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  transform: translateY(-0.15rem);
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
  color: #fff;
}

.btn.btn-white:hover,
.btn[class*=btn-soft-]:hover {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.05);
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
}

.btn-check:checked+.btn,
.btn-check:active+.btn,
.btn:active,
.btn.active {
  transform: translateY(-0.15rem);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn.btn-block {
  padding: 0;
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btn.btn-block:disabled,
.btn.btn-block.disabled,
fieldset:disabled .btn.btn-block {
  opacity: 1;
}

.btn.btn-block.btn-sm,
.btn-group-sm>.btn.btn-block {
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1rem;
}

.btn.btn-block.btn-lg,
.btn-group-lg>.btn.btn-block {
  width: 3rem;
  height: 3rem;
  font-size: calc(1.265rem + 0.18vw);
}

@media (min-width: 1200px) {

  .btn.btn-block.btn-lg,
  .btn-group-lg>.btn.btn-block {
    font-size: 1.4rem;
  }
}

.btn.btn-block.btn-play {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.2rem;
}

.btn.btn-block.btn-play i {
  margin-left: 0.15rem;
}

.btn.btn-circle {
  padding: 0;
  border-radius: 100% !important;
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btn.btn-circle:disabled,
.btn.btn-circle.disabled,
fieldset:disabled .btn.btn-circle {
  opacity: 1;
}

.btn.btn-circle.btn-sm,
.btn-group-sm>.btn.btn-circle {
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.8rem;
}

.btn.btn-circle.btn-sm i,
.btn-group-sm>.btn.btn-circle i {
  font-size: 0.85rem;
}

.btn.btn-circle.btn-lg,
.btn-group-lg>.btn.btn-circle {
  width: 3rem;
  height: 3rem;
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {

  .btn.btn-circle.btn-lg,
  .btn-group-lg>.btn.btn-circle {
    font-size: 1.3rem;
  }
}

.btn.btn-circle.btn-play {
  width: 3.5rem;
  height: 3.5rem;
  font-size: calc(1.355rem + 1.26vw);
}

@media (min-width: 1200px) {
  .btn.btn-circle.btn-play {
    font-size: 2.3rem;
  }
}

.btn.btn-circle.btn-play i {
  margin-left: 0.15rem;
}

.btn.btn-circle.btn-play:hover {
  transform: translateY(0);
}

.btn.btn-circle.counter {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.btn.btn-circle .number {
  display: table-cell;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: 700;
}

.input-group .btn:hover {
  transform: none;
}

.btn-blue {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-blue:hover {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:focus+.btn-blue,
.btn-blue:focus {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:checked+.btn-blue,
.btn-check:active+.btn-blue,
.btn-blue:active,
.btn-blue.active,
.show>.btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3769c4;
}

.btn-blue:disabled:not(.btn-circle):not(.btn-block),
.btn-blue.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-outline-blue {
  color: #3f78e0;
  border-color: #3f78e0;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:focus+.btn-outline-blue,
.btn-outline-blue:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-blue,
.btn-check:active+.btn-outline-blue,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.dropdown-toggle.show {
  color: #3f78e0;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:checked+.btn-outline-blue:focus,
.btn-check:active+.btn-outline-blue:focus,
.btn-outline-blue:active:focus,
.btn-outline-blue.active:focus,
.btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(63, 120, 224, 0.5);
}

.btn-outline-blue:disabled,
.btn-outline-blue.disabled {
  color: #3f78e0;
  background-color: transparent;
}

.btn-purple {
  color: #fff;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-purple:hover {
  color: #fff;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
  color: #fff;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-check:checked+.btn-purple,
.btn-check:active+.btn-purple,
.btn-purple:active,
.btn-purple.active,
.show>.btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #747ed1;
  border-color: #666eb7;
}

.btn-purple:disabled:not(.btn-circle):not(.btn-block),
.btn-purple.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-outline-purple {
  color: #747ed1;
  border-color: #747ed1;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-check:focus+.btn-outline-purple,
.btn-outline-purple:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-purple,
.btn-check:active+.btn-outline-purple,
.btn-outline-purple:active,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show {
  color: #747ed1;
  background-color: #747ed1;
  border-color: #747ed1;
}

.btn-check:checked+.btn-outline-purple:focus,
.btn-check:active+.btn-outline-purple:focus,
.btn-outline-purple:active:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(116, 126, 209, 0.5);
}

.btn-outline-purple:disabled,
.btn-outline-purple.disabled {
  color: #747ed1;
  background-color: transparent;
}

.btn-violet {
  color: #fff;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-violet:hover {
  color: #fff;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-check:focus+.btn-violet,
.btn-violet:focus {
  color: #fff;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-check:checked+.btn-violet,
.btn-check:active+.btn-violet,
.btn-violet:active,
.btn-violet.active,
.show>.btn-violet.dropdown-toggle {
  color: #fff;
  background-color: #a07cc5;
  border-color: #8c6dac;
}

.btn-violet:disabled:not(.btn-circle):not(.btn-block),
.btn-violet.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-outline-violet {
  color: #a07cc5;
  border-color: #a07cc5;
}

.btn-outline-violet:hover {
  color: #fff;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-check:focus+.btn-outline-violet,
.btn-outline-violet:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-violet,
.btn-check:active+.btn-outline-violet,
.btn-outline-violet:active,
.btn-outline-violet.active,
.btn-outline-violet.dropdown-toggle.show {
  color: #a07cc5;
  background-color: #a07cc5;
  border-color: #a07cc5;
}

.btn-check:checked+.btn-outline-violet:focus,
.btn-check:active+.btn-outline-violet:focus,
.btn-outline-violet:active:focus,
.btn-outline-violet.active:focus,
.btn-outline-violet.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(160, 124, 197, 0.5);
}

.btn-outline-violet:disabled,
.btn-outline-violet.disabled {
  color: #a07cc5;
  background-color: transparent;
}

.btn-pink {
  color: #fff;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-pink:hover {
  color: #fff;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-check:focus+.btn-pink,
.btn-pink:focus {
  color: #fff;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-check:checked+.btn-pink,
.btn-check:active+.btn-pink,
.btn-pink:active,
.btn-pink.active,
.show>.btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d16b86;
  border-color: #b75e75;
}

.btn-pink:disabled:not(.btn-circle):not(.btn-block),
.btn-pink.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-outline-pink {
  color: #d16b86;
  border-color: #d16b86;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-check:focus+.btn-outline-pink,
.btn-outline-pink:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-pink,
.btn-check:active+.btn-outline-pink,
.btn-outline-pink:active,
.btn-outline-pink.active,
.btn-outline-pink.dropdown-toggle.show {
  color: #d16b86;
  background-color: #d16b86;
  border-color: #d16b86;
}

.btn-check:checked+.btn-outline-pink:focus,
.btn-check:active+.btn-outline-pink:focus,
.btn-outline-pink:active:focus,
.btn-outline-pink.active:focus,
.btn-outline-pink.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(209, 107, 134, 0.5);
}

.btn-outline-pink:disabled,
.btn-outline-pink.disabled {
  color: #d16b86;
  background-color: transparent;
}

.btn-red {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-red:hover {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:focus+.btn-red,
.btn-red:focus {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:checked+.btn-red,
.btn-check:active+.btn-red,
.btn-red:active,
.btn-red.active,
.show>.btn-red.dropdown-toggle {
  color: #fff;
  background-color: #e2626b;
  border-color: #c6565e;
}

.btn-red:disabled:not(.btn-circle):not(.btn-block),
.btn-red.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-outline-red {
  color: #e2626b;
  border-color: #e2626b;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:focus+.btn-outline-red,
.btn-outline-red:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-red,
.btn-check:active+.btn-outline-red,
.btn-outline-red:active,
.btn-outline-red.active,
.btn-outline-red.dropdown-toggle.show {
  color: #e2626b;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:checked+.btn-outline-red:focus,
.btn-check:active+.btn-outline-red:focus,
.btn-outline-red:active:focus,
.btn-outline-red.active:focus,
.btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(226, 98, 107, 0.5);
}

.btn-outline-red:disabled,
.btn-outline-red.disabled {
  color: #e2626b;
  background-color: transparent;
}

.btn-orange {
  color: #fff;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-orange:hover {
  color: #fff;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-check:focus+.btn-orange,
.btn-orange:focus {
  color: #fff;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-check:checked+.btn-orange,
.btn-check:active+.btn-orange,
.btn-orange:active,
.btn-orange.active,
.show>.btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #f78b77;
  border-color: #d87a68;
}

.btn-orange:disabled:not(.btn-circle):not(.btn-block),
.btn-orange.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-outline-orange {
  color: #f78b77;
  border-color: #f78b77;
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-check:focus+.btn-outline-orange,
.btn-outline-orange:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-orange,
.btn-check:active+.btn-outline-orange,
.btn-outline-orange:active,
.btn-outline-orange.active,
.btn-outline-orange.dropdown-toggle.show {
  color: #f78b77;
  background-color: #f78b77;
  border-color: #f78b77;
}

.btn-check:checked+.btn-outline-orange:focus,
.btn-check:active+.btn-outline-orange:focus,
.btn-outline-orange:active:focus,
.btn-outline-orange.active:focus,
.btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(247, 139, 119, 0.5);
}

.btn-outline-orange:disabled,
.btn-outline-orange.disabled {
  color: #f78b77;
  background-color: transparent;
}

.btn-yellow {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-yellow:hover {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:focus+.btn-yellow,
.btn-yellow:focus {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:checked+.btn-yellow,
.btn-check:active+.btn-yellow,
.btn-yellow:active,
.btn-yellow.active,
.show>.btn-yellow.dropdown-toggle {
  color: #fff;
  background-color: #fab758;
  border-color: #dba04d;
}

.btn-yellow:disabled:not(.btn-circle):not(.btn-block),
.btn-yellow.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-outline-yellow {
  color: #fab758;
  border-color: #fab758;
}

.btn-outline-yellow:hover {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:focus+.btn-outline-yellow,
.btn-outline-yellow:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-yellow,
.btn-check:active+.btn-outline-yellow,
.btn-outline-yellow:active,
.btn-outline-yellow.active,
.btn-outline-yellow.dropdown-toggle.show {
  color: #fab758;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:checked+.btn-outline-yellow:focus,
.btn-check:active+.btn-outline-yellow:focus,
.btn-outline-yellow:active:focus,
.btn-outline-yellow.active:focus,
.btn-outline-yellow.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(250, 183, 88, 0.5);
}

.btn-outline-yellow:disabled,
.btn-outline-yellow.disabled {
  color: #fab758;
  background-color: transparent;
}

.btn-green {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-green:hover {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:focus+.btn-green,
.btn-green:focus {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:checked+.btn-green,
.btn-check:active+.btn-green,
.btn-green:active,
.btn-green.active,
.show>.btn-green.dropdown-toggle {
  color: #fff;
  background-color: #6bbea3;
  border-color: #5ea68f;
}

.btn-green:disabled:not(.btn-circle):not(.btn-block),
.btn-green.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-outline-green {
  color: #6bbea3;
  border-color: #6bbea3;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:focus+.btn-outline-green,
.btn-outline-green:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-green,
.btn-check:active+.btn-outline-green,
.btn-outline-green:active,
.btn-outline-green.active,
.btn-outline-green.dropdown-toggle.show {
  color: #6bbea3;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:checked+.btn-outline-green:focus,
.btn-check:active+.btn-outline-green:focus,
.btn-outline-green:active:focus,
.btn-outline-green.active:focus,
.btn-outline-green.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(107, 190, 163, 0.5);
}

.btn-outline-green:disabled,
.btn-outline-green.disabled {
  color: #6bbea3;
  background-color: transparent;
}

.btn-leaf {
  color: #fff;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-leaf:hover {
  color: #fff;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-check:focus+.btn-leaf,
.btn-leaf:focus {
  color: #fff;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-check:checked+.btn-leaf,
.btn-check:active+.btn-leaf,
.btn-leaf:active,
.btn-leaf.active,
.show>.btn-leaf.dropdown-toggle {
  color: #fff;
  background-color: #7cb798;
  border-color: #6da085;
}

.btn-leaf:disabled:not(.btn-circle):not(.btn-block),
.btn-leaf.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-outline-leaf {
  color: #7cb798;
  border-color: #7cb798;
}

.btn-outline-leaf:hover {
  color: #fff;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-check:focus+.btn-outline-leaf,
.btn-outline-leaf:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-leaf,
.btn-check:active+.btn-outline-leaf,
.btn-outline-leaf:active,
.btn-outline-leaf.active,
.btn-outline-leaf.dropdown-toggle.show {
  color: #7cb798;
  background-color: #7cb798;
  border-color: #7cb798;
}

.btn-check:checked+.btn-outline-leaf:focus,
.btn-check:active+.btn-outline-leaf:focus,
.btn-outline-leaf:active:focus,
.btn-outline-leaf.active:focus,
.btn-outline-leaf.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(124, 183, 152, 0.5);
}

.btn-outline-leaf:disabled,
.btn-outline-leaf.disabled {
  color: #7cb798;
  background-color: transparent;
}

.btn-aqua {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-aqua:hover {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:focus+.btn-aqua,
.btn-aqua:focus {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:checked+.btn-aqua,
.btn-check:active+.btn-aqua,
.btn-aqua:active,
.btn-aqua.active,
.show>.btn-aqua.dropdown-toggle {
  color: #fff;
  background-color: #54a8c7;
  border-color: #4a93ae;
}

.btn-aqua:disabled:not(.btn-circle):not(.btn-block),
.btn-aqua.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-outline-aqua {
  color: #54a8c7;
  border-color: #54a8c7;
}

.btn-outline-aqua:hover {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:focus+.btn-outline-aqua,
.btn-outline-aqua:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-aqua,
.btn-check:active+.btn-outline-aqua,
.btn-outline-aqua:active,
.btn-outline-aqua.active,
.btn-outline-aqua.dropdown-toggle.show {
  color: #54a8c7;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:checked+.btn-outline-aqua:focus,
.btn-check:active+.btn-outline-aqua:focus,
.btn-outline-aqua:active:focus,
.btn-outline-aqua.active:focus,
.btn-outline-aqua.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(84, 168, 199, 0.5);
}

.btn-outline-aqua:disabled,
.btn-outline-aqua.disabled {
  color: #54a8c7;
  background-color: transparent;
}

.btn-navy {
  color: #fff;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-navy:hover {
  color: #fff;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-check:focus+.btn-navy,
.btn-navy:focus {
  color: #fff;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-check:checked+.btn-navy,
.btn-check:active+.btn-navy,
.btn-navy:active,
.btn-navy.active,
.show>.btn-navy.dropdown-toggle {
  color: #fff;
  background-color: #343f52;
  border-color: #2e3748;
}

.btn-navy:disabled:not(.btn-circle):not(.btn-block),
.btn-navy.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-outline-navy {
  color: #343f52;
  border-color: #343f52;
}

.btn-outline-navy:hover {
  color: #fff;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-check:focus+.btn-outline-navy,
.btn-outline-navy:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-navy,
.btn-check:active+.btn-outline-navy,
.btn-outline-navy:active,
.btn-outline-navy.active,
.btn-outline-navy.dropdown-toggle.show {
  color: #343f52;
  background-color: #343f52;
  border-color: #343f52;
}

.btn-check:checked+.btn-outline-navy:focus,
.btn-check:active+.btn-outline-navy:focus,
.btn-outline-navy:active:focus,
.btn-outline-navy.active:focus,
.btn-outline-navy.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(52, 63, 82, 0.5);
}

.btn-outline-navy:disabled,
.btn-outline-navy.disabled {
  color: #343f52;
  background-color: transparent;
}

.btn-ash {
  color: #fff;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-ash:hover {
  color: #fff;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-check:focus+.btn-ash,
.btn-ash:focus {
  color: #fff;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-check:checked+.btn-ash,
.btn-check:active+.btn-ash,
.btn-ash:active,
.btn-ash.active,
.show>.btn-ash.dropdown-toggle {
  color: #fff;
  background-color: #9499a3;
  border-color: #82868f;
}

.btn-ash:disabled:not(.btn-circle):not(.btn-block),
.btn-ash.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-outline-ash {
  color: #9499a3;
  border-color: #9499a3;
}

.btn-outline-ash:hover {
  color: #fff;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-check:focus+.btn-outline-ash,
.btn-outline-ash:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-ash,
.btn-check:active+.btn-outline-ash,
.btn-outline-ash:active,
.btn-outline-ash.active,
.btn-outline-ash.dropdown-toggle.show {
  color: #9499a3;
  background-color: #9499a3;
  border-color: #9499a3;
}

.btn-check:checked+.btn-outline-ash:focus,
.btn-check:active+.btn-outline-ash:focus,
.btn-outline-ash:active:focus,
.btn-outline-ash.active:focus,
.btn-outline-ash.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(148, 153, 163, 0.5);
}

.btn-outline-ash:disabled,
.btn-outline-ash.disabled {
  color: #9499a3;
  background-color: transparent;
}

.btn-white {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus+.btn-white,
.btn-white:focus {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked+.btn-white,
.btn-check:active+.btn-white,
.btn-white:active,
.btn-white.active,
.show>.btn-white.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #dfdfdf;
}

.btn-white:disabled:not(.btn-circle):not(.btn-block),
.btn-white.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus+.btn-outline-white,
.btn-outline-white:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-white,
.btn-check:active+.btn-outline-white,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked+.btn-outline-white:focus,
.btn-check:active+.btn-outline-white:focus,
.btn-outline-white:active:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white:disabled,
.btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-light {
  color: #fff;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-light:hover {
  color: #fff;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #fff;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #fff;
  background-color: #fefefe;
  border-color: #dedede;
}

.btn-light:disabled:not(.btn-circle):not(.btn-block),
.btn-light.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-outline-light {
  color: #fefefe;
  border-color: #fefefe;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #fefefe;
  background-color: #fefefe;
  border-color: #fefefe;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(254, 254, 254, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #fefefe;
  background-color: transparent;
}

.btn-dark {
  color: #fff;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-dark:hover {
  color: #fff;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #262b32;
  border-color: #21262c;
}

.btn-dark:disabled:not(.btn-circle):not(.btn-block),
.btn-dark.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-outline-dark {
  color: #262b32;
  border-color: #262b32;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #262b32;
  background-color: #262b32;
  border-color: #262b32;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(38, 43, 50, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #262b32;
  background-color: transparent;
}

.btn-primary {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-primary:hover {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3769c4;
}

.btn-primary:disabled:not(.btn-circle):not(.btn-block),
.btn-primary.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-outline-primary {
  color: #3f78e0;
  border-color: #3f78e0;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #3f78e0;
  background-color: #3f78e0;
  border-color: #3f78e0;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(63, 120, 224, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #3f78e0;
  background-color: transparent;
}

.btn-secondary {
  color: #fff;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #aab0bc;
  border-color: #959aa5;
}

.btn-secondary:disabled:not(.btn-circle):not(.btn-block),
.btn-secondary.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-outline-secondary {
  color: #aab0bc;
  border-color: #aab0bc;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #aab0bc;
  background-color: #aab0bc;
  border-color: #aab0bc;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(170, 176, 188, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #aab0bc;
  background-color: transparent;
}

.btn-success {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-success:hover {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #6bbea3;
  border-color: #5ea68f;
}

.btn-success:disabled:not(.btn-circle):not(.btn-block),
.btn-success.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-outline-success {
  color: #6bbea3;
  border-color: #6bbea3;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #6bbea3;
  background-color: #6bbea3;
  border-color: #6bbea3;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(107, 190, 163, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #6bbea3;
  background-color: transparent;
}

.btn-info {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-info:hover {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #54a8c7;
  border-color: #4a93ae;
}

.btn-info:disabled:not(.btn-circle):not(.btn-block),
.btn-info.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-outline-info {
  color: #54a8c7;
  border-color: #54a8c7;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #54a8c7;
  background-color: #54a8c7;
  border-color: #54a8c7;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(84, 168, 199, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #54a8c7;
  background-color: transparent;
}

.btn-warning {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-warning:hover {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #fab758;
  border-color: #dba04d;
}

.btn-warning:disabled:not(.btn-circle):not(.btn-block),
.btn-warning.disabled:not(.btn-circle):not(.btn-block) {
  color: #343f52;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-outline-warning {
  color: #fab758;
  border-color: #fab758;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #fab758;
  background-color: #fab758;
  border-color: #fab758;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(250, 183, 88, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fab758;
  background-color: transparent;
}

.btn-danger {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e2626b;
  border-color: #c6565e;
}

.btn-danger:disabled:not(.btn-circle):not(.btn-block),
.btn-danger.disabled:not(.btn-circle):not(.btn-block) {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-outline-danger {
  color: #e2626b;
  border-color: #e2626b;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #e2626b;
  background-color: #e2626b;
  border-color: #e2626b;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15), 0 0 0 0.25rem rgba(226, 98, 107, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #e2626b;
  background-color: transparent;
}

.btn-white,
.btn-white:focus,
.btn-white:not(.btn-play):hover,
.btn-outline-white:hover {
  color: #343f52;
}

.btn-white.btn-play:hover {
  color: #3f78e0;
}

.btn-soft-blue {
  background-color: #e0e9fa;
  color: #3f78e0;
}

.btn-soft-blue:hover {
  color: #3f78e0;
}

.btn-soft-purple {
  background-color: #e9eaf8;
  color: #747ed1;
}

.btn-soft-purple:hover {
  color: #747ed1;
}

.btn-soft-violet {
  background-color: #f0eaf6;
  color: #a07cc5;
}

.btn-soft-violet:hover {
  color: #a07cc5;
}

.btn-soft-pink {
  background-color: #f8e7ec;
  color: #d16b86;
}

.btn-soft-pink:hover {
  color: #d16b86;
}

.btn-soft-red {
  background-color: #fae6e7;
  color: #e2626b;
}

.btn-soft-red:hover {
  color: #e2626b;
}

.btn-soft-orange {
  background-color: #feece9;
  color: #f78b77;
}

.btn-soft-orange:hover {
  color: #f78b77;
}

.btn-soft-yellow {
  background-color: #fef3e4;
  color: #fab758;
}

.btn-soft-yellow:hover {
  color: #fab758;
}

.btn-soft-green {
  background-color: #e7f5f0;
  color: #6bbea3;
}

.btn-soft-green:hover {
  color: #6bbea3;
}

.btn-soft-leaf {
  background-color: #eaf3ef;
  color: #7cb798;
}

.btn-soft-leaf:hover {
  color: #7cb798;
}

.btn-soft-aqua {
  background-color: #e4f1f6;
  color: #54a8c7;
}

.btn-soft-aqua:hover {
  color: #54a8c7;
}

.btn-soft-navy {
  background-color: #dfe0e3;
  color: #343f52;
}

.btn-soft-navy:hover {
  color: #343f52;
}

.btn-soft-ash {
  background-color: #eeeff0;
  color: #9499a3;
}

.btn-soft-ash:hover {
  color: #9499a3;
}

.btn-soft-dark {
  background-color: #dcddde;
  color: #262b32;
}

.btn-soft-dark:hover {
  color: #262b32;
}

.btn-soft-primary {
  background-color: #e0e9fa;
  color: #3f78e0;
}

.btn-soft-primary:hover {
  color: #3f78e0;
}

.btn-soft-ash {
  background-color: rgba(164, 174, 198, 0.2);
  color: #343f52;
}

.btn-soft-ash:hover {
  color: #343f52;
}

.btn-gradient {
  color: #fff;
  border: 0;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.btn-gradient.btn-sm,
.btn-group-sm>.btn-gradient.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-gradient.btn-lg,
.btn-group-lg>.btn-gradient.btn {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.gradient-1 {
  background-image: linear-gradient(120deg, #f857a6 25%, #e9314d 100%);
}

.gradient-1.btn-outline-gradient,
.gradient-1.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(120deg, #f857a6 25%, #e9314d 100%);
}

.gradient-2 {
  background-image: linear-gradient(40deg, #f5b161 0.4%, #ec366e 100.2%);
}

.gradient-2.btn-outline-gradient,
.gradient-2.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(40deg, #f5b161 0.4%, #ec366e 100.2%);
}

.gradient-3 {
  background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.gradient-3.btn-outline-gradient,
.gradient-3.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.gradient-4 {
  background-image: linear-gradient(125deg, #9040db, #ff72c2 50%, #ffd84f);
}

.gradient-4.btn-outline-gradient,
.gradient-4.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(125deg, #9040db, #ff72c2 50%, #ffd84f);
}

.gradient-5 {
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.gradient-5.btn-outline-gradient,
.gradient-5.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.gradient-6 {
  background-image: linear-gradient(45deg, #08AEEA 0%, #2AF598 100%);
}

.gradient-6.btn-outline-gradient,
.gradient-6.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(45deg, #08AEEA 0%, #2AF598 100%);
}

.gradient-7 {
  background-image: linear-gradient(100deg, #0093e9 0, #80d0c7 95%);
}

.gradient-7.btn-outline-gradient,
.gradient-7.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(100deg, #0093e9 0, #80d0c7 95%);
}

.gradient-8 {
  background-image: linear-gradient(45deg, #ffdee9, #b5fffc);
}

.gradient-8.btn-outline-gradient,
.gradient-8.btn-outline-gradient span {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(45deg, #ffdee9, #b5fffc);
}

.gradient-6,
.gradient-7 {
  background-size: 130% auto;
}

.btn-outline-gradient {
  border: 2px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  padding: 0.5rem 1.1rem;
}

.btn-outline-gradient.btn-sm,
.btn-group-sm>.btn-outline-gradient.btn {
  padding: 0.4rem 0.9rem;
}

.btn-outline-gradient.btn-lg,
.btn-group-lg>.btn-outline-gradient.btn {
  padding: 0.65rem 1.3rem;
}

.btn-outline-gradient span {
  color: #343f52;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-outline-gradient:hover span {
  color: #fff;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}

.btn-link {
  font-weight: 500;
  color: #0d6efd;
  text-decoration: none;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.65rem 1.4rem;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.4rem 1rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
}

.w-100.btn-icon {
  position: relative;
}

.w-100.btn-icon.btn-icon-start i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.3rem;
}

.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.btn-icon i {
  vertical-align: -0.1rem;
}

.btn-icon.btn-sm i,
.btn-group-sm>.btn-icon.btn i {
  font-size: 0.8rem;
}

.btn-icon-start i {
  margin-right: 0.1rem;
}

.btn-icon-end i {
  margin-left: 0.1rem;
}

.navigation .btn-icon i {
  margin: 0;
  font-size: 0.9rem;
}

.navigation .btn-icon.btn-sm i,
.navigation .btn-group-sm>.btn-icon.btn i {
  vertical-align: -0.15rem;
}

.navbar .btn-sm,
.navbar .btn-group-sm>.btn {
  font-size: 0.8rem;
}

.social a:not(.btn-circle) {
  font-size: 1rem;
  transition: all 200ms ease-in-out;
  transform: translateY(0);
}

@media (prefers-reduced-motion: reduce) {
  .social a:not(.btn-circle) {
    transition: none;
  }
}

.social a:not(.btn-circle):hover {
  transform: translateY(-0.15rem);
}

.social a:not(.btn-circle) i {
  font-size: 1rem;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=dropbox] {
  color: #007bdf;
}

.social a.btn-circle[class*=dropbox] {
  color: #fff;
  background-color: #007bdf;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=dribbble] {
  color: #d07ead;
}

.social a.btn-circle[class*=dribbble] {
  color: #fff;
  background-color: #d07ead;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google-drive-alt] {
  color: #1b9f5e;
}

.social a.btn-circle[class*=google-drive-alt] {
  color: #fff;
  background-color: #1b9f5e;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=facebook-f] {
  color: #4470cf;
}

.social a.btn-circle[class*=facebook-f] {
  color: #fff;
  background-color: #4470cf;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=instagram] {
  color: #d53581;
}

.social a.btn-circle[class*=instagram] {
  color: #fff;
  background-color: #d53581;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=line] {
  color: #0fab10;
}

.social a.btn-circle[class*=line] {
  color: #fff;
  background-color: #0fab10;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=facebook-messenger] {
  color: #007ff8;
}

.social a.btn-circle[class*=facebook-messenger] {
  color: #fff;
  background-color: #007ff8;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=black-berry] {
  color: #272727;
}

.social a.btn-circle[class*=black-berry] {
  color: #fff;
  background-color: #272727;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=linkedin] {
  color: #3393c1;
}

.social a.btn-circle[class*=linkedin] {
  color: #fff;
  background-color: #3393c1;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=intercom] {
  color: #2e88f8;
}

.social a.btn-circle[class*=intercom] {
  color: #fff;
  background-color: #2e88f8;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=facebook] {
  color: #4470cf;
}

.social a.btn-circle[class*=facebook] {
  color: #fff;
  background-color: #4470cf;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=youtube] {
  color: #c8312b;
}

.social a.btn-circle[class*=youtube] {
  color: #fff;
  background-color: #c8312b;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=apple] {
  color: #1a1919;
}

.social a.btn-circle[class*=apple] {
  color: #fff;
  background-color: #1a1919;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=visual-studio] {
  color: #6b61be;
}

.social a.btn-circle[class*=visual-studio] {
  color: #fff;
  background-color: #6b61be;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=twitter] {
  color: #5daed5;
}

.social a.btn-circle[class*=twitter] {
  color: #fff;
  background-color: #5daed5;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=snapchat-ghost] {
  color: #f7c31a;
}

.social a.btn-circle[class*=snapchat-ghost] {
  color: #fff;
  background-color: #f7c31a;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=slack] {
  color: #d4135c;
}

.social a.btn-circle[class*=slack] {
  color: #fff;
  background-color: #d4135c;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=tumblr-square] {
  color: #5d82a4;
}

.social a.btn-circle[class*=tumblr-square] {
  color: #fff;
  background-color: #5d82a4;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=medium-m] {
  color: #00c364;
}

.social a.btn-circle[class*=medium-m] {
  color: #fff;
  background-color: #00c364;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=tumblr] {
  color: #5d82a4;
}

.social a.btn-circle[class*=tumblr] {
  color: #fff;
  background-color: #5d82a4;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=adobe] {
  color: #e61f26;
}

.social a.btn-circle[class*=adobe] {
  color: #fff;
  background-color: #e61f26;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=whatsapp] {
  color: #00a859;
}

.social a.btn-circle[class*=whatsapp] {
  color: #fff;
  background-color: #00a859;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*="500-px"] {
  color: #00a6df;
}

.social a.btn-circle[class*="500-px"] {
  color: #fff;
  background-color: #00a6df;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=snapchat-square] {
  color: #f7c31a;
}

.social a.btn-circle[class*=snapchat-square] {
  color: #fff;
  background-color: #f7c31a;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=behance] {
  color: #098ffa;
}

.social a.btn-circle[class*=behance] {
  color: #fff;
  background-color: #098ffa;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=instagram-alt] {
  color: #d53581;
}

.social a.btn-circle[class*=instagram-alt] {
  color: #fff;
  background-color: #d53581;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=github-alt] {
  color: #4073a6;
}

.social a.btn-circle[class*=github-alt] {
  color: #fff;
  background-color: #4073a6;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=linkedin-alt] {
  color: #3393c1;
}

.social a.btn-circle[class*=linkedin-alt] {
  color: #fff;
  background-color: #3393c1;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=slack-alt] {
  color: #d4135c;
}

.social a.btn-circle[class*=slack-alt] {
  color: #fff;
  background-color: #d4135c;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google] {
  color: #e44134;
}

.social a.btn-circle[class*=google] {
  color: #fff;
  background-color: #e44134;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google-play] {
  color: #e44134;
}

.social a.btn-circle[class*=google-play] {
  color: #fff;
  background-color: #e44134;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google-drive] {
  color: #1b9f5e;
}

.social a.btn-circle[class*=google-drive] {
  color: #fff;
  background-color: #1b9f5e;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=vuejs-alt] {
  color: #3fb37f;
}

.social a.btn-circle[class*=vuejs-alt] {
  color: #fff;
  background-color: #3fb37f;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google-hangouts] {
  color: #109956;
}

.social a.btn-circle[class*=google-hangouts] {
  color: #fff;
  background-color: #109956;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=vuejs] {
  color: #3fb37f;
}

.social a.btn-circle[class*=vuejs] {
  color: #fff;
  background-color: #3fb37f;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=java-script] {
  color: #f0d91d;
}

.social a.btn-circle[class*=java-script] {
  color: #fff;
  background-color: #f0d91d;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=paypal] {
  color: #0089cc;
}

.social a.btn-circle[class*=paypal] {
  color: #fff;
  background-color: #0089cc;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=reddit-alien-alt] {
  color: #f84300;
}

.social a.btn-circle[class*=reddit-alien-alt] {
  color: #fff;
  background-color: #f84300;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=android-alt] {
  color: #97c03d;
}

.social a.btn-circle[class*=android-alt] {
  color: #fff;
  background-color: #97c03d;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=github] {
  color: #4073a6;
}

.social a.btn-circle[class*=github] {
  color: #fff;
  background-color: #4073a6;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=android] {
  color: #97c03d;
}

.social a.btn-circle[class*=android] {
  color: #fff;
  background-color: #97c03d;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=amazon] {
  color: #f79400;
}

.social a.btn-circle[class*=amazon] {
  color: #fff;
  background-color: #f79400;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=adobe-alt] {
  color: #e61f26;
}

.social a.btn-circle[class*=adobe-alt] {
  color: #fff;
  background-color: #e61f26;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=master-card] {
  color: #f0931d;
}

.social a.btn-circle[class*=master-card] {
  color: #fff;
  background-color: #f0931d;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=opera-alt] {
  color: #e33737;
}

.social a.btn-circle[class*=opera-alt] {
  color: #fff;
  background-color: #e33737;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=css3-alt] {
  color: #006db6;
}

.social a.btn-circle[class*=css3-alt] {
  color: #fff;
  background-color: #006db6;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=opera] {
  color: #e33737;
}

.social a.btn-circle[class*=opera] {
  color: #fff;
  background-color: #e33737;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=css3] {
  color: #006db6;
}

.social a.btn-circle[class*=css3] {
  color: #fff;
  background-color: #006db6;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=twitter-alt] {
  color: #5daed5;
}

.social a.btn-circle[class*=twitter-alt] {
  color: #fff;
  background-color: #5daed5;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=intercom-alt] {
  color: #2e88f8;
}

.social a.btn-circle[class*=intercom-alt] {
  color: #fff;
  background-color: #2e88f8;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=swiggy] {
  color: #f88a2d;
}

.social a.btn-circle[class*=swiggy] {
  color: #fff;
  background-color: #f88a2d;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=snapchat-alt] {
  color: #f7c31a;
}

.social a.btn-circle[class*=snapchat-alt] {
  color: #fff;
  background-color: #f7c31a;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=tumblr-alt] {
  color: #5d82a4;
}

.social a.btn-circle[class*=tumblr-alt] {
  color: #fff;
  background-color: #5d82a4;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=facebook-messenger-alt] {
  color: #007ff8;
}

.social a.btn-circle[class*=facebook-messenger-alt] {
  color: #fff;
  background-color: #007ff8;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=bitcoin-alt] {
  color: #f38633;
}

.social a.btn-circle[class*=bitcoin-alt] {
  color: #fff;
  background-color: #f38633;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=google-hangouts-alt] {
  color: #109956;
}

.social a.btn-circle[class*=google-hangouts-alt] {
  color: #fff;
  background-color: #109956;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=blogger] {
  color: #e86733;
}

.social a.btn-circle[class*=blogger] {
  color: #fff;
  background-color: #e86733;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=blogger-alt] {
  color: #e86733;
}

.social a.btn-circle[class*=blogger-alt] {
  color: #fff;
  background-color: #e86733;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=bitcoin] {
  color: #f38633;
}

.social a.btn-circle[class*=bitcoin] {
  color: #fff;
  background-color: #f38633;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=behance-alt] {
  color: #098ffa;
}

.social a.btn-circle[class*=behance-alt] {
  color: #fff;
  background-color: #098ffa;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=apple-alt] {
  color: #1a1919;
}

.social a.btn-circle[class*=apple-alt] {
  color: #fff;
  background-color: #1a1919;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=react] {
  color: #00d1f7;
}

.social a.btn-circle[class*=react] {
  color: #fff;
  background-color: #00d1f7;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=telegram] {
  color: #2caef0;
}

.social a.btn-circle[class*=telegram] {
  color: #fff;
  background-color: #2caef0;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=vk-alt] {
  color: #4f7db3;
}

.social a.btn-circle[class*=vk-alt] {
  color: #fff;
  background-color: #4f7db3;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=vk] {
  color: #4f7db3;
}

.social a.btn-circle[class*=vk] {
  color: #fff;
  background-color: #4f7db3;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=skype] {
  color: #2ebbf0;
}

.social a.btn-circle[class*=skype] {
  color: #fff;
  background-color: #2ebbf0;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=skype-alt] {
  color: #2ebbf0;
}

.social a.btn-circle[class*=skype-alt] {
  color: #fff;
  background-color: #2ebbf0;
}

.social a:not([class*=link-]):not(.btn-circle) .uil[class*=telegram-alt] {
  color: #2caef0;
}

.social a.btn-circle[class*=telegram-alt] {
  color: #fff;
  background-color: #2caef0;
}

.social.social-muted a:not(.btn-circle):not([class*=link-]) i {
  color: #343f52 !important;
}

.social.social-white a i,
.social.social-white a:hover i {
  color: #fff !important;
}

.social>a {
  margin: 0 0.7rem 0 0;
}

[class*=text-end] .social>a,
.social[class*=text-end]>a {
  margin: 0 0 0 0.7rem;
}

@media (min-width: 768px) {

  [class*=text-md-end] .social>a,
  .social[class*=text-md-end]>a {
    margin: 0 0 0 0.7rem;
  }
}

.text-center .social>a,
.social.text-center>a {
  margin: 0 0.35rem;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: -0.01rem;
  border-radius: 100%;
  height: auto;
}

@-webkit-keyframes show-animation {
  from {
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes show-animation {
  from {
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes hide-animation {
  0% {
    transform: translate3d(1, 1, 1);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 1);
    opacity: 0;
  }
}

@keyframes hide-animation {
  0% {
    transform: translate3d(1, 1, 1);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 1);
    opacity: 0;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes priceFade {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(80%);
  }
}

@keyframes priceFade {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(80%);
  }
}

@-webkit-keyframes priceShow {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes priceShow {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}

@-webkit-keyframes loader-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.hero-slider:before,
.lg-outer .lg-item:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  background: none;
  border: 4px solid #3f78e0;
  box-shadow: none;
  border-radius: 50%;
  -webkit-animation: loader-scale 1s ease-out infinite;
  animation: loader-scale 1s ease-out infinite;
}

.hero-slider-wrapper.bg-dark .hero-slider:before,
.lg-outer .lg-item:after {
  border-color: #fff;
}

.dropdown-menu {
  border: 0;
}

.dropdown-item {
  font-weight: 700;
  letter-spacing: -0.01rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
  outline: 0;
}

.dropdown-header {
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}

.center-nav.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 0.75rem !important;
}

.center-nav[class*=navbar-bg-] .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 1.25rem !important;
}

.navbar.transparent .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 1.2rem !important;
}

.navbar.transparent.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu,
.navbar[class*=navbar-bg-] .navbar-nav>.nav-item.language-select>.dropdown-menu {
  margin-top: 2rem !important;
}

@media (min-width: 992px) {

  .navbar:not(.transparent) .navbar-nav>.nav-item:not(.language-select)>.dropdown-menu,
  .navbar.fixed .navbar-nav>.nav-item>.dropdown-menu {
    margin-top: 0.75rem !important;
  }

  .navbar.extended:not(.fixed) .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 0.95rem !important;
  }

  .navbar[class*=navbar-bg-]:not(.fancy):not(.extended):not(.fixed) .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 2.25rem !important;
  }

  .navbar.fixed .navbar-nav>.nav-item.language-select>.dropdown-menu {
    margin-top: 1.95rem !important;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-collapse .dropdown-menu {
    padding: 0;
    margin-top: 0;
    background: none;
  }

  .navbar .navbar-collapse .dropdown-menu .dropdown-item {
    color: #fff;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
  }
}

.language-select .dropdown-menu {
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 100% !important;
  margin-top: 0;
  padding: 0.65rem 0;
  position: absolute;
}

.navbar-nav .sub-arrow {
  font-family: "Custom";
}

.navbar-nav.sm-collapsible .sub-arrow {
  top: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0 2.2rem 0 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.navbar-nav.sm-collapsible .sub-arrow::before,
.navbar-nav.sm-collapsible .show>a>.sub-arrow::before {
  content: "\e900";
  display: table-cell;
  vertical-align: middle;
}

.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
  margin: 0 0 0 1rem;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: inline-block;
  width: auto;
  height: 1em;
  margin-right: 0.25rem;
  margin-bottom: -0.15rem;
  vertical-align: -2px;
  border: 0 !important;
  font-size: 0.75rem;
  color: #3f78e0;
}

.navbar.caret-none .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}

.navbar-dark .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: #fff;
}

.navbar-dark.transparent.banner--stick .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: #3f78e0;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow+.sub-arrow {
  display: none !important;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid;
  border-radius: 2px;
  transform: translateY(3px);
}

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  font-size: 0.8rem;
  top: 0.4rem;
  right: 1.8rem;
  margin: 0;
  border: 0;
}

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow:before,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  position: relative;
  content: "\e902";
}

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
  padding-right: 2.5rem;
}

.dropdown-toggle::after {
  font-family: "Custom";
  border: 0;
  font-size: 0.8rem;
  margin-left: 0;
  margin-right: 0rem;
  vertical-align: -1px;
  content: "\e900";
  color: #3f78e0;
}

.dropdown.nav-item .dropdown-toggle::after {
  content: "";
}

@media (max-width: 991.98px) {
  .dropdown-toggle::after {
    vertical-align: 0;
  }
}

.navbar-dark:not(.fixed) .dropdown-toggle:after,
.navbar-bg-dark .dropdown-toggle:after,
.btn.dropdown-toggle:after {
  color: #fff;
}

.share-dropdown .dropdown-menu {
  min-width: 6.25rem;
  margin-top: 1rem !important;
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
}

.share-dropdown .dropdown-menu[data-popper-placement^=top] {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.share-dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.15rem;
  font-size: 0.7rem;
}

.share-dropdown .dropdown-menu .dropdown-item i {
  padding-right: 0.4rem;
  vertical-align: -0.1rem;
  width: 1rem;
  font-size: 0.8rem;
}

.share-dropdown .dropdown-toggle::after {
  display: none;
}

.navbar-nav,
.navbar-nav>li.mega-menu {
  position: static !important;
}

.navbar-nav>li>ul.mega-menu {
  right: 0 !important;
  width: auto !important;
  max-width: none !important;
}

@media (min-width: 992px) {
  .navbar .navbar-nav ul.show-animation {
    -webkit-animation: show-animation 0.25s;
    animation: show-animation 0.25s;
  }

  .navbar .navbar-nav ul.hide-animation {
    -webkit-animation: hide-animation 0.25s;
    animation: hide-animation 0.25s;
  }

  .navbar-nav>li>ul.mega-menu {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .mega-menu-content>.row>[class*=col-]+[class*=col-] {
    border-left: 1px solid rgba(164, 174, 198, 0.2);
  }

  .navbar-nav>li>ul.mega-menu .mega-menu-content {
    padding: 0.6rem 0.25rem 0.2rem;
  }

  .mega-menu-dark {
    background: #343f52;
  }

  .mega-menu-dark .img-svg {
    background: rgba(255, 255, 255, 0.05);
    transition: all 200ms ease-in-out;
  }

  .mega-menu-dark .img-svg:hover {
    background: rgba(255, 255, 255, 0.09);
  }

  .mega-menu-dark .dropdown-item {
    color: #fff;
  }

  .mega-menu-dark .dropdown-item:hover,
  .mega-menu-dark .dropdown-item:focus {
    color: #fff;
  }

  .mega-menu-img .mega-menu-content {
    padding: 1rem 2rem !important;
  }

  .mega-menu-img .dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.nav-link {
  letter-spacing: -0.01rem;
}

.sidebar .nav-link {
  letter-spacing: normal;
}

.navbar-other .nav-link {
  cursor: pointer;
}

@media (max-width: 991.98px) {

  .navbar .navbar-collapse .show>.nav-link,
  .navbar .navbar-collapse .nav-link.active,
  .navbar .navbar-collapse .nav-link:hover,
  .navbar .navbar-collapse .nav-link:focus {
    color: #fff !important;
  }
}

.nav-tabs .nav-link {
  background: #fff;
  font-size: 0.85rem;
  padding: 0.6rem 1.3rem;
}

.nav-tabs .nav-link i {
  font-size: 1rem;
}

.nav-tabs.nav-pills {
  border-color: transparent;
}

.nav-tabs.nav-pills .nav-link {
  border-color: transparent;
  box-shadow: 0 0 0 0.05rem rgba(8, 60, 130, 0.06), 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  .nav-tabs.d-flex:not(.nav-pills) {
    justify-content: flex-start !important;
  }
}

@media (max-width: 767px) {
  .nav-tabs.nav-pills {
    flex-direction: column;
  }

  .nav-tabs.nav-pills .nav-item {
    margin-bottom: 1rem;
  }

  .nav-tabs.nav-pills .nav-link {
    margin-right: 0;
  }
}

.nav-tabs.nav-tabs-bg {
  border-color: transparent;
}

.nav-tabs.nav-tabs-bg .nav-link {
  padding: 1.4rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  line-height: 1.25rem;
  box-shadow: none;
  background: transparent;
}

.nav-tabs.nav-tabs-bg .nav-link:first-child {
  margin-left: 0;
}

.nav-tabs.nav-tabs-bg .nav-link:last-child {
  margin-right: 0;
}

.nav-tabs.nav-tabs-bg .nav-link:hover,
.nav-tabs.nav-tabs-bg .nav-link.active {
  color: #343f52;
  border-color: transparent;
  background: #fff;
  box-shadow: 0 0 0 0.05rem rgba(8, 60, 130, 0.06), 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.nav-tabs.nav-tabs-bg .nav-link p {
  margin: 0;
  font-weight: 500;
  color: #60697b;
}

.nav-tabs.nav-tabs-bg.nav-tabs-shadow-lg .nav-link:hover,
.nav-tabs.nav-tabs-bg.nav-tabs-shadow-lg .nav-link.active {
  box-shadow: 0rem 0.25rem 1.75rem rgba(30, 34, 40, 0.07);
}

.nav-tabs.flex-column.nav-tabs-bg .nav-item {
  margin-bottom: 1rem;
}

.nav-tabs.nav-justified .nav-item {
  text-align: left;
}

@media (min-width: 992px) {
  .nav-tabs.nav-justified .nav-item {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .nav-tabs.nav-justified .nav-item:first-child {
    margin-left: 0;
  }

  .nav-tabs.nav-justified .nav-item:last-child {
    margin-right: 0;
  }
}

.tab-content {
  margin-top: 1.25rem;
}

.navbar {
  width: 100%;
  z-index: 1020;
}

.navbar.transparent .logo-dark {
  display: none;
}

.navbar.fixed.transparent .logo-light {
  display: none;
}

.navbar.fixed.transparent .logo-dark {
  display: inline-block;
}

.navbar .container {
  position: relative;
}

.navbar-other .nav-item .nav-link>i {
  font-size: 1.1rem;
}

.navbar .navbar-other .navbar-nav>.nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar .navbar-other .navbar-nav>.nav-item+.nav-item {
  margin-left: 0.8rem;
}

.navbar-bg-light {
  background: #fff;
  box-shadow: none;
}

@media (min-width: 992px) {

  .navbar.fancy.navbar-bg-light:not(.fixed),
  .navbar.extended.navbar-bg-light:not(.fixed) {
    background: none !important;
  }

  .navbar .nav-link {
    white-space: nowrap;
  }
}

.navbar-bg-dark {
  background: #2f353a;
}

.navbar-dark .logo-dark {
  display: none;
}

.navbar-dark.fixed .logo-light {
  display: none;
}

.navbar-dark.fixed .logo-dark {
  display: inline-block;
}

.navbar-dark:not([class*=navbar-bg-]).fixed .btn {
  background: #3f78e0;
  border-color: #3f78e0;
  color: #fff;
}

.navbar .navbar-other .nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar .navbar-collapse .nav-link {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
  }
}

@media (min-width: 992px) {
  .navbar.extended:not(.fixed) .navbar-collapse .nav-link {
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
  }

  .navbar.fancy:not(.fixed) .navbar-collapse .nav-link {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar[class*=navbar-bg-]:not(.fancy):not(.extended):not(.fixed) .navbar-collapse .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .navbar.transparent:not(.fixed) {
    padding-top: 0.3rem;
  }

  .navbar.extended .navbar-collapse-wrapper,
  .navbar.fancy .navbar-collapse-wrapper {
    box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.02);
    width: 100%;
    border-radius: 0.4rem;
  }

  .navbar.extended:not(.fixed) .navbar-collapse-wrapper {
    padding: 0 1.5rem 0 0.5rem;
  }

  .navbar.fancy:not(.fixed) .navbar-collapse-wrapper {
    padding: 0 1.5rem 0 1.5rem;
    margin-top: 2.25rem;
  }

  .navbar.extended .navbar-brand {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .navbar.fixed .topbar {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-collapse .nav-link {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    color: #fff;
  }

  .navbar-other .nav-item.language-select .nav-link {
    font-size: 1.05rem;
  }

  .navbar-other .nav-item .nav-link>i {
    font-size: calc(1.255rem + 0.06vw);
  }
}

@media (max-width: 991.98px) and (min-width: 1200px) {
  .navbar-other .nav-item .nav-link>i {
    font-size: 1.3rem;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-brand {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

@media (max-width: 991.98px) {
  .navbar.fancy .navbar-collapse-wrapper {
    background: none !important;
    box-shadow: none !important;
  }
}

.navbar.fixed {
  margin: 0;
  width: 100%;
}

.banner--clone {
  position: fixed !important;
  z-index: 1008;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 300ms ease-in-out, border 1ms linear, padding-right 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .banner--clone {
    transition: none;
  }
}

.banner--stick {
  transform: translateY(0%);
  border-bottom: 0;
}

.banner--stick:not(.navbar-dark),
.banner--stick.transparent {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  background: rgba(255, 255, 255, 0.97);
}

.banner--stick:not(.navbar-dark).navbar-dark .nav-link,
.banner--stick.transparent.navbar-dark .nav-link {
  color: #343f52;
}

.banner--stick:not(.navbar-dark).navbar-dark .nav-link:hover,
.banner--stick:not(.navbar-dark).navbar-dark .nav-link:focus,
.banner--stick.transparent.navbar-dark .nav-link:hover,
.banner--stick.transparent.navbar-dark .nav-link:focus {
  color: #3f78e0;
}

.banner--stick:not(.navbar-dark).navbar-dark .nav-link.disabled,
.banner--stick.transparent.navbar-dark .nav-link.disabled {
  color: rgba(52, 63, 82, 0.3);
}

.navbar.fixed:not(.extended) .topbar {
  display: none !important;
}

.navbar.fixed .navbar-collapse-wrapper {
  background: none !important;
  box-shadow: none !important;
}

.offcanvas-info {
  position: fixed;
  top: 0;
  bottom: 0;
  left: auto;
  right: -15rem;
  width: 15rem;
  padding-top: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  overflow-y: auto;
  visibility: hidden;
  background: #1e2228;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: right, visibility;
  z-index: 1041;
  align-items: start;
  background-clip: padding-box;
}

.offcanvas-info.open {
  right: 0;
  visibility: visible;
}

.offcanvas-close {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  cursor: pointer;
}

.offcanvas-nav-close {
  left: auto;
  right: 0.5rem;
}

.offcanvas-info .widget {
  margin-bottom: 2rem;
}

@media (max-width: 991.98px) {
  .offcanvas-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    left: -15rem;
    width: 15rem;
    padding-right: 0;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    overflow-y: auto;
    visibility: hidden;
    background: #1e2228;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
    transition: left 0.3s ease-in-out, visibility 0.3s ease-in-out;
    align-items: start;
    background-clip: padding-box;
    z-index: 1041;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-nav {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-nav.open {
    left: 0;
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-header {
    padding: 3rem 0 2rem;
  }

  .offcanvas-header img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 991.98px) {
  .navbar.fixed .offcanvas-nav.open {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}

button.plain {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  vertical-align: middle;
  border: 0 none;
  background: none;
  padding: 0;
  margin: 0;
}

@media (prefers-reduced-motion: reduce) {
  button.plain {
    transition: none;
  }
}

button.plain::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

button.plain:focus {
  outline: 0;
}

button.hamburger {
  display: inline-block;
  width: 24px;
  height: 22px;
  padding: 0;
  margin-left: 4px;
}

button.hamburger:before,
button.hamburger:after {
  content: "";
}

button.hamburger:before,
button.hamburger span,
button.hamburger:after {
  display: block;
  width: 100%;
  height: 3px;
  margin: 0 0 5px;
  transition: all 200ms ease-in-out;
  background: #343f52;
}

@media (prefers-reduced-motion: reduce) {

  button.hamburger:before,
  button.hamburger span,
  button.hamburger:after {
    transition: none;
  }
}

.inverse-text:not(.fixed) button.hamburger:before,
.inverse-text:not(.fixed) button.hamburger span,
.inverse-text:not(.fixed) button.hamburger:after,
.navbar.position-absolute:not(.fixed) button.hamburger:before,
.navbar.position-absolute:not(.fixed) button.hamburger span,
.navbar.position-absolute:not(.fixed) button.hamburger:after,
.navbar.navbar-bg-dark button.hamburger:before,
.navbar.navbar-bg-dark button.hamburger span,
.navbar.navbar-bg-dark button.hamburger:after,
.navbar.transparent.navbar-dark:not(.fixed) button.hamburger:before,
.navbar.transparent.navbar-dark:not(.fixed) button.hamburger span,
.navbar.transparent.navbar-dark:not(.fixed) button.hamburger:after {
  background: #fff;
}

button.hamburger.animate.active span {
  transform: scale(0);
}

button.hamburger.animate.active:before {
  transform: translateY(8px) rotate(45deg);
}

button.hamburger.animate.active:after {
  transform: translateY(-8px) rotate(-45deg);
}

.card {
  box-shadow: 0 0 0 0.05rem rgba(8, 60, 130, 0.06), 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  border: 0;
}

.card[class*=bg-] {
  box-shadow: none !important;
}

.card-img-top img {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.card-img-top .plyr {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 991.98px) {
  .card-md-none {
    background: none;
    border-radius: 0;
  }

  .card-md-none .card-body {
    padding: 0;
  }
}

.accordion-wrapper .card {
  margin-bottom: 1.25rem;
}

.accordion-wrapper .card.plain {
  background: none;
  border: 0;
  margin-bottom: 0;
  box-shadow: none;
}

.accordion-wrapper .card.plain .card-body {
  padding: 0 0 0 1.1rem;
}

.accordion-wrapper .card.plain .card-header {
  padding: 0 0 0.8rem 0;
}

.accordion-wrapper .card.plain .card-header button {
  padding: 0 0 0 1.1rem;
}

.accordion-wrapper .card.plain .card-header button:before {
  left: 0;
}

.accordion-wrapper .card-header {
  margin-bottom: 0;
  background: none;
  border: 0;
  padding: 0.9rem 1.3rem 0.85rem;
}

.accordion-wrapper .card-header button {
  cursor: pointer;
  width: 100%;
  padding: 0 0 0 1.2rem;
  text-align: left;
  margin: 0;
  border: 0;
  font-size: 0.85rem;
  font-weight: 700;
  color: #3f78e0;
  transition: all 150ms ease-in-out;
  background: none;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-wrapper .card-header button {
    transition: none;
  }
}

.accordion-wrapper .card-header button:before {
  font-family: "Custom";
  position: absolute;
  left: 1.3rem;
  content: "\e903";
  font-size: 1rem;
  font-weight: normal;
  width: 1rem;
  margin-top: -0.2rem;
  margin-left: -0.3rem;
  display: inline-block;
  color: #3f78e0;
}

.accordion-wrapper .card-header button.collapsed {
  color: #343f52;
}

.accordion-wrapper .card-header button.collapsed:before {
  content: "\e900";
}

.accordion-wrapper .card-header button:hover {
  color: #3f78e0;
}

.accordion-wrapper .card-body {
  padding: 0 1.25rem 0.25rem 2.5rem;
}

.collapse-link {
  font-size: 0.85rem;
  font-weight: 700;
  color: #3f78e0;
}

.collapse-link:before {
  font-family: "Custom";
  content: "\e903";
  font-size: 1rem;
  font-weight: normal;
  width: 1rem;
  margin-left: -0.3rem;
  margin-right: 0.4rem;
  display: inline-block;
  vertical-align: -2px;
}

.collapse-link.collapsed {
  color: #343f52;
}

.collapse-link.collapsed:before {
  content: "\e900";
}

.collapse-link:hover {
  color: #3f78e0;
}

.accordion-collapse>.card-footer {
  background: #273444;
  padding: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: none;
  border-radius: 0;
}

.breadcrumb-item {
  display: flex;
  color: #60697b;
}

.breadcrumb-item a {
  color: inherit;
}

.breadcrumb-item a:hover {
  color: #3f78e0;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.6rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: "Custom";
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  color: rgba(96, 105, 123, 0.3);
  content: "\e902";
  font-size: 0.35rem;
  padding-bottom: 0.05rem;
}

.breadcrumb.text-white .breadcrumb-item:hover,
.breadcrumb.text-white .breadcrumb-item.active {
  color: #fff;
}

.breadcrumb.text-white .breadcrumb-item a {
  color: #fff;
}

.breadcrumb.text-white .breadcrumb-item a:hover {
  border-color: #fff;
}

.breadcrumb.text-white .breadcrumb-item+.breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.pagination {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
}

.page-link {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.65rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link i {
  font-size: 0.9rem;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.15rem 1rem;
}

.alert-dismissible.alert-blue .btn-close {
  color: #396cca;
}

.alert-dismissible.alert-blue .btn-close:hover {
  color: #264886;
}

.alert-dismissible.alert-purple .btn-close {
  color: #6871bc;
}

.alert-dismissible.alert-purple .btn-close:hover {
  color: #464c7d;
}

.alert-dismissible.alert-violet .btn-close {
  color: #9070b1;
}

.alert-dismissible.alert-violet .btn-close:hover {
  color: #604a76;
}

.alert-dismissible.alert-pink .btn-close {
  color: #bc6079;
}

.alert-dismissible.alert-pink .btn-close:hover {
  color: #7d4050;
}

.alert-dismissible.alert-red .btn-close {
  color: #cb5860;
}

.alert-dismissible.alert-red .btn-close:hover {
  color: #883b40;
}

.alert-dismissible.alert-orange .btn-close {
  color: #de7d6b;
}

.alert-dismissible.alert-orange .btn-close:hover {
  color: #945347;
}

.alert-dismissible.alert-yellow .btn-close {
  color: #e1a54f;
}

.alert-dismissible.alert-yellow .btn-close:hover {
  color: #966e35;
}

.alert-dismissible.alert-green .btn-close {
  color: #60ab93;
}

.alert-dismissible.alert-green .btn-close:hover {
  color: #407262;
}

.alert-dismissible.alert-leaf .btn-close {
  color: #70a589;
}

.alert-dismissible.alert-leaf .btn-close:hover {
  color: #4a6e5b;
}

.alert-dismissible.alert-aqua .btn-close {
  color: #4c97b3;
}

.alert-dismissible.alert-aqua .btn-close:hover {
  color: #326577;
}

.alert-dismissible.alert-navy .btn-close {
  color: #2f394a;
}

.alert-dismissible.alert-navy .btn-close:hover {
  color: #1f2631;
}

.alert-dismissible.alert-ash .btn-close {
  color: #858a93;
}

.alert-dismissible.alert-ash .btn-close:hover {
  color: #595c62;
}

.alert-dismissible.alert-white .btn-close {
  color: #e6e6e6;
}

.alert-dismissible.alert-white .btn-close:hover {
  color: #999999;
}

.alert-dismissible.alert-light .btn-close {
  color: #e5e5e5;
}

.alert-dismissible.alert-light .btn-close:hover {
  color: #989898;
}

.alert-dismissible.alert-gray .btn-close {
  color: #dddee0;
}

.alert-dismissible.alert-gray .btn-close:hover {
  color: #949495;
}

.alert-dismissible.alert-dark .btn-close {
  color: #22272d;
}

.alert-dismissible.alert-dark .btn-close:hover {
  color: #171a1e;
}

.alert-dismissible.alert-primary .btn-close {
  color: #396cca;
}

.alert-dismissible.alert-primary .btn-close:hover {
  color: #264886;
}

.alert-dismissible.alert-secondary .btn-close {
  color: #999ea9;
}

.alert-dismissible.alert-secondary .btn-close:hover {
  color: #666a71;
}

.alert-dismissible.alert-success .btn-close {
  color: #60ab93;
}

.alert-dismissible.alert-success .btn-close:hover {
  color: #407262;
}

.alert-dismissible.alert-info .btn-close {
  color: #4c97b3;
}

.alert-dismissible.alert-info .btn-close:hover {
  color: #326577;
}

.alert-dismissible.alert-warning .btn-close {
  color: #e1a54f;
}

.alert-dismissible.alert-warning .btn-close:hover {
  color: #966e35;
}

.alert-dismissible.alert-danger .btn-close {
  color: #cb5860;
}

.alert-dismissible.alert-danger .btn-close:hover {
  color: #883b40;
}

.alert-icon {
  padding-left: 2.5rem;
}

.alert-icon i {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0.8rem 1rem;
  font-size: 1rem;
}

.alert-blue {
  color: #2c549d;
  background-color: #ecf2fc;
  border-color: #c5d7f6;
}

.alert-blue .alert-link {
  color: #23437e;
}

.alert-purple {
  color: #515892;
  background-color: #f1f2fa;
  border-color: #d5d8f1;
}

.alert-purple .alert-link {
  color: #414675;
}

.alert-violet {
  color: #70578a;
  background-color: #f6f2f9;
  border-color: #e3d8ee;
}

.alert-violet .alert-link {
  color: #5a466e;
}

.alert-pink {
  color: #924b5e;
  background-color: #faf0f3;
  border-color: #f1d3db;
}

.alert-pink .alert-link {
  color: #753c4b;
}

.alert-red {
  color: #9e454b;
  background-color: #fceff0;
  border-color: #f6d0d3;
}

.alert-red .alert-link {
  color: #7e373c;
}

.alert-orange {
  color: #ad6153;
  background-color: #fef3f1;
  border-color: #fddcd6;
}

.alert-orange .alert-link {
  color: #8a4e42;
}

.alert-yellow {
  color: #af803e;
  background-color: #fff8ee;
  border-color: #fee9cd;
}

.alert-yellow .alert-link {
  color: #8c6632;
}

.alert-green {
  color: #4b8572;
  background-color: #f0f9f6;
  border-color: #d3ece3;
}

.alert-green .alert-link {
  color: #3c6a5b;
}

.alert-leaf {
  color: #57806a;
  background-color: #f2f8f5;
  border-color: #d8e9e0;
}

.alert-leaf .alert-link {
  color: #466655;
}

.alert-aqua {
  color: #3b768b;
  background-color: #eef6f9;
  border-color: #cce5ee;
}

.alert-aqua .alert-link {
  color: #2f5e6f;
}

.alert-navy {
  color: #242c39;
  background-color: #ebecee;
  border-color: #c2c5cb;
}

.alert-navy .alert-link {
  color: #1d232e;
}

.alert-ash {
  color: #686b72;
  background-color: #f4f5f6;
  border-color: #dfe0e3;
}

.alert-ash .alert-link {
  color: #53565b;
}

.alert-white {
  color: #b3b3b3;
  background-color: white;
  border-color: white;
}

.alert-white .alert-link {
  color: #8f8f8f;
}

.alert-light {
  color: #b2b2b2;
  background-color: white;
  border-color: white;
}

.alert-light .alert-link {
  color: #8e8e8e;
}

.alert-gray {
  color: #acadae;
  background-color: #fefefe;
  border-color: #fcfdfd;
}

.alert-gray .alert-link {
  color: #8a8a8b;
}

.alert-dark {
  color: #1b1e23;
  background-color: #e9eaeb;
  border-color: #bebfc2;
}

.alert-dark .alert-link {
  color: #16181c;
}

.alert-primary {
  color: #2c549d;
  background-color: #ecf2fc;
  border-color: #c5d7f6;
}

.alert-primary .alert-link {
  color: #23437e;
}

.alert-secondary {
  color: #777b84;
  background-color: #f7f7f8;
  border-color: #e6e7eb;
}

.alert-secondary .alert-link {
  color: #5f626a;
}

.alert-success {
  color: #4b8572;
  background-color: #f0f9f6;
  border-color: #d3ece3;
}

.alert-success .alert-link {
  color: #3c6a5b;
}

.alert-info {
  color: #3b768b;
  background-color: #eef6f9;
  border-color: #cce5ee;
}

.alert-info .alert-link {
  color: #2f5e6f;
}

.alert-warning {
  color: #af803e;
  background-color: #fff8ee;
  border-color: #fee9cd;
}

.alert-warning .alert-link {
  color: #8c6632;
}

.alert-danger {
  color: #9e454b;
  background-color: #fceff0;
  border-color: #f6d0d3;
}

.alert-danger .alert-link {
  color: #7e373c;
}

.alert[class*=bg-] {
  padding: 0.8rem 0;
}

.alert[class*=bg-] .alert-inner {
  padding-right: 3rem;
}

.alert[class*=bg-].alert-dismissible .btn-close {
  padding-top: 0;
  padding-bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.8);
}

.alert[class*=bg-].alert-dismissible .btn-close:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .alert[class*=bg-].alert-dismissible .btn-close {
    padding-right: 0.5rem;
  }
}

.progress-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.progress-list p {
  margin-bottom: 0.25rem;
}

.progress-list li {
  margin-bottom: 1rem;
}

.progress-list .progressbar svg,
.progress-list .progressbar svg path {
  border-radius: 1rem;
}

.progress-list .progressbar svg path:first-child {
  stroke: rgba(30, 34, 40, 0.06);
}

.progress-list .progressbar.blue svg path:last-child {
  stroke: #3f78e0;
}

.progress-list .progressbar.purple svg path:last-child {
  stroke: #747ed1;
}

.progress-list .progressbar.violet svg path:last-child {
  stroke: #a07cc5;
}

.progress-list .progressbar.pink svg path:last-child {
  stroke: #d16b86;
}

.progress-list .progressbar.red svg path:last-child {
  stroke: #e2626b;
}

.progress-list .progressbar.orange svg path:last-child {
  stroke: #f78b77;
}

.progress-list .progressbar.yellow svg path:last-child {
  stroke: #fab758;
}

.progress-list .progressbar.green svg path:last-child {
  stroke: #6bbea3;
}

.progress-list .progressbar.leaf svg path:last-child {
  stroke: #7cb798;
}

.progress-list .progressbar.aqua svg path:last-child {
  stroke: #54a8c7;
}

.progress-list .progressbar.navy svg path:last-child {
  stroke: #343f52;
}

.progress-list .progressbar.ash svg path:last-child {
  stroke: #9499a3;
}

.progress-list .progressbar.white svg path:last-child {
  stroke: #fff;
}

.progress-list .progressbar.light svg path:last-child {
  stroke: #fefefe;
}

.progress-list .progressbar.gray svg path:last-child {
  stroke: #f6f7f9;
}

.progress-list .progressbar.dark svg path:last-child {
  stroke: #262b32;
}

.progress-list .progressbar.primary svg path:last-child {
  stroke: #3f78e0;
}

.progress-list .progressbar.soft-blue svg path:last-child {
  stroke: #8caeec;
}

.progress-list .progressbar.soft-purple svg path:last-child {
  stroke: #acb2e3;
}

.progress-list .progressbar.soft-violet svg path:last-child {
  stroke: #c6b0dc;
}

.progress-list .progressbar.soft-pink svg path:last-child {
  stroke: #e3a6b6;
}

.progress-list .progressbar.soft-red svg path:last-child {
  stroke: #eea1a6;
}

.progress-list .progressbar.soft-orange svg path:last-child {
  stroke: #fab9ad;
}

.progress-list .progressbar.soft-yellow svg path:last-child {
  stroke: #fcd49b;
}

.progress-list .progressbar.soft-green svg path:last-child {
  stroke: #a6d8c8;
}

.progress-list .progressbar.soft-leaf svg path:last-child {
  stroke: #b0d4c1;
}

.progress-list .progressbar.soft-aqua svg path:last-child {
  stroke: #98cbdd;
}

.progress-list .progressbar.soft-navy svg path:last-child {
  stroke: #858c97;
}

.progress-list .progressbar.soft-ash svg path:last-child {
  stroke: #bfc2c8;
}

.progress-list .progressbar.soft-white svg path:last-child {
  stroke: white;
}

.progress-list .progressbar.soft-light svg path:last-child {
  stroke: #fefefe;
}

.progress-list .progressbar.soft-gray svg path:last-child {
  stroke: #fafafb;
}

.progress-list .progressbar.soft-dark svg path:last-child {
  stroke: #7d8084;
}

.progress-list .progressbar.soft-primary svg path:last-child {
  stroke: #8caeec;
}

.progress-list .progressbar.line {
  position: relative;
}

.progress-list .progressbar.line svg {
  height: 0.3rem;
}

@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}

.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 1040;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 200ms linear, margin-right 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}

.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap:after {
  position: absolute;
  font-family: "Unicons";
  content: "\e93e";
  text-align: center;
  line-height: 2.3rem;
  font-size: 1.2rem;
  color: #3f78e0;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #3f78e0;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}

.btn-close {
  padding: 0 0;
  color: rgba(30, 34, 40, 0.3);
  background: none;
  border: 0;
  line-height: 1;
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn-close {
    transition: none;
  }
}

.btn-close:before {
  font-family: "Unicons";
  content: "\ecef";
  font-size: 1.2rem;
  width: 1.2rem;
  height: 1.2rem;
}

.btn-close:hover {
  color: rgba(30, 34, 40, 0.5);
  text-decoration: none;
}

.btn-close:focus {
  outline: none;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal .btn-close {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
}

[class*=offcanvas-] .btn-close {
  color: rgba(30, 34, 40, 0.8);
}

[class*=offcanvas-] .btn-close:hover {
  color: #1e2228;
}

.modal.fade.modal-bottom-center .modal-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 576px) {
  .modal.fade.modal-bottom-center .modal-dialog {
    margin: 1.75rem auto;
  }
}

@media (max-width: 767.98px) {
  .modal.fade.modal-bottom-center .modal-dialog {
    width: calc(100% - (0.5rem*2));
  }
}

.tooltip {
  font-weight: 500;
}

.has-tooltip:focus {
  outline: 0;
}

.has-popover:focus {
  outline: 0;
}

.popover-header {
  padding: 1rem 1.25rem 0.25rem;
  border: 0;
  font-size: 1rem;
}

.popover-body {
  padding: 0 1.25rem 1rem;
}

.owl-carousel {
  margin-bottom: 1.5rem;
}

.owl-carousel .owl-stage-outer {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.owl-carousel.clients .owl-stage {
  display: flex;
  align-items: center;
}

.owl-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  line-height: 1;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  color: #343f52;
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.02);
  font-size: 1.2rem;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 100%;
  text-shadow: none;
  transition: all 200ms ease-in-out;
  pointer-events: all;
}

@media (prefers-reduced-motion: reduce) {

  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    transition: none;
  }
}

.owl-carousel .owl-nav button.owl-next.disabled,
.owl-carousel .owl-nav button.owl-prev.disabled {
  background: rgba(255, 255, 255, 0.7);
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: rgba(255, 255, 255, 0.9);
}

.owl-carousel .owl-nav button.owl-prev {
  left: 1rem;
}

.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 1rem;
}

.owl-carousel .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  line-height: 1;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.3rem;
  background: #aab0bc;
  opacity: 0.5;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 200ms ease-in-out;
  border-radius: 100%;
  transform: scale(0.6);
  border: 3px solid transparent;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-dots .owl-dot span {
    transition: none;
  }
}

.owl-carousel .owl-dots .owl-dot:hover span {
  transform: scale(1);
}

.owl-carousel .owl-dots .owl-dot.active span {
  transform: scale(1);
  background: none;
  border-color: #aab0bc;
}

.owl-carousel .item figure .item-link {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 1rem;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  color: #343f52;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.02);
  font-size: 1rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.owl-carousel .item figure .item-link:hover {
  background: rgba(255, 255, 255, 0.9);
}

.owl-carousel .item:hover figure .item-link {
  opacity: 1;
  right: 1rem;
}

.owl-carousel.dots-start .owl-dots {
  text-align: left;
  justify-content: flex-start;
}

.owl-carousel.dots-over {
  margin: 0;
}

.owl-carousel.dots-over .owl-dots {
  bottom: 1rem;
}

.owl-carousel.dots-over .owl-dots .owl-dot span {
  background: #fff;
  opacity: 1;
  box-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.02);
}

.owl-carousel.dots-over .owl-dots .owl-dot.active span {
  background: none;
  border-color: #fff;
}

.owl-carousel.dots-dark .owl-dots .owl-dot span {
  background: #fff;
}

.owl-carousel.dots-dark .owl-dots .owl-dot.active span {
  background: none;
  border-color: #fff;
}

.owl-carousel.gap-small .owl-dots {
  margin-top: 1rem;
}

.owl-carousel.nav-dark .owl-nav button,
.owl-carousel.nav-dark .item figure .item-link {
  background: rgba(63, 120, 224, 0.9) !important;
  color: #fff !important;
}

.owl-carousel.nav-dark .owl-nav button.disabled,
.owl-carousel.nav-dark .item figure .item-link.disabled {
  background: rgba(63, 120, 224, 0.9) !important;
}

.owl-carousel.nav-dark .owl-nav button:hover,
.owl-carousel.nav-dark .item figure .item-link:hover {
  background: #3f78e0 !important;
}

@media (max-width: 767px) {
  .owl-carousel .owl-dots {
    margin-top: 1.25rem;
  }
}

.item-inner {
  padding: 1rem 0.75rem;
}

.hero-slider-wrapper {
  position: relative;
  min-height: 750px;
}

.hero-slider-wrapper .hero-slider .owl-slide {
  position: relative;
  height: 750px;
  background-size: cover;
  background-position: center center;
}

.hero-slider-wrapper .hero-slider .owl-slide.bg-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(30, 34, 40, 0.5);
}

.hero-slider-wrapper .hero-slider .owl-slide.bg-overlay-300:before {
  background: rgba(30, 34, 40, 0.3);
}

.hero-slider-wrapper .hero-slider .owl-slide.bg-overlay-400:before {
  background: rgba(30, 34, 40, 0.4);
}

.hero-slider-wrapper .hero-slider .owl-slide.bg-overlay [class*=container] * {
  position: relative;
  z-index: 3;
}

@media (max-width: 767px) {
  .hero-slider-wrapper {
    min-height: 500px !important;
  }

  .hero-slider .owl-slide {
    height: 500px !important;
  }

  .hero-slider .owl-nav {
    display: none;
  }
}

.icon-svg,
.icon-svg.icon-svg-lg {
  width: 3rem;
  height: 3rem;
}

.icon-svg.icon-svg-md {
  width: 2.6rem;
  height: 2.6rem;
}

.icon-svg.icon-svg-sm {
  width: 2.2rem;
  height: 2.2rem;
}

.svg-fill {
  fill: #8caeec;
}

.svg-stroke {
  fill: #36496d;
}

.text-blue .svg-fill {
  fill: #8caeec;
}

.text-purple .svg-fill {
  fill: #acb2e3;
}

.text-violet .svg-fill {
  fill: #c6b0dc;
}

.text-pink .svg-fill {
  fill: #e3a6b6;
}

.text-red .svg-fill {
  fill: #eea1a6;
}

.text-orange .svg-fill {
  fill: #fab9ad;
}

.text-yellow .svg-fill {
  fill: #fcd49b;
}

.text-green .svg-fill {
  fill: #a6d8c8;
}

.text-leaf .svg-fill {
  fill: #b0d4c1;
}

.text-aqua .svg-fill {
  fill: #98cbdd;
}

.text-navy .svg-fill {
  fill: #858c97;
}

.text-ash .svg-fill {
  fill: #bfc2c8;
}

.text-white .svg-fill {
  fill: white;
}

.text-light .svg-fill {
  fill: #fefefe;
}

.text-gray .svg-fill {
  fill: #fafafb;
}

.text-dark .svg-fill {
  fill: #7d8084;
}

.text-primary .svg-fill {
  fill: #8caeec;
}

.ratings {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 0.8rem;
}

.ratings:after {
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  color: #fcc032;
  content: "\2605\2605\2605\2605\2605";
  overflow: hidden;
}

.ratings.one:after {
  content: "\2605";
}

.ratings.two:after {
  content: "\2605\2605";
}

.ratings.three:after {
  content: "\2605\2605\2605";
}

.ratings.four:after {
  content: "\2605\2605\2605\2605";
}

.custom-control .ratings {
  height: 0.9rem;
}

@font-face {
  font-family: "Unicons";
  src: url("/fonts/unicons/Unicons.woff2") format("woff2"), url("/fonts/unicons/Unicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Custom";
  src: url("/fonts/custom/Custom.woff2") format("woff2"), url("/fonts/custom/Custom.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^=uil-],
[class*=" uil-"],
[class^=icn-],
[class*=" icn-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-spacing: normal;
}

[class^=uil-],
[class*=" uil-"] {
  font-family: "Unicons" !important;
}

[class^=icn-],
[class*=" icn-"] {
  font-family: "Custom" !important;
}

.icn-terminal:before {
  content: "\e905";
}

.icn-caret-down:before {
  content: "\e900";
}

.icn-caret-left:before {
  content: "\e901";
}

.icn-caret-right:before {
  content: "\e902";
}

.icn-caret-up:before {
  content: "\e903";
}

.icn-comment-line:before {
  content: "\e904";
}

.uil-plus-0:before {
  content: "\e900";
}

.uil-plus-3:before {
  content: "\e901";
}

.uil-plus-6:before {
  content: "\e902";
}

.uil-plus-10:before {
  content: "\e903";
}

.uil-plus-12:before {
  content: "\e904";
}

.uil-plus-13:before {
  content: "\e905";
}

.uil-plus-16:before {
  content: "\e906";
}

.uil-plus-17:before {
  content: "\e907";
}

.uil-plus-18:before {
  content: "\e908";
}

.uil-plus-21:before {
  content: "\e909";
}

.uil-500-px:before {
  content: "\e90a";
}

.uil-abacus:before {
  content: "\e90b";
}

.uil-accessible-icon-alt:before {
  content: "\e90c";
}

.uil-adjust-circle:before {
  content: "\e90d";
}

.uil-adjust-alt:before {
  content: "\e90e";
}

.uil-adobe-alt:before {
  content: "\e90f";
}

.uil-adjust:before {
  content: "\e910";
}

.uil-align-center:before {
  content: "\e911";
}

.uil-align-center-v:before {
  content: "\e912";
}

.uil-align-center-alt:before {
  content: "\e913";
}

.uil-align-alt:before {
  content: "\e914";
}

.uil-arrow-break:before {
  content: "\e915";
}

.uil-archway:before {
  content: "\e916";
}

.uil-align-center-justify:before {
  content: "\e917";
}

.uil-align-center-h:before {
  content: "\e918";
}

.uil-archive-alt:before {
  content: "\e919";
}

.uil-apple-alt:before {
  content: "\e91a";
}

.uil-annoyed:before {
  content: "\e91b";
}

.uil-angle-up:before {
  content: "\e91c";
}

.uil-angle-right:before {
  content: "\e91d";
}

.uil-angle-down:before {
  content: "\e91e";
}

.uil-angle-double-right:before {
  content: "\e91f";
}

.uil-angle-double-left:before {
  content: "\e920";
}

.uil-arrows-merge:before {
  content: "\e921";
}

.uil-arrows-maximize:before {
  content: "\e922";
}

.uil-angle-right-b:before {
  content: "\e923";
}

.uil-arrow-resize-diagonal:before {
  content: "\e924";
}

.uil-arrow-random:before {
  content: "\e925";
}

.uil-angle-left:before {
  content: "\e926";
}

.uil-annoyed-alt:before {
  content: "\e927";
}

.uil-ball:before {
  content: "\e928";
}

.uil-balance-scale:before {
  content: "\e929";
}

.uil-backward:before {
  content: "\e92a";
}

.uil-backspace:before {
  content: "\e92b";
}

.uil-award-alt:before {
  content: "\e92c";
}

.uil-atom:before {
  content: "\e92d";
}

.uil-auto-flash:before {
  content: "\e92e";
}

.uil-bell-slash:before {
  content: "\e92f";
}

.uil-bell-school:before {
  content: "\e930";
}

.uil-behance:before {
  content: "\e931";
}

.uil-behance-alt:before {
  content: "\e932";
}

.uil-bed:before {
  content: "\e933";
}

.uil-blogger:before {
  content: "\e934";
}

.uil-bluetooth-b:before {
  content: "\e935";
}

.uil-bold:before {
  content: "\e936";
}

.uil-bolt-alt:before {
  content: "\e937";
}

.uil-bolt-slash:before {
  content: "\e938";
}

.uil-bolt:before {
  content: "\e939";
}

.uil-blogger-alt:before {
  content: "\e93a";
}

.uil-black-berry:before {
  content: "\e93b";
}

.uil-atm-card:before {
  content: "\e93c";
}

.uil-arrows-h-alt:before {
  content: "\e93d";
}

.uil-arrow-up:before {
  content: "\e93e";
}

.uil-arrow-up-right:before {
  content: "\e93f";
}

.uil-bookmark:before {
  content: "\e940";
}

.uil-book:before {
  content: "\e941";
}

.uil-book-reader:before {
  content: "\e942";
}

.uil-book-open:before {
  content: "\e943";
}

.uil-border-top:before {
  content: "\e944";
}

.uil-border-right:before {
  content: "\e945";
}

.uil-arrows-left-down:before {
  content: "\e946";
}

.uil-arrows-h:before {
  content: "\e947";
}

.uil-bookmark-full:before {
  content: "\e948";
}

.uil-bitcoin:before {
  content: "\e949";
}

.uil-bitcoin-circle:before {
  content: "\e94a";
}

.uil-bitcoin-bold:before {
  content: "\e94b";
}

.uil-bus:before {
  content: "\e94c";
}

.uil-bus-alt:before {
  content: "\e94d";
}

.uil-bullseye:before {
  content: "\e94e";
}

.uil-building:before {
  content: "\e94f";
}

.uil-calendar-slash:before {
  content: "\e950";
}

.uil-calculator-alt:before {
  content: "\e951";
}

.uil-calcualtor:before {
  content: "\e952";
}

.uil-camera-slash:before {
  content: "\e953";
}

.uil-camera-plus:before {
  content: "\e954";
}

.uil-calendar-alt:before {
  content: "\e955";
}

.uil-bitcoin-alt:before {
  content: "\e956";
}

.uil-calculator:before {
  content: "\e957";
}

.uil-car-slash:before {
  content: "\e958";
}

.uil-car-sideview:before {
  content: "\e959";
}

.uil-bill:before {
  content: "\e95a";
}

.uil-bell:before {
  content: "\e95b";
}

.uil-capture:before {
  content: "\e95c";
}

.uil-camera-change:before {
  content: "\e95d";
}

.uil-calling:before {
  content: "\e95e";
}

.uil-capsule:before {
  content: "\e95f";
}

.uil-bug:before {
  content: "\e960";
}

.uil-at:before {
  content: "\e961";
}

.uil-asterisk:before {
  content: "\e962";
}

.uil-angle-double-up:before {
  content: "\e963";
}

.uil-chart-down:before {
  content: "\e964";
}

.uil-calender:before {
  content: "\e965";
}

.uil-chart-bar:before {
  content: "\e966";
}

.uil-assistive-listening-systems:before {
  content: "\e967";
}

.uil-arrows-v:before {
  content: "\e968";
}

.uil-brush-alt:before {
  content: "\e969";
}

.uil-bring-front:before {
  content: "\e96a";
}

.uil-brightness-minus:before {
  content: "\e96b";
}

.uil-brightness-low:before {
  content: "\e96c";
}

.uil-bright:before {
  content: "\e96d";
}

.uil-briefcase:before {
  content: "\e96e";
}

.uil-circle:before {
  content: "\e96f";
}

.uil-circle-layer:before {
  content: "\e970";
}

.uil-check:before {
  content: "\e971";
}

.uil-book-medical:before {
  content: "\e972";
}

.uil-book-alt:before {
  content: "\e973";
}

.uil-check-square:before {
  content: "\e974";
}

.uil-brightness-half:before {
  content: "\e975";
}

.uil-brightness-empty:before {
  content: "\e976";
}

.uil-bring-bottom:before {
  content: "\e977";
}

.uil-border-out:before {
  content: "\e978";
}

.uil-border-horizontal:before {
  content: "\e979";
}

.uil-border-bottom:before {
  content: "\e97a";
}

.uil-border-alt:before {
  content: "\e97b";
}

.uil-briefcase-alt:before {
  content: "\e97c";
}

.uil-box:before {
  content: "\e97d";
}

.uil-check-circle:before {
  content: "\e97e";
}

.uil-chart-bar-alt:before {
  content: "\e97f";
}

.uil-celsius:before {
  content: "\e980";
}

.uil-cell:before {
  content: "\e981";
}

.uil-boombox:before {
  content: "\e982";
}

.uil-border-clear:before {
  content: "\e983";
}

.uil-bowling-ball:before {
  content: "\e984";
}

.uil-border-left:before {
  content: "\e985";
}

.uil-brightness:before {
  content: "\e986";
}

.uil-brightness-plus:before {
  content: "\e987";
}

.uil-cloud-exclamation:before {
  content: "\e988";
}

.uil-cart:before {
  content: "\e989";
}

.uil-cloud-drizzle:before {
  content: "\e98a";
}

.uil-cloud-download:before {
  content: "\e98b";
}

.uil-border-inner:before {
  content: "\e98c";
}

.uil-books:before {
  content: "\e98d";
}

.uil-chat:before {
  content: "\e98e";
}

.uil-chat-info:before {
  content: "\e98f";
}

.uil-chat-bubble-user:before {
  content: "\e990";
}

.uil-cloud-rain-sun:before {
  content: "\e991";
}

.uil-cloud-database-tree:before {
  content: "\e992";
}

.uil-cloud-data-connection:before {
  content: "\e993";
}

.uil-border-vertical:before {
  content: "\e994";
}

.uil-cloud-computing:before {
  content: "\e995";
}

.uil-caret-right:before {
  content: "\e996";
}

.uil-cloud-check:before {
  content: "\e997";
}

.uil-card-atm:before {
  content: "\e998";
}

.uil-cloud-bookmark:before {
  content: "\e999";
}

.uil-car:before {
  content: "\e99a";
}

.uil-cloud-block:before {
  content: "\e99b";
}

.uil-cloud-sun-rain:before {
  content: "\e99c";
}

.uil-cloud-sun-tear:before {
  content: "\e99d";
}

.uil-cloud-sun-rain-alt:before {
  content: "\e99e";
}

.uil-cloud-sun-meatball:before {
  content: "\e99f";
}

.uil-cloud-unlock:before {
  content: "\e9a0";
}

.uil-cloud-times:before {
  content: "\e9a1";
}

.uil-cloud-sun-hail:before {
  content: "\e9a2";
}

.uil-cloud-upload:before {
  content: "\e9a3";
}

.uil-cloud-wifi:before {
  content: "\e9a4";
}

.uil-cloud-wind:before {
  content: "\e9a5";
}

.uil-car-wash:before {
  content: "\e9a6";
}

.uil-closed-captioning:before {
  content: "\e9a7";
}

.uil-cloud-slash:before {
  content: "\e9a8";
}

.uil-club:before {
  content: "\e9a9";
}

.uil-cloud-showers-heavy:before {
  content: "\e9aa";
}

.uil-cloud-showers-alt:before {
  content: "\e9ab";
}

.uil-cloud-shield:before {
  content: "\e9ac";
}

.uil-cloud:before {
  content: "\e9ad";
}

.uil-clock:before {
  content: "\e9ae";
}

.uil-cloud-set:before {
  content: "\e9af";
}

.uil-cloud-share:before {
  content: "\e9b0";
}

.uil-clouds:before {
  content: "\e9b1";
}

.uil-clock-two:before {
  content: "\e9b2";
}

.uil-clock-three:before {
  content: "\e9b3";
}

.uil-chart:before {
  content: "\e9b4";
}

.uil-clock-ten:before {
  content: "\e9b5";
}

.uil-cloud-rain:before {
  content: "\e9b6";
}

.uil-baby-carriage:before {
  content: "\e9b7";
}

.uil-bed-double:before {
  content: "\e9b8";
}

.uil-arrow:before {
  content: "\e9b9";
}

.uil-clock-seven:before {
  content: "\e9ba";
}

.uil-cloud-moon:before {
  content: "\e9bb";
}

.uil-cloud-sun:before {
  content: "\e9bc";
}

.uil-backpack:before {
  content: "\e9bd";
}

.uil-basketball:before {
  content: "\e9be";
}

.uil-battery-empty:before {
  content: "\e9bf";
}

.uil-cloud-question:before {
  content: "\e9c0";
}

.uil-bars:before {
  content: "\e9c1";
}

.uil-cloud-moon-rain:before {
  content: "\e9c2";
}

.uil-battery-bolt:before {
  content: "\e9c3";
}

.uil-cloud-moon-hail:before {
  content: "\e9c4";
}

.uil-ban:before {
  content: "\e9c5";
}

.uil-arrow-up-left:before {
  content: "\e9c6";
}

.uil-basketball-hoop:before {
  content: "\e9c7";
}

.uil-cloud-meatball:before {
  content: "\e9c8";
}

.uil-arrow-to-bottom:before {
  content: "\e9c9";
}

.uil-arrow-to-right:before {
  content: "\e9ca";
}

.uil-cloud-lock:before {
  content: "\e9cb";
}

.uil-arrows-up-right:before {
  content: "\e9cc";
}

.uil-arrows-v-alt:before {
  content: "\e9cd";
}

.uil-bus-school:before {
  content: "\e9ce";
}

.uil-baseball-ball:before {
  content: "\e9cf";
}

.uil-arrows-shrink-v:before {
  content: "\e9d0";
}

.uil-cloud-info:before {
  content: "\e9d1";
}

.uil-arrow-right:before {
  content: "\e9d2";
}

.uil-cloud-hail:before {
  content: "\e9d3";
}

.uil-cancel:before {
  content: "\e9d4";
}

.uil-camera:before {
  content: "\e9d5";
}

.uil-chart-pie:before {
  content: "\e9d6";
}

.uil-clock-nine:before {
  content: "\e9d7";
}

.uil-chart-pie-alt:before {
  content: "\e9d8";
}

.uil-bath:before {
  content: "\e9d9";
}

.uil-bag:before {
  content: "\e9da";
}

.uil-ankh:before {
  content: "\e9db";
}

.uil-angry:before {
  content: "\e9dc";
}

.uil-chart-line:before {
  content: "\e9dd";
}

.uil-clock-five:before {
  content: "\e9de";
}

.uil-clock-eight:before {
  content: "\e9df";
}

.uil-bag-slash:before {
  content: "\e9e0";
}

.uil-bag-alt:before {
  content: "\e9e1";
}

.uil-clipboard:before {
  content: "\e9e2";
}

.uil-clipboard-notes:before {
  content: "\e9e3";
}

.uil-arrows-shrink-h:before {
  content: "\e9e4";
}

.uil-arrows-resize:before {
  content: "\e9e5";
}

.uil-chart-growth:before {
  content: "\e9e6";
}

.uil-clipboard-blank:before {
  content: "\e9e7";
}

.uil-arrows-right-down:before {
  content: "\e9e8";
}

.uil-chart-growth-alt:before {
  content: "\e9e9";
}

.uil-clipboard-alt:before {
  content: "\e9ea";
}

.uil-arrows-resize-h:before {
  content: "\e9eb";
}

.uil-arrows-resize-v:before {
  content: "\e9ec";
}

.uil-airplay:before {
  content: "\e9ed";
}

.uil-angle-double-down:before {
  content: "\e9ee";
}

.uil-android-alt:before {
  content: "\e9ef";
}

.uil-android-phone-slash:before {
  content: "\e9f0";
}

.uil-clapper-board:before {
  content: "\e9f1";
}

.uil-circuit:before {
  content: "\e9f2";
}

.uil-align:before {
  content: "\e9f3";
}

.uil-align-letter-right:before {
  content: "\e9f4";
}

.uil-amazon:before {
  content: "\e9f5";
}

.uil-align-left-justify:before {
  content: "\e9f6";
}

.uil-arrow-left:before {
  content: "\e9f7";
}

.uil-align-justify:before {
  content: "\e9f8";
}

.uil-arrow-growth:before {
  content: "\e9f9";
}

.uil-arrow-from-right:before {
  content: "\e9fa";
}

.uil-arrow-from-top:before {
  content: "\e9fb";
}

.uil-align-left:before {
  content: "\e9fc";
}

.uil-android:before {
  content: "\e9fd";
}

.uil-adobe:before {
  content: "\e9fe";
}

.uil-arrow-down:before {
  content: "\e9ff";
}

.uil-adjust-half:before {
  content: "\ea00";
}

.uil-award:before {
  content: "\ea01";
}

.uil-arrow-down-left:before {
  content: "\ea02";
}

.uil-anchor:before {
  content: "\ea03";
}

.uil-arrow-compress-h:before {
  content: "\ea04";
}

.uil-analytics:before {
  content: "\ea05";
}

.uil-analysis:before {
  content: "\ea06";
}

.uil-apps:before {
  content: "\ea07";
}

.uil-archive:before {
  content: "\ea08";
}

.uil-apple:before {
  content: "\ea09";
}

.uil-arrow-circle-up:before {
  content: "\ea0a";
}

.uil-arrow-circle-right:before {
  content: "\ea0b";
}

.uil-align-right:before {
  content: "\ea0c";
}

.uil-align-right-justify:before {
  content: "\ea0d";
}

.uil-cloud-moon-showers:before {
  content: "\ea0e";
}

.uil-arrow-circle-left:before {
  content: "\ea0f";
}

.uil-arrow-circle-down:before {
  content: "\ea10";
}

.uil-code:before {
  content: "\ea11";
}

.uil-coins:before {
  content: "\ea12";
}

.uil-coffee:before {
  content: "\ea13";
}

.uil-columns:before {
  content: "\ea14";
}

.uil-comment-alt-block:before {
  content: "\ea15";
}

.uil-comment-alt-dots:before {
  content: "\ea16";
}

.uil-comment-alt-chart-lines:before {
  content: "\ea17";
}

.uil-comment-alt-download:before {
  content: "\ea18";
}

.uil-comment-alt-edit:before {
  content: "\ea19";
}

.uil-comment-alt-exclamation:before {
  content: "\ea1a";
}

.uil-comment-alt-check:before {
  content: "\ea1b";
}

.uil-comment-alt-heart:before {
  content: "\ea1c";
}

.uil-comment-alt-image:before {
  content: "\ea1d";
}

.uil-comment-alt-info:before {
  content: "\ea1e";
}

.uil-comment-alt-lines:before {
  content: "\ea1f";
}

.uil-comment-alt-lock:before {
  content: "\ea20";
}

.uil-comment-alt-medical:before {
  content: "\ea21";
}

.uil-comment-alt-message:before {
  content: "\ea22";
}

.uil-comment-alt-notes:before {
  content: "\ea23";
}

.uil-comment-alt-plus:before {
  content: "\ea24";
}

.uil-comment-alt-question:before {
  content: "\ea25";
}

.uil-comment-alt-redo:before {
  content: "\ea26";
}

.uil-comment-alt-search:before {
  content: "\ea27";
}

.uil-comment-alt-share:before {
  content: "\ea28";
}

.uil-comment-alt-shield:before {
  content: "\ea29";
}

.uil-comment-alt-slash:before {
  content: "\ea2a";
}

.uil-comment-alt-upload:before {
  content: "\ea2b";
}

.uil-comment-alt-verify:before {
  content: "\ea2c";
}

.uil-comment-alt:before {
  content: "\ea2d";
}

.uil-comment-block:before {
  content: "\ea2e";
}

.uil-comment-chart-line:before {
  content: "\ea2f";
}

.uil-comment-check:before {
  content: "\ea30";
}

.uil-comment-dots:before {
  content: "\ea31";
}

.uil-comment-download:before {
  content: "\ea32";
}

.uil-comment-edit:before {
  content: "\ea33";
}

.uil-comment-exclamation:before {
  content: "\ea34";
}

.uil-comment-heart:before {
  content: "\ea35";
}

.uil-comment-image:before {
  content: "\ea36";
}

.uil-comment-info-alt:before {
  content: "\ea37";
}

.uil-comment-info:before {
  content: "\ea38";
}

.uil-comment-lines:before {
  content: "\ea39";
}

.uil-comment-lock:before {
  content: "\ea3a";
}

.uil-comment-medical:before {
  content: "\ea3b";
}

.uil-comment-message:before {
  content: "\ea3c";
}

.uil-comment-notes:before {
  content: "\ea3d";
}

.uil-comment-plus:before {
  content: "\ea3e";
}

.uil-comment-question:before {
  content: "\ea3f";
}

.uil-comment-redo:before {
  content: "\ea40";
}

.uil-comment-search:before {
  content: "\ea41";
}

.uil-comment-share:before {
  content: "\ea42";
}

.uil-comment-shield:before {
  content: "\ea43";
}

.uil-comment-slash:before {
  content: "\ea44";
}

.uil-comment-upload:before {
  content: "\ea45";
}

.uil-comment-verify:before {
  content: "\ea46";
}

.uil-comment:before {
  content: "\ea47";
}

.uil-comments-alt:before {
  content: "\ea48";
}

.uil-comments:before {
  content: "\ea49";
}

.uil-commnet-alt-slash:before {
  content: "\ea4a";
}

.uil-compact-disc:before {
  content: "\ea4b";
}

.uil-compass:before {
  content: "\ea4c";
}

.uil-compress-alt-left:before {
  content: "\ea4d";
}

.uil-compress-alt:before {
  content: "\ea4e";
}

.uil-compress-arrows:before {
  content: "\ea4f";
}

.uil-compress-lines:before {
  content: "\ea50";
}

.uil-compress-point:before {
  content: "\ea51";
}

.uil-compress-v:before {
  content: "\ea52";
}

.uil-compress:before {
  content: "\ea53";
}

.uil-computer-mouse:before {
  content: "\ea54";
}

.uil-confused:before {
  content: "\ea55";
}

.uil-constructor:before {
  content: "\ea56";
}

.uil-copy-alt:before {
  content: "\ea57";
}

.uil-copy-landscape:before {
  content: "\ea58";
}

.uil-copy:before {
  content: "\ea59";
}

.uil-copyright:before {
  content: "\ea5a";
}

.uil-corner-down-left:before {
  content: "\ea5b";
}

.uil-corner-down-right-alt:before {
  content: "\ea5c";
}

.uil-corner-down-right:before {
  content: "\ea5d";
}

.uil-corner-right-down:before {
  content: "\ea5e";
}

.uil-corner-left-down:before {
  content: "\ea5f";
}

.uil-corner-up-left-alt:before {
  content: "\ea60";
}

.uil-corner-up-left:before {
  content: "\ea61";
}

.uil-corner-up-right-alt:before {
  content: "\ea62";
}

.uil-corner-up-right:before {
  content: "\ea63";
}

.uil-creative-commons-pd-alt:before {
  content: "\ea64";
}

.uil-creative-commons-pd:before {
  content: "\ea65";
}

.uil-crockery:before {
  content: "\ea66";
}

.uil-crop-alt-rotate-left:before {
  content: "\ea67";
}

.uil-crop-alt-rotate-right:before {
  content: "\ea68";
}

.uil-crop-alt:before {
  content: "\ea69";
}

.uil-crosshair-alt:before {
  content: "\ea6a";
}

.uil-crosshair:before {
  content: "\ea6b";
}

.uil-cube:before {
  content: "\ea6c";
}

.uil-dashboard:before {
  content: "\ea6d";
}

.uil-crosshairs:before {
  content: "\ea6e";
}

.uil-database:before {
  content: "\ea6f";
}

.uil-desert:before {
  content: "\ea70";
}

.uil-desktop-alt-slash:before {
  content: "\ea71";
}

.uil-database-alt:before {
  content: "\ea72";
}

.uil-data-sharing:before {
  content: "\ea73";
}

.uil-desktop-slash:before {
  content: "\ea74";
}

.uil-desktop:before {
  content: "\ea75";
}

.uil-dialpad-alt:before {
  content: "\ea76";
}

.uil-desktop-cloud-alt:before {
  content: "\ea77";
}

.uil-desktop-alt:before {
  content: "\ea78";
}

.uil-diary:before {
  content: "\ea79";
}

.uil-dice-five:before {
  content: "\ea7a";
}

.uil-diary-alt:before {
  content: "\ea7b";
}

.uil-dice-four:before {
  content: "\ea7c";
}

.uil-diamond:before {
  content: "\ea7d";
}

.uil-dialpad:before {
  content: "\ea7e";
}

.uil-dice-two:before {
  content: "\ea7f";
}

.uil-direction:before {
  content: "\ea80";
}

.uil-directions:before {
  content: "\ea81";
}

.uil-dice-three:before {
  content: "\ea82";
}

.uil-dice-six:before {
  content: "\ea83";
}

.uil-document-layout-center:before {
  content: "\ea84";
}

.uil-dice-one:before {
  content: "\ea85";
}

.uil-dna:before {
  content: "\ea86";
}

.uil-dizzy-meh:before {
  content: "\ea87";
}

.uil-dollar-sign:before {
  content: "\ea88";
}

.uil-down-arrow:before {
  content: "\ea89";
}

.uil-dollar-sign-alt:before {
  content: "\ea8a";
}

.uil-dollar-alt:before {
  content: "\ea8b";
}

.uil-document:before {
  content: "\ea8c";
}

.uil-document-layout-right:before {
  content: "\ea8d";
}

.uil-document-layout-left:before {
  content: "\ea8e";
}

.uil-edit-alt:before {
  content: "\ea8f";
}

.uil-edit:before {
  content: "\ea90";
}

.uil-ellipsis-h:before {
  content: "\ea91";
}

.uil-ellipsis-v:before {
  content: "\ea92";
}

.uil-emoji:before {
  content: "\ea93";
}

.uil-enter:before {
  content: "\ea94";
}

.uil-entry:before {
  content: "\ea95";
}

.uil-envelope-add:before {
  content: "\ea96";
}

.uil-envelope-alt:before {
  content: "\ea97";
}

.uil-envelope-block:before {
  content: "\ea98";
}

.uil-ear:before {
  content: "\ea99";
}

.uil-dumbbell:before {
  content: "\ea9a";
}

.uil-dropbox:before {
  content: "\ea9b";
}

.uil-dribbble:before {
  content: "\ea9c";
}

.uil-envelope-exclamation:before {
  content: "\ea9d";
}

.uil-download-alt:before {
  content: "\ea9e";
}

.uil-envelope-edit:before {
  content: "\ea9f";
}

.uil-envelope-heart:before {
  content: "\eaa0";
}

.uil-drill:before {
  content: "\eaa1";
}

.uil-envelope-download-alt:before {
  content: "\eaa2";
}

.uil-envelope-info:before {
  content: "\eaa3";
}

.uil-envelope-lock:before {
  content: "\eaa4";
}

.uil-envelope-check:before {
  content: "\eaa5";
}

.uil-envelope-minus:before {
  content: "\eaa6";
}

.uil-envelope-open:before {
  content: "\eaa7";
}

.uil-envelope-bookmark:before {
  content: "\eaa8";
}

.uil-envelope-question:before {
  content: "\eaa9";
}

.uil-envelope-receive:before {
  content: "\eaaa";
}

.uil-envelope-redo:before {
  content: "\eaab";
}

.uil-envelope-search:before {
  content: "\eaac";
}

.uil-envelope-send:before {
  content: "\eaad";
}

.uil-envelope-share:before {
  content: "\eaae";
}

.uil-envelope-shield:before {
  content: "\eaaf";
}

.uil-envelope-star:before {
  content: "\eab0";
}

.uil-envelope-times:before {
  content: "\eab1";
}

.uil-envelope-upload-alt:before {
  content: "\eab2";
}

.uil-envelope-upload:before {
  content: "\eab3";
}

.uil-envelope:before {
  content: "\eab4";
}

.uil-envelopes:before {
  content: "\eab5";
}

.uil-equal-circle:before {
  content: "\eab6";
}

.uil-euro-circle:before {
  content: "\eab7";
}

.uil-euro:before {
  content: "\eab8";
}

.uil-exchange-alt:before {
  content: "\eab9";
}

.uil-exchange:before {
  content: "\eaba";
}

.uil-exclamation-circle:before {
  content: "\eabb";
}

.uil-exclamation-octagon:before {
  content: "\eabc";
}

.uil-exclude:before {
  content: "\eabd";
}

.uil-exit:before {
  content: "\eabe";
}

.uil-expand-alt:before {
  content: "\eabf";
}

.uil-expand-arrows-alt:before {
  content: "\eac0";
}

.uil-expand-arrows:before {
  content: "\eac1";
}

.uil-exclamation-triangle:before {
  content: "\eac2";
}

.uil-expand-right:before {
  content: "\eac3";
}

.uil-expand-left:before {
  content: "\eac4";
}

.uil-expand-from-corner:before {
  content: "\eac5";
}

.uil-external-link-alt:before {
  content: "\eac6";
}

.uil-eye-slash:before {
  content: "\eac7";
}

.uil-eye:before {
  content: "\eac8";
}

.uil-exposure-increase:before {
  content: "\eac9";
}

.uil-exposure-alt:before {
  content: "\eaca";
}

.uil-export:before {
  content: "\eacb";
}

.uil-facebook:before {
  content: "\eacc";
}

.uil-facebook-messenger:before {
  content: "\eacd";
}

.uil-facebook-messenger-alt:before {
  content: "\eace";
}

.uil-facebook-f:before {
  content: "\eacf";
}

.uil-file-alt:before {
  content: "\ead0";
}

.uil-fast-mail:before {
  content: "\ead1";
}

.uil-feedback:before {
  content: "\ead2";
}

.uil-favorite:before {
  content: "\ead3";
}

.uil-fast-mail-alt:before {
  content: "\ead4";
}

.uil-fahrenheit:before {
  content: "\ead5";
}

.uil-cloud-showers:before {
  content: "\ead6";
}

.uil-cloud-moon-meatball:before {
  content: "\ead7";
}

.uil-cloud-redo:before {
  content: "\ead8";
}

.uil-cloud-heart:before {
  content: "\ead9";
}

.uil-ambulance:before {
  content: "\eada";
}

.uil-arrow-down-right:before {
  content: "\eadb";
}

.uil-envelope-download:before {
  content: "\eadc";
}

.uil-cog:before {
  content: "\eadd";
}

.uil-file-block-alt:before {
  content: "\eade";
}

.uil-file-blank:before {
  content: "\eadf";
}

.uil-file-bookmark-alt:before {
  content: "\eae0";
}

.uil-file-check-alt:before {
  content: "\eae1";
}

.uil-file-check:before {
  content: "\eae2";
}

.uil-file-contract-dollar:before {
  content: "\eae3";
}

.uil-file-copy-alt:before {
  content: "\eae4";
}

.uil-file-download-alt:before {
  content: "\eae5";
}

.uil-file-download:before {
  content: "\eae6";
}

.uil-file-edit-alt:before {
  content: "\eae7";
}

.uil-file-exclamation-alt:before {
  content: "\eae8";
}

.uil-file-exclamation:before {
  content: "\eae9";
}

.uil-file-heart:before {
  content: "\eaea";
}

.uil-file-info-alt:before {
  content: "\eaeb";
}

.uil-file-landscape-alt:before {
  content: "\eaec";
}

.uil-file-landscape:before {
  content: "\eaed";
}

.uil-file-lanscape-slash:before {
  content: "\eaee";
}

.uil-file-lock-alt:before {
  content: "\eaef";
}

.uil-file-medical-alt:before {
  content: "\eaf0";
}

.uil-file-medical:before {
  content: "\eaf1";
}

.uil-file-minus-alt:before {
  content: "\eaf2";
}

.uil-file-minus:before {
  content: "\eaf3";
}

.uil-file-network:before {
  content: "\eaf4";
}

.uil-file-plus-alt:before {
  content: "\eaf5";
}

.uil-file-question-alt:before {
  content: "\eaf6";
}

.uil-file-plus:before {
  content: "\eaf7";
}

.uil-file-question:before {
  content: "\eaf8";
}

.uil-file-redo-alt:before {
  content: "\eaf9";
}

.uil-file-search-alt:before {
  content: "\eafa";
}

.uil-file-share-alt:before {
  content: "\eafb";
}

.uil-file-shield-alt:before {
  content: "\eafc";
}

.uil-file-slash:before {
  content: "\eafd";
}

.uil-file-times-alt:before {
  content: "\eafe";
}

.uil-file-times:before {
  content: "\eaff";
}

.uil-file-upload-alt:before {
  content: "\eb00";
}

.uil-file-upload:before {
  content: "\eb01";
}

.uil-file:before {
  content: "\eb02";
}

.uil-files-landscapes:before {
  content: "\eb03";
}

.uil-files-landscapes-alt:before {
  content: "\eb04";
}

.uil-film:before {
  content: "\eb05";
}

.uil-filter-slash:before {
  content: "\eb06";
}

.uil-filter:before {
  content: "\eb07";
}

.uil-fire:before {
  content: "\eb08";
}

.uil-flask-potion:before {
  content: "\eb09";
}

.uil-flask:before {
  content: "\eb0a";
}

.uil-flip-h-alt:before {
  content: "\eb0b";
}

.uil-flip-h:before {
  content: "\eb0c";
}

.uil-flip-v-alt:before {
  content: "\eb0d";
}

.uil-flip-v:before {
  content: "\eb0e";
}

.uil-flower:before {
  content: "\eb0f";
}

.uil-focus-add:before {
  content: "\eb10";
}

.uil-focus-target:before {
  content: "\eb11";
}

.uil-focus:before {
  content: "\eb12";
}

.uil-folder-check:before {
  content: "\eb13";
}

.uil-folder-download:before {
  content: "\eb14";
}

.uil-folder-exclamation:before {
  content: "\eb15";
}

.uil-folder-heart:before {
  content: "\eb16";
}

.uil-folder-info:before {
  content: "\eb17";
}

.uil-folder-lock:before {
  content: "\eb18";
}

.uil-folder-medical:before {
  content: "\eb19";
}

.uil-folder-minus:before {
  content: "\eb1a";
}

.uil-folder-network:before {
  content: "\eb1b";
}

.uil-folder-plus:before {
  content: "\eb1c";
}

.uil-folder-question:before {
  content: "\eb1d";
}

.uil-folder-slash:before {
  content: "\eb1e";
}

.uil-folder-times:before {
  content: "\eb1f";
}

.uil-folder-upload:before {
  content: "\eb20";
}

.uil-folder:before {
  content: "\eb21";
}

.uil-food:before {
  content: "\eb22";
}

.uil-football-american:before {
  content: "\eb23";
}

.uil-football-ball:before {
  content: "\eb24";
}

.uil-football:before {
  content: "\eb25";
}

.uil-forecastcloud-moon-tear:before {
  content: "\eb26";
}

.uil-forwaded-call:before {
  content: "\eb27";
}

.uil-forward:before {
  content: "\eb28";
}

.uil-frown:before {
  content: "\eb29";
}

.uil-game-structure:before {
  content: "\eb2a";
}

.uil-game:before {
  content: "\eb2b";
}

.uil-gift:before {
  content: "\eb2c";
}

.uil-github-alt:before {
  content: "\eb2d";
}

.uil-github:before {
  content: "\eb2e";
}

.uil-glass-martini-alt:before {
  content: "\eb2f";
}

.uil-glass-martini-alt-slash:before {
  content: "\eb30";
}

.uil-glass-martini:before {
  content: "\eb31";
}

.uil-glass-tea:before {
  content: "\eb32";
}

.uil-glass:before {
  content: "\eb33";
}

.uil-globe:before {
  content: "\eb34";
}

.uil-gold:before {
  content: "\eb35";
}

.uil-google-drive-alt:before {
  content: "\eb36";
}

.uil-google-drive:before {
  content: "\eb37";
}

.uil-google-hangouts-alt:before {
  content: "\eb38";
}

.uil-google-hangouts:before {
  content: "\eb39";
}

.uil-google-play:before {
  content: "\eb3a";
}

.uil-google:before {
  content: "\eb3b";
}

.uil-graduation-hat:before {
  content: "\eb3c";
}

.uil-graph-bar:before {
  content: "\eb3d";
}

.uil-grid:before {
  content: "\eb3e";
}

.uil-grids:before {
  content: "\eb3f";
}

.uil-grin-tongue-wink-alt:before {
  content: "\eb40";
}

.uil-grin-tongue-wink:before {
  content: "\eb41";
}

.uil-grin:before {
  content: "\eb42";
}

.uil-grip-horizontal-line:before {
  content: "\eb43";
}

.uil-hdd:before {
  content: "\eb44";
}

.uil-headphones-alt:before {
  content: "\eb45";
}

.uil-headphones:before {
  content: "\eb46";
}

.uil-heart-alt:before {
  content: "\eb47";
}

.uil-heart-medical:before {
  content: "\eb48";
}

.uil-heart-rate:before {
  content: "\eb49";
}

.uil-heart-sign:before {
  content: "\eb4a";
}

.uil-heart:before {
  content: "\eb4b";
}

.uil-heartbeat:before {
  content: "\eb4c";
}

.uil-history-alt:before {
  content: "\eb4d";
}

.uil-history:before {
  content: "\eb4e";
}

.uil-home-alt:before {
  content: "\eb4f";
}

.uil-home:before {
  content: "\eb50";
}

.uil-horizontal-align-center:before {
  content: "\eb51";
}

.uil-horizontal-align-left:before {
  content: "\eb52";
}

.uil-horizontal-align-right:before {
  content: "\eb53";
}

.uil-horizontal-distribution-center:before {
  content: "\eb54";
}

.uil-horizontal-distribution-left:before {
  content: "\eb55";
}

.uil-horizontal-distribution-right:before {
  content: "\eb56";
}

.uil-hourglass:before {
  content: "\eb57";
}

.uil-css3-alt:before {
  content: "\eb58";
}

.uil-css3:before {
  content: "\eb59";
}

.uil-hunting:before {
  content: "\eb5a";
}

.uil-image-alt-slash:before {
  content: "\eb5b";
}

.uil-image-block:before {
  content: "\eb5c";
}

.uil-image-broken:before {
  content: "\eb5d";
}

.uil-image-check:before {
  content: "\eb5e";
}

.uil-image-download:before {
  content: "\eb5f";
}

.uil-image-edit:before {
  content: "\eb60";
}

.uil-image-lock:before {
  content: "\eb61";
}

.uil-image-minus:before {
  content: "\eb62";
}

.uil-image-plus:before {
  content: "\eb63";
}

.uil-image-question:before {
  content: "\eb64";
}

.uil-image-redo:before {
  content: "\eb65";
}

.uil-image-resize-landscape:before {
  content: "\eb66";
}

.uil-image-resize-square:before {
  content: "\eb67";
}

.uil-image-search:before {
  content: "\eb68";
}

.uil-image-share:before {
  content: "\eb69";
}

.uil-image-shield:before {
  content: "\eb6a";
}

.uil-image-slash:before {
  content: "\eb6b";
}

.uil-image-times:before {
  content: "\eb6c";
}

.uil-image-upload:before {
  content: "\eb6d";
}

.uil-image-v:before {
  content: "\eb6e";
}

.uil-image:before {
  content: "\eb6f";
}

.uil-images:before {
  content: "\eb70";
}

.uil-import:before {
  content: "\eb71";
}

.uil-incoming-call:before {
  content: "\eb72";
}

.uil-info-circle:before {
  content: "\eb73";
}

.uil-instagram-alt:before {
  content: "\eb74";
}

.uil-instagram:before {
  content: "\eb75";
}

.uil-intercom-alt:before {
  content: "\eb76";
}

.uil-intercom:before {
  content: "\eb77";
}

.uil-invoice:before {
  content: "\eb78";
}

.uil-italic:before {
  content: "\eb79";
}

.uil-jackhammer:before {
  content: "\eb7a";
}

.uil-java-script:before {
  content: "\eb7b";
}

.uil-kayak:before {
  content: "\eb7c";
}

.uil-key-skeleton-alt:before {
  content: "\eb7d";
}

.uil-key-skeleton:before {
  content: "\eb7e";
}

.uil-keyboard-alt:before {
  content: "\eb7f";
}

.uil-keyboard-hide:before {
  content: "\eb80";
}

.uil-keyboard-show:before {
  content: "\eb81";
}

.uil-keyboard:before {
  content: "\eb82";
}

.uil-keyhole-circle:before {
  content: "\eb83";
}

.uil-keyhole-square-full:before {
  content: "\eb84";
}

.uil-keyhole-square:before {
  content: "\eb85";
}

.uil-kid:before {
  content: "\eb86";
}

.uil-label-alt:before {
  content: "\eb87";
}

.uil-label:before {
  content: "\eb88";
}

.uil-lamp:before {
  content: "\eb89";
}

.uil-laptop-cloud:before {
  content: "\eb8a";
}

.uil-laptop:before {
  content: "\eb8b";
}

.uil-laughing:before {
  content: "\eb8c";
}

.uil-layer-group:before {
  content: "\eb8d";
}

.uil-layers-alt:before {
  content: "\eb8e";
}

.uil-layers-slash:before {
  content: "\eb8f";
}

.uil-layers:before {
  content: "\eb90";
}

.uil-left-arrow-from-left:before {
  content: "\eb91";
}

.uil-left-arrow-to-left:before {
  content: "\eb92";
}

.uil-left-indent:before {
  content: "\eb93";
}

.uil-left-to-right-text-direction:before {
  content: "\eb94";
}

.uil-life-ring:before {
  content: "\eb95";
}

.uil-lightbulb-alt:before {
  content: "\eb96";
}

.uil-lightbulb:before {
  content: "\eb97";
}

.uil-line-alt:before {
  content: "\eb98";
}

.uil-line-spacing:before {
  content: "\eb99";
}

.uil-line:before {
  content: "\eb9a";
}

.uil-link-alt:before {
  content: "\eb9b";
}

.uil-link-broken:before {
  content: "\eb9c";
}

.uil-link-h:before {
  content: "\eb9d";
}

.uil-link:before {
  content: "\eb9e";
}

.uil-linkedin-alt:before {
  content: "\eb9f";
}

.uil-left-indent-alt:before {
  content: "\eba0";
}

.uil-list-ul:before {
  content: "\eba1";
}

.uil-location-arrow-alt:before {
  content: "\eba2";
}

.uil-location-arrow:before {
  content: "\eba3";
}

.uil-layer-group-slash:before {
  content: "\eba4";
}

.uil-location-pin-alt:before {
  content: "\eba5";
}

.uil-list-ui-alt:before {
  content: "\eba6";
}

.uil-location-point:before {
  content: "\eba7";
}

.uil-linkedin:before {
  content: "\eba8";
}

.uil-lock-access:before {
  content: "\eba9";
}

.uil-location:before {
  content: "\ebaa";
}

.uil-lock-alt:before {
  content: "\ebab";
}

.uil-lock-open-alt:before {
  content: "\ebac";
}

.uil-lock-slash:before {
  content: "\ebad";
}

.uil-mailbox-alt:before {
  content: "\ebae";
}

.uil-lock:before {
  content: "\ebaf";
}

.uil-mailbox:before {
  content: "\ebb0";
}

.uil-map-marker-alt:before {
  content: "\ebb1";
}

.uil-map-marker-edit:before {
  content: "\ebb2";
}

.uil-map-marker-info:before {
  content: "\ebb3";
}

.uil-map-marker-minus:before {
  content: "\ebb4";
}

.uil-map-marker-plus:before {
  content: "\ebb5";
}

.uil-map-marker-question:before {
  content: "\ebb6";
}

.uil-map-marker-shield:before {
  content: "\ebb7";
}

.uil-map-marker-slash:before {
  content: "\ebb8";
}

.uil-map-marker:before {
  content: "\ebb9";
}

.uil-map-pin-alt:before {
  content: "\ebba";
}

.uil-map-pin:before {
  content: "\ebbb";
}

.uil-map:before {
  content: "\ebbc";
}

.uil-mars:before {
  content: "\ebbd";
}

.uil-master-card:before {
  content: "\ebbe";
}

.uil-maximize-left:before {
  content: "\ebbf";
}

.uil-medal:before {
  content: "\ebc0";
}

.uil-medical-drip:before {
  content: "\ebc1";
}

.uil-medical-square-full:before {
  content: "\ebc2";
}

.uil-medical-square:before {
  content: "\ebc3";
}

.uil-medical:before {
  content: "\ebc4";
}

.uil-medium-m:before {
  content: "\ebc5";
}

.uil-medkit:before {
  content: "\ebc6";
}

.uil-meeting-board:before {
  content: "\ebc7";
}

.uil-megaphone:before {
  content: "\ebc8";
}

.uil-meh-alt:before {
  content: "\ebc9";
}

.uil-meh-closed-eye:before {
  content: "\ebca";
}

.uil-meh:before {
  content: "\ebcb";
}

.uil-message:before {
  content: "\ebcc";
}

.uil-metro:before {
  content: "\ebcd";
}

.uil-microphone-slash:before {
  content: "\ebce";
}

.uil-microphone:before {
  content: "\ebcf";
}

.uil-minus-circle:before {
  content: "\ebd0";
}

.uil-minus-path:before {
  content: "\ebd1";
}

.uil-minus-square-full:before {
  content: "\ebd2";
}

.uil-minus-square:before {
  content: "\ebd3";
}

.uil-minus:before {
  content: "\ebd4";
}

.uil-missed-call:before {
  content: "\ebd5";
}

.uil-mobey-bill-slash:before {
  content: "\ebd6";
}

.uil-mobile-android-alt:before {
  content: "\ebd7";
}

.uil-mobile-android:before {
  content: "\ebd8";
}

.uil-mobile-vibrate:before {
  content: "\ebd9";
}

.uil-modem:before {
  content: "\ebda";
}

.uil-money-bill-stack:before {
  content: "\ebdb";
}

.uil-money-bill:before {
  content: "\ebdc";
}

.uil-money-insert:before {
  content: "\ebdd";
}

.uil-money-stack:before {
  content: "\ebde";
}

.uil-money-withdraw:before {
  content: "\ebdf";
}

.uil-money-withdrawal:before {
  content: "\ebe0";
}

.uil-moneybag-alt:before {
  content: "\ebe1";
}

.uil-moneybag:before {
  content: "\ebe2";
}

.uil-monitor-heart-rate:before {
  content: "\ebe3";
}

.uil-monitor:before {
  content: "\ebe4";
}

.uil-moon-eclipse:before {
  content: "\ebe5";
}

.uil-moon:before {
  content: "\ebe6";
}

.uil-moonset:before {
  content: "\ebe7";
}

.uil-mountains-sun:before {
  content: "\ebe8";
}

.uil-mountains:before {
  content: "\ebe9";
}

.uil-mouse-alt:before {
  content: "\ebea";
}

.uil-mouse:before {
  content: "\ebeb";
}

.uil-multiply:before {
  content: "\ebec";
}

.uil-music-note:before {
  content: "\ebed";
}

.uil-music-tune-slash:before {
  content: "\ebee";
}

.uil-music:before {
  content: "\ebef";
}

.uil-n-a:before {
  content: "\ebf0";
}

.uil-navigator:before {
  content: "\ebf1";
}

.uil-nerd:before {
  content: "\ebf2";
}

.uil-newspaper:before {
  content: "\ebf3";
}

.uil-ninja:before {
  content: "\ebf4";
}

.uil-no-entry:before {
  content: "\ebf5";
}

.uil-notebooks:before {
  content: "\ebf6";
}

.uil-notes:before {
  content: "\ebf7";
}

.uil-object-group:before {
  content: "\ebf8";
}

.uil-object-ungroup:before {
  content: "\ebf9";
}

.uil-octagon:before {
  content: "\ebfa";
}

.uil-opera-alt:before {
  content: "\ebfb";
}

.uil-opera:before {
  content: "\ebfc";
}

.uil-outgoing-call:before {
  content: "\ebfd";
}

.uil-package:before {
  content: "\ebfe";
}

.uil-padlock:before {
  content: "\ebff";
}

.uil-paint-tool:before {
  content: "\ec00";
}

.uil-palette:before {
  content: "\ec01";
}

.uil-panorama-h-alt:before {
  content: "\ec02";
}

.uil-panorama-h:before {
  content: "\ec03";
}

.uil-panorama-v:before {
  content: "\ec04";
}

.uil-paperclip:before {
  content: "\ec05";
}

.uil-paragraph:before {
  content: "\ec06";
}

.uil-parcel:before {
  content: "\ec07";
}

.uil-parking-square:before {
  content: "\ec08";
}

.uil-pathfinder-unite:before {
  content: "\ec09";
}

.uil-pathfinder:before {
  content: "\ec0a";
}

.uil-pause-circle:before {
  content: "\ec0b";
}

.uil-pause:before {
  content: "\ec0c";
}

.uil-paypal:before {
  content: "\ec0d";
}

.uil-pen:before {
  content: "\ec0e";
}

.uil-pentagon:before {
  content: "\ec0f";
}

.uil-percentage:before {
  content: "\ec10";
}

.uil-phone-alt:before {
  content: "\ec11";
}

.uil-phone-pause:before {
  content: "\ec12";
}

.uil-phone-slash:before {
  content: "\ec13";
}

.uil-phone-times:before {
  content: "\ec14";
}

.uil-phone-volume:before {
  content: "\ec15";
}

.uil-phone:before {
  content: "\ec16";
}

.uil-picture:before {
  content: "\ec17";
}

.uil-plane-departure:before {
  content: "\ec18";
}

.uil-plane-fly:before {
  content: "\ec19";
}

.uil-plane:before {
  content: "\ec1a";
}

.uil-play-circle:before {
  content: "\ec1b";
}

.uil-plane-arrival:before {
  content: "\ec1c";
}

.uil-plus-circle:before {
  content: "\ec1d";
}

.uil-plus-square:before {
  content: "\ec1e";
}

.uil-plus:before {
  content: "\ec1f";
}

.uil-polygon:before {
  content: "\ec20";
}

.uil-podium:before {
  content: "\ec21";
}

.uil-presentation-lines-alt:before {
  content: "\ec22";
}

.uil-presentation-line:before {
  content: "\ec23";
}

.uil-previous:before {
  content: "\ec24";
}

.uil-presentation:before {
  content: "\ec25";
}

.uil-pound:before {
  content: "\ec26";
}

.uil-postcard:before {
  content: "\ec27";
}

.uil-pound-circle:before {
  content: "\ec28";
}

.uil-post-stamp:before {
  content: "\ec29";
}

.uil-registered:before {
  content: "\ec2a";
}

.uil-refresh:before {
  content: "\ec2b";
}

.uil-right-to-left-text-direction:before {
  content: "\ec2c";
}

.uil-record-audio:before {
  content: "\ec2d";
}

.uil-receipt:before {
  content: "\ec2e";
}

.uil-question-circle:before {
  content: "\ec2f";
}

.uil-puzzle-piece:before {
  content: "\ec30";
}

.uil-pump:before {
  content: "\ec31";
}

.uil-sad-squint:before {
  content: "\ec32";
}

.uil-sad-crying:before {
  content: "\ec33";
}

.uil-sad-cry:before {
  content: "\ec34";
}

.uil-receipt-alt:before {
  content: "\ec35";
}

.uil-raindrops:before {
  content: "\ec36";
}

.uil-raindrops-alt:before {
  content: "\ec37";
}

.uil-sad-dizzy:before {
  content: "\ec38";
}

.uil-presentation-play:before {
  content: "\ec39";
}

.uil-presentation-times:before {
  content: "\ec3a";
}

.uil-shopping-cart-alt:before {
  content: "\ec3b";
}

.uil-shovel:before {
  content: "\ec3c";
}

.uil-smile-wink-alt:before {
  content: "\ec3d";
}

.uil-shopping-trolley:before {
  content: "\ec3e";
}

.uil-smile-squint-wink:before {
  content: "\ec3f";
}

.uil-smile-beam:before {
  content: "\ec40";
}

.uil-sliders-v:before {
  content: "\ec41";
}

.uil-presentation-minus:before {
  content: "\ec42";
}

.uil-smile-squint-wink-alt:before {
  content: "\ec43";
}

.uil-smile-dizzy:before {
  content: "\ec44";
}

.uil-swatchbook:before {
  content: "\ec45";
}

.uil-sliders-v-alt:before {
  content: "\ec46";
}

.uil-surprise:before {
  content: "\ec47";
}

.uil-sun:before {
  content: "\ec48";
}

.uil-sunset:before {
  content: "\ec49";
}

.uil-suitcase:before {
  content: "\ec4a";
}

.uil-slack:before {
  content: "\ec4b";
}

.uil-shield-check:before {
  content: "\ec4c";
}

.uil-shield-exclamation:before {
  content: "\ec4d";
}

.uil-rss:before {
  content: "\ec4e";
}

.uil-ruler:before {
  content: "\ec4f";
}

.uil-ruler-combined:before {
  content: "\ec50";
}

.uil-suitcase-alt:before {
  content: "\ec51";
}

.uil-store-alt:before {
  content: "\ec52";
}

.uil-stop-circle:before {
  content: "\ec53";
}

.uil-step-backward:before {
  content: "\ec54";
}

.uil-step-forward:before {
  content: "\ec55";
}

.uil-rss-interface:before {
  content: "\ec56";
}

.uil-react:before {
  content: "\ec57";
}

.uil-rss-alt:before {
  content: "\ec58";
}

.uil-redo:before {
  content: "\ec59";
}

.uil-right-indent-alt:before {
  content: "\ec5a";
}

.uil-reddit-alien-alt:before {
  content: "\ec5b";
}

.uil-step-backward-circle:before {
  content: "\ec5c";
}

.uil-spin:before {
  content: "\ec5d";
}

.uil-spade:before {
  content: "\ec5e";
}

.uil-space-key:before {
  content: "\ec5f";
}

.uil-restaurant:before {
  content: "\ec60";
}

.uil-snowflake:before {
  content: "\ec61";
}

.uil-repeat:before {
  content: "\ec62";
}

.uil-snowflake-alt:before {
  content: "\ec63";
}

.uil-snapchat-ghost:before {
  content: "\ec64";
}

.uil-smile-wink:before {
  content: "\ec65";
}

.uil-smile:before {
  content: "\ec66";
}

.uil-raddit-alien-alt:before {
  content: "\ec67";
}

.uil-rainbow:before {
  content: "\ec68";
}

.uil-rotate-360:before {
  content: "\ec69";
}

.uil-rope-way:before {
  content: "\ec6a";
}

.uil-snapchat-alt:before {
  content: "\ec6b";
}

.uil-sperms:before {
  content: "\ec6c";
}

.uil-stopwatch:before {
  content: "\ec6d";
}

.uil-robot:before {
  content: "\ec6e";
}

.uil-signal:before {
  content: "\ec6f";
}

.uil-silence:before {
  content: "\ec70";
}

.uil-silent-squint:before {
  content: "\ec71";
}

.uil-square:before {
  content: "\ec72";
}

.uil-signal-alt:before {
  content: "\ec73";
}

.uil-square-shape:before {
  content: "\ec74";
}

.uil-star-half-alt:before {
  content: "\ec75";
}

.uil-squint:before {
  content: "\ec76";
}

.uil-shopping-basket:before {
  content: "\ec77";
}

.uil-slack-alt:before {
  content: "\ec78";
}

.uil-skype:before {
  content: "\ec79";
}

.uil-square-full:before {
  content: "\ec7a";
}

.uil-sorting:before {
  content: "\ec7b";
}

.uil-sort:before {
  content: "\ec7c";
}

.uil-sort-amount-up:before {
  content: "\ec7d";
}

.uil-skip-forward-circle:before {
  content: "\ec7e";
}

.uil-skype-alt:before {
  content: "\ec7f";
}

.uil-skip-forward-alt:before {
  content: "\ec80";
}

.uil-skip-forward:before {
  content: "\ec81";
}

.uil-sitemap:before {
  content: "\ec82";
}

.uil-sort-amount-down:before {
  content: "\ec83";
}

.uil-sim-card:before {
  content: "\ec84";
}

.uil-snow-flake:before {
  content: "\ec85";
}

.uil-sport:before {
  content: "\ec86";
}

.uil-servers:before {
  content: "\ec87";
}

.uil-snapchat-square:before {
  content: "\ec88";
}

.uil-sign-alt:before {
  content: "\ec89";
}

.uil-sign-in-alt:before {
  content: "\ec8a";
}

.uil-server-connection:before {
  content: "\ec8b";
}

.uil-shuffle:before {
  content: "\ec8c";
}

.uil-shutter:before {
  content: "\ec8d";
}

.uil-shrink:before {
  content: "\ec8e";
}

.uil-server:before {
  content: "\ec8f";
}

.uil-server-alt:before {
  content: "\ec90";
}

.uil-selfie:before {
  content: "\ec91";
}

.uil-search:before {
  content: "\ec92";
}

.uil-shutter-alt:before {
  content: "\ec93";
}

.uil-sigma:before {
  content: "\ec94";
}

.uil-server-network:before {
  content: "\ec95";
}

.uil-search-plus:before {
  content: "\ec96";
}

.uil-search-alt:before {
  content: "\ec97";
}

.uil-scroll:before {
  content: "\ec98";
}

.uil-sick:before {
  content: "\ec99";
}

.uil-subway-alt:before {
  content: "\ec9a";
}

.uil-subway:before {
  content: "\ec9b";
}

.uil-search-minus:before {
  content: "\ec9c";
}

.uil-scroll-h:before {
  content: "\ec9d";
}

.uil-screw:before {
  content: "\ec9e";
}

.uil-signal-alt-3:before {
  content: "\ec9f";
}

.uil-sign-right:before {
  content: "\eca0";
}

.uil-sign-out-alt:before {
  content: "\eca1";
}

.uil-schedule:before {
  content: "\eca2";
}

.uil-science:before {
  content: "\eca3";
}

.uil-sign-left:before {
  content: "\eca4";
}

.uil-scenery:before {
  content: "\eca5";
}

.uil-scaling-right:before {
  content: "\eca6";
}

.uil-presentation-edit:before {
  content: "\eca7";
}

.uil-presentation-check:before {
  content: "\eca8";
}

.uil-processor:before {
  content: "\eca9";
}

.uil-process:before {
  content: "\ecaa";
}

.uil-presentation-plus:before {
  content: "\ecab";
}

.uil-scaling-left:before {
  content: "\ecac";
}

.uil-sad:before {
  content: "\ecad";
}

.uil-print-slash:before {
  content: "\ecae";
}

.uil-print:before {
  content: "\ecaf";
}

.uil-share-alt:before {
  content: "\ecb0";
}

.uil-pricetag-alt:before {
  content: "\ecb1";
}

.uil-subject:before {
  content: "\ecb2";
}

.uil-servicemark:before {
  content: "\ecb3";
}

.uil-streering:before {
  content: "\ecb4";
}

.uil-plug:before {
  content: "\ecb5";
}

.uil-prescription-bottle:before {
  content: "\ecb6";
}

.uil-store:before {
  content: "\ecb7";
}

.uil-power:before {
  content: "\ecb8";
}

.uil-stretcher:before {
  content: "\ecb9";
}

.uil-shop:before {
  content: "\ecba";
}

.uil-play:before {
  content: "\ecbb";
}

.uil-shield:before {
  content: "\ecbc";
}

.uil-ship:before {
  content: "\ecbd";
}

.uil-shield-slash:before {
  content: "\ecbe";
}

.uil-shield-question:before {
  content: "\ecbf";
}

.uil-swiggy:before {
  content: "\ecc0";
}

.uil-swimmer:before {
  content: "\ecc1";
}

.uil-symbol:before {
  content: "\ecc2";
}

.uil-sync-exclamation:before {
  content: "\ecc3";
}

.uil-sync-slash:before {
  content: "\ecc4";
}

.uil-sync:before {
  content: "\ecc5";
}

.uil-syringe:before {
  content: "\ecc6";
}

.uil-table:before {
  content: "\ecc7";
}

.uil-tablet:before {
  content: "\ecc8";
}

.uil-tablets:before {
  content: "\ecc9";
}

.uil-tachometer-fast:before {
  content: "\ecca";
}

.uil-tag-alt:before {
  content: "\eccb";
}

.uil-tag:before {
  content: "\eccc";
}

.uil-tape:before {
  content: "\eccd";
}

.uil-taxi:before {
  content: "\ecce";
}

.uil-tear:before {
  content: "\eccf";
}

.uil-technology:before {
  content: "\ecd0";
}

.uil-telegram-alt:before {
  content: "\ecd1";
}

.uil-telegram:before {
  content: "\ecd2";
}

.uil-telescope:before {
  content: "\ecd3";
}

.uil-temperature-empty:before {
  content: "\ecd4";
}

.uil-temperature-half:before {
  content: "\ecd5";
}

.uil-temperature-plus:before {
  content: "\ecd6";
}

.uil-temperature-minus:before {
  content: "\ecd7";
}

.uil-temperature-quarter:before {
  content: "\ecd8";
}

.uil-temperature:before {
  content: "\ecd9";
}

.uil-temperature-three-quarter:before {
  content: "\ecda";
}

.uil-text-fields:before {
  content: "\ecdb";
}

.uil-text-size:before {
  content: "\ecdc";
}

.uil-text-strike-through:before {
  content: "\ecdd";
}

.uil-text:before {
  content: "\ecde";
}

.uil-th-large:before {
  content: "\ecdf";
}

.uil-th-slash:before {
  content: "\ece0";
}

.uil-th:before {
  content: "\ece1";
}

.uil-step-backward-alt:before {
  content: "\ece2";
}

.uil-stopwatch-slash:before {
  content: "\ece3";
}

.uil-star:before {
  content: "\ece4";
}

.uil-server-network-alt:before {
  content: "\ece5";
}

.uil-thermometer:before {
  content: "\ece6";
}

.uil-thumbs-down:before {
  content: "\ece7";
}

.uil-thumbs-up:before {
  content: "\ece8";
}

.uil-thunderstorm-moon:before {
  content: "\ece9";
}

.uil-thunderstorm-sun:before {
  content: "\ecea";
}

.uil-thunderstorm:before {
  content: "\eceb";
}

.uil-ticket:before {
  content: "\ecec";
}

.uil-times-circle:before {
  content: "\eced";
}

.uil-times-square:before {
  content: "\ecee";
}

.uil-times:before {
  content: "\ecef";
}

.uil-toggle-on:before {
  content: "\ecf0";
}

.uil-toggle-off:before {
  content: "\ecf1";
}

.uil-top-arrow-from-top:before {
  content: "\ecf2";
}

.uil-top-arrow-to-top:before {
  content: "\ecf3";
}

.uil-tornado:before {
  content: "\ecf4";
}

.uil-trademark-circle:before {
  content: "\ecf5";
}

.uil-trademark:before {
  content: "\ecf6";
}

.uil-traffic-barrier:before {
  content: "\ecf7";
}

.uil-trash-alt:before {
  content: "\ecf8";
}

.uil-trash:before {
  content: "\ecf9";
}

.uil-trophy:before {
  content: "\ecfa";
}

.uil-trees:before {
  content: "\ecfb";
}

.uil-trowel:before {
  content: "\ecfc";
}

.uil-truck-case:before {
  content: "\ecfd";
}

.uil-triangle:before {
  content: "\ecfe";
}

.uil-truck-loading:before {
  content: "\ecff";
}

.uil-truck:before {
  content: "\ed00";
}

.uil-tumblr-alt:before {
  content: "\ed01";
}

.uil-tumblr-square:before {
  content: "\ed02";
}

.uil-tumblr:before {
  content: "\ed03";
}

.uil-tv-retro-slash:before {
  content: "\ed04";
}

.uil-tv-retro:before {
  content: "\ed05";
}

.uil-twitter-alt:before {
  content: "\ed06";
}

.uil-umbrella:before {
  content: "\ed07";
}

.uil-unamused:before {
  content: "\ed08";
}

.uil-underline:before {
  content: "\ed09";
}

.uil-twitter:before {
  content: "\ed0a";
}

.uil-university:before {
  content: "\ed0b";
}

.uil-unlock-alt:before {
  content: "\ed0c";
}

.uil-unlock:before {
  content: "\ed0d";
}

.uil-upload-alt:before {
  content: "\ed0e";
}

.uil-upload:before {
  content: "\ed0f";
}

.uil-usd-circle:before {
  content: "\ed10";
}

.uil-usd-square:before {
  content: "\ed11";
}

.uil-user-check:before {
  content: "\ed12";
}

.uil-user-circle:before {
  content: "\ed13";
}

.uil-user-hard-hat:before {
  content: "\ed14";
}

.uil-user-exclamation:before {
  content: "\ed15";
}

.uil-user-minus:before {
  content: "\ed16";
}

.uil-user-plus:before {
  content: "\ed17";
}

.uil-user-square:before {
  content: "\ed18";
}

.uil-user-times:before {
  content: "\ed19";
}

.uil-user:before {
  content: "\ed1a";
}

.uil-users-alt:before {
  content: "\ed1b";
}

.uil-utensils-alt:before {
  content: "\ed1c";
}

.uil-utensils:before {
  content: "\ed1d";
}

.uil-vector-square-alt:before {
  content: "\ed1e";
}

.uil-vector-square:before {
  content: "\ed1f";
}

.uil-venus:before {
  content: "\ed20";
}

.uil-vertical-align-bottom:before {
  content: "\ed21";
}

.uil-vertical-align-center:before {
  content: "\ed22";
}

.uil-vertical-align-top:before {
  content: "\ed23";
}

.uil-vertical-distribute-bottom:before {
  content: "\ed24";
}

.uil-vertical-distribution-center:before {
  content: "\ed25";
}

.uil-vertical-distribution-top:before {
  content: "\ed26";
}

.uil-video-slash:before {
  content: "\ed27";
}

.uil-video:before {
  content: "\ed28";
}

.uil-visual-studio:before {
  content: "\ed29";
}

.uil-vk-alt:before {
  content: "\ed2a";
}

.uil-vk:before {
  content: "\ed2b";
}

.uil-voicemail-rectangle:before {
  content: "\ed2c";
}

.uil-voicemail:before {
  content: "\ed2d";
}

.uil-volleyball:before {
  content: "\ed2e";
}

.uil-volume-down:before {
  content: "\ed2f";
}

.uil-volume-mute:before {
  content: "\ed30";
}

.uil-volume-off:before {
  content: "\ed31";
}

.uil-volume-up:before {
  content: "\ed32";
}

.uil-volume:before {
  content: "\ed33";
}

.uil-vuejs-alt:before {
  content: "\ed34";
}

.uil-wall:before {
  content: "\ed35";
}

.uil-wallet:before {
  content: "\ed36";
}

.uil-water-drop-slash:before {
  content: "\ed37";
}

.uil-watch:before {
  content: "\ed38";
}

.uil-watch-alt:before {
  content: "\ed39";
}

.uil-web-grid-alt:before {
  content: "\ed3a";
}

.uil-water:before {
  content: "\ed3b";
}

.uil-water-glass:before {
  content: "\ed3c";
}

.uil-webcam:before {
  content: "\ed3d";
}

.uil-web-section:before {
  content: "\ed3e";
}

.uil-vuejs:before {
  content: "\ed3f";
}

.uil-wheel-barrow:before {
  content: "\ed40";
}

.uil-web-grid:before {
  content: "\ed41";
}

.uil-web-section-alt:before {
  content: "\ed42";
}

.uil-wifi-slash:before {
  content: "\ed43";
}

.uil-wifi:before {
  content: "\ed44";
}

.uil-wifi-router:before {
  content: "\ed45";
}

.uil-wheelchair:before {
  content: "\ed46";
}

.uil-wind-moon:before {
  content: "\ed47";
}

.uil-wheelchair-alt:before {
  content: "\ed48";
}

.uil-wind-sun:before {
  content: "\ed49";
}

.uil-window-maximize:before {
  content: "\ed4a";
}

.uil-window-grid:before {
  content: "\ed4b";
}

.uil-window-restore:before {
  content: "\ed4c";
}

.uil-window-section:before {
  content: "\ed4d";
}

.uil-weight:before {
  content: "\ed4e";
}

.uil-window:before {
  content: "\ed4f";
}

.uil-windsock:before {
  content: "\ed50";
}

.uil-wrap-text:before {
  content: "\ed51";
}

.uil-wrench:before {
  content: "\ed52";
}

.uil-whatsapp:before {
  content: "\ed53";
}

.uil-yellow:before {
  content: "\ed54";
}

.uil-yen-circle:before {
  content: "\ed55";
}

.uil-yen:before {
  content: "\ed56";
}

.uil-yin-yang:before {
  content: "\ed57";
}

.uil-youtube-alt:before {
  content: "\ed58";
}

.uil-youtube:before {
  content: "\ed59";
}

.uil-wind:before {
  content: "\ed5a";
}

.counter-wrapper p {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 500;
}

.counter-wrapper .counter {
  font-size: calc(1.325rem + 0.9vw);
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 0.5rem;
}

@media (min-width: 1200px) {
  .counter-wrapper .counter {
    font-size: 2rem;
  }
}

.counter-wrapper .counter.counter-lg {
  font-size: calc(1.345rem + 1.14vw);
}

@media (min-width: 1200px) {
  .counter-wrapper .counter.counter-lg {
    font-size: 2.2rem;
  }
}

.counter-wrapper .card-body {
  padding: 1.5rem;
}

.counter-wrapper .icon-bg {
  margin: 0 auto;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content-wrapper,
footer {
  flex-shrink: 0;
}

.content-wrapper {
  flex-grow: 1;
}

.overlay {
  position: relative;
  text-align: center;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

figure.overlay img {
  opacity: 1;
  width: 100%;
  max-width: 100%;
  vertical-align: top;
  position: relative;
}

.overlay figcaption,
.overlay .figcaption {
  padding: 0.5rem;
  z-index: 5;
  pointer-events: none;
}

.overlay * {
  transition: all 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .overlay * {
    transition: none;
  }
}

.overlay img {
  transition: none;
}

.overlay:not(.caption-overlay) i {
  display: block;
  font-size: calc(1.265rem + 0.18vw);
}

@media (min-width: 1200px) {
  .overlay:not(.caption-overlay) i {
    font-size: 1.4rem;
  }
}

.overlay a,
.overlay span.bg {
  position: relative;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  cursor: pointer !important;
}

.overlay span.bg {
  opacity: 0;
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
}

.overlay span.bg {
  background: rgba(30, 34, 40, 0.6);
}

.overlay.color span.bg {
  background: rgba(63, 120, 224, 0.7);
}

.overlay.light span.bg {
  background: rgba(255, 255, 255, 0.6);
}

.overlay:hover span.bg {
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .overlay:hover span.bg {
    transition: none;
  }
}

.overlay * {
  color: #fff !important;
}

.overlay.light * {
  color: #343f52 !important;
}

.overlay img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  vertical-align: top;
}

.overlay1 * {
  box-sizing: border-box;
}

.overlay1 figcaption {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  text-align: center;
}

.overlay1 .from-top {
  position: absolute;
  padding: 0.75rem 1rem;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-80%);
}

.overlay1:hover figcaption,
.overlay1:hover .figcaption {
  opacity: 1;
}

.overlay1:hover figcaption *,
.overlay1:hover .figcaption * {
  transform: translateY(-50%);
}

.overlay2 * {
  position: absolute;
  left: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

.overlay2>* {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  position: absolute;
}

.overlay2 figcaption {
  height: 100%;
  opacity: 0;
}

.overlay2 img {
  position: relative;
}

.overlay2 .from-top {
  bottom: 50%;
  padding: 0 1rem;
  transform: translate3d(0%, -100%, 0);
}

.overlay2 .from-bottom {
  top: 50%;
  padding: 0 1rem;
  transform: translate3d(0%, 100%, 0);
}

.overlay2:hover * {
  opacity: 1;
}

.overlay2:hover figcaption * {
  transform: translate3d(0%, 0%, 0);
}

.hover-scale {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translateY(0);
}

.hover-scale img {
  transition: all 0.35s ease-in-out;
}

.hover-scale:hover img {
  transform: scale(1.05);
}

.itooltip-inner {
  padding: 0.7rem 1rem;
  box-shadow: var(--shadow);
  -webkit-animation: 0.15s linear fadein;
  animation: 0.15s linear fadein;
  border-radius: 0.4rem;
}

.itooltip-inner,
.itooltip-light.itooltip-inner {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  color: inherit;
}

.itooltip-dark.itooltip-inner {
  background: rgba(30, 34, 40, 0.9);
}

.itooltip-color.itooltip-inner {
  background: rgba(63, 120, 224, 0.9);
}

.itooltip-blue.itooltip-inner {
  background: rgba(63, 120, 224, 0.9);
}

.itooltip-purple.itooltip-inner {
  background: rgba(116, 126, 209, 0.9);
}

.itooltip-violet.itooltip-inner {
  background: rgba(160, 124, 197, 0.9);
}

.itooltip-pink.itooltip-inner {
  background: rgba(209, 107, 134, 0.9);
}

.itooltip-red.itooltip-inner {
  background: rgba(226, 98, 107, 0.9);
}

.itooltip-orange.itooltip-inner {
  background: rgba(247, 139, 119, 0.9);
}

.itooltip-yellow.itooltip-inner {
  background: rgba(250, 183, 88, 0.9);
}

.itooltip-green.itooltip-inner {
  background: rgba(107, 190, 163, 0.9);
}

.itooltip-leaf.itooltip-inner {
  background: rgba(124, 183, 152, 0.9);
}

.itooltip-aqua.itooltip-inner {
  background: rgba(84, 168, 199, 0.9);
}

.itooltip-navy.itooltip-inner {
  background: rgba(52, 63, 82, 0.9);
}

.itooltip-ash.itooltip-inner {
  background: rgba(148, 153, 163, 0.9);
}

.itooltip-white.itooltip-inner {
  background: rgba(255, 255, 255, 0.9);
}

.itooltip-light.itooltip-inner {
  background: rgba(254, 254, 254, 0.9);
}

.itooltip-gray.itooltip-inner {
  background: rgba(246, 247, 249, 0.9);
}

.itooltip-primary.itooltip-inner {
  background: rgba(63, 120, 224, 0.9);
}

.itooltip-secondary.itooltip-inner {
  background: rgba(170, 176, 188, 0.9);
}

.itooltip-success.itooltip-inner {
  background: rgba(107, 190, 163, 0.9);
}

.itooltip-info.itooltip-inner {
  background: rgba(84, 168, 199, 0.9);
}

.itooltip-warning.itooltip-inner {
  background: rgba(250, 183, 88, 0.9);
}

.itooltip-danger.itooltip-inner {
  background: rgba(226, 98, 107, 0.9);
}

[class*=itooltip-]:not(.itooltip-light).itooltip-inner * {
  color: #fff;
}

.lift {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.lift:hover {
  transform: translateY(-0.4rem);
  box-shadow: 0rem 0.25rem 1.25rem rgba(52, 63, 82, 0.1);
}

.lg-backdrop {
  background: rgba(30, 34, 40, 0.9);
}

.lg-outer .lg-img-wrap {
  padding: 0.5rem;
}

.lg-outer .lg-item {
  background: none;
}

.lg-outer .lg-item.lg-complete:after {
  display: none;
}

.lg-outer .lg-toolbar {
  background: none;
}

.lg-image,
.lg-object {
  border-radius: 0.4rem !important;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  border: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.2rem;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 100%;
  margin-top: 0;
  padding: 0;
  box-shadow: none;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}

.lg-actions .lg-next:before,
.lg-actions .lg-prev:before {
  font-family: "Unicons";
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  background: rgba(0, 0, 0, 0.5);
}

.lg-actions .lg-next:after,
.lg-actions .lg-prev:after {
  display: none;
}

.lg-actions .lg-prev {
  left: 1rem;
}

.lg-actions .lg-prev:before {
  content: "\e9f7";
}

.lg-actions .lg-next {
  right: 1rem;
}

.lg-actions .lg-next:before {
  content: "\e9d2";
}

.lg-toolbar .lg-icon.lg-close {
  border: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.2rem;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 100%;
  margin-top: 0;
  padding: 0;
  box-shadow: none;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lg-toolbar .lg-icon:hover {
  background: rgba(0, 0, 0, 0.5);
}

.lg-toolbar .lg-icon.lg-close:after {
  font-family: "Unicons";
}

.lg-toolbar .lg-icon.lg-close:after {
  content: "\ecef";
}

.lg-toolbar .lg-icon.lg-close {
  margin-right: 1rem;
  margin-top: 1rem;
}

.lg-dropdown {
  z-index: 3051;
}

.lg-sub-html {
  padding: 0.5rem 0.5rem 1rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(30, 34, 40, 0.4) 100%);
}

.lg-sub-html p {
  font-size: 0.8rem;
  color: #fff;
  margin: 0;
}

.lg-sub-html h5,
.lg-sub-html .h5 {
  color: #fff;
  margin-bottom: 0.2rem;
}

video.player {
  max-width: 100%;
}

.plyr {
  border-radius: 0.4rem;
  position: relative;
  z-index: 2;
}

.plyr__control--overlaid {
  border: 0;
  padding: 1.25rem;
  background: #fff;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  color: #343f52;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  background: #fff !important;
  color: #3f78e0 !important;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  transition: all 200ms ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .plyr__control--overlaid:focus,
  .plyr__control--overlaid:hover {
    transition: none;
  }
}

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(30, 34, 40, 0.2));
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
.plyr--video .plyr__controls .plyr__control:hover,
.plyr--video .plyr__controls .plyr__control[aria-expanded=true] {
  background: #3f78e0;
}

.plyr--full-ui input[type=range] {
  color: #3f78e0;
}

.plyr__control--overlaid::before,
.plyr__control--overlaid::after,
.btn-circle.ripple::before,
.btn-circle.ripple::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: #fff;
  opacity: 0.8;
  -webkit-animation: ripple-1 2s infinite ease-in-out;
  animation: ripple-1 2s infinite ease-in-out;
  z-index: -1;
}

.plyr__control--overlaid::after,
.btn-circle.ripple::after {
  opacity: 0.6;
  -webkit-animation: ripple-2 2s infinite ease-in-out;
  animation: ripple-2 2s infinite ease-in-out;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.btn-blue.btn-circle.ripple::before,
.btn-blue.btn-circle.ripple::after {
  background: #3f78e0;
}

.btn-purple.btn-circle.ripple::before,
.btn-purple.btn-circle.ripple::after {
  background: #747ed1;
}

.btn-violet.btn-circle.ripple::before,
.btn-violet.btn-circle.ripple::after {
  background: #a07cc5;
}

.btn-pink.btn-circle.ripple::before,
.btn-pink.btn-circle.ripple::after {
  background: #d16b86;
}

.btn-red.btn-circle.ripple::before,
.btn-red.btn-circle.ripple::after {
  background: #e2626b;
}

.btn-orange.btn-circle.ripple::before,
.btn-orange.btn-circle.ripple::after {
  background: #f78b77;
}

.btn-yellow.btn-circle.ripple::before,
.btn-yellow.btn-circle.ripple::after {
  background: #fab758;
}

.btn-green.btn-circle.ripple::before,
.btn-green.btn-circle.ripple::after {
  background: #6bbea3;
}

.btn-leaf.btn-circle.ripple::before,
.btn-leaf.btn-circle.ripple::after {
  background: #7cb798;
}

.btn-aqua.btn-circle.ripple::before,
.btn-aqua.btn-circle.ripple::after {
  background: #54a8c7;
}

.btn-navy.btn-circle.ripple::before,
.btn-navy.btn-circle.ripple::after {
  background: #343f52;
}

.btn-ash.btn-circle.ripple::before,
.btn-ash.btn-circle.ripple::after {
  background: #9499a3;
}

.btn-white.btn-circle.ripple::before,
.btn-white.btn-circle.ripple::after {
  background: #fff;
}

.btn-light.btn-circle.ripple::before,
.btn-light.btn-circle.ripple::after {
  background: #fefefe;
}

.btn-dark.btn-circle.ripple::before,
.btn-dark.btn-circle.ripple::after {
  background: #343f52;
}

.btn-primary.btn-circle.ripple::before,
.btn-primary.btn-circle.ripple::after {
  background: #3f78e0;
}

.btn-secondary.btn-circle.ripple::before,
.btn-secondary.btn-circle.ripple::after {
  background: #aab0bc;
}

.btn-success.btn-circle.ripple::before,
.btn-success.btn-circle.ripple::after {
  background: #6bbea3;
}

.btn-info.btn-circle.ripple::before,
.btn-info.btn-circle.ripple::after {
  background: #54a8c7;
}

.btn-warning.btn-circle.ripple::before,
.btn-warning.btn-circle.ripple::after {
  background: #fab758;
}

.btn-danger.btn-circle.ripple::before,
.btn-danger.btn-circle.ripple::after {
  background: #e2626b;
}

.btn-gray.btn-circle.ripple::before,
.btn-gray.btn-circle.ripple::after {
  background: #aab0bc;
}

.btn-soft-blue.btn-circle.ripple::before,
.btn-soft-blue.btn-circle.ripple::after {
  background-color: #e0e9fa;
}

.btn-soft-purple.btn-circle.ripple::before,
.btn-soft-purple.btn-circle.ripple::after {
  background-color: #e9eaf8;
}

.btn-soft-violet.btn-circle.ripple::before,
.btn-soft-violet.btn-circle.ripple::after {
  background-color: #f0eaf6;
}

.btn-soft-pink.btn-circle.ripple::before,
.btn-soft-pink.btn-circle.ripple::after {
  background-color: #f8e7ec;
}

.btn-soft-red.btn-circle.ripple::before,
.btn-soft-red.btn-circle.ripple::after {
  background-color: #fae6e7;
}

.btn-soft-orange.btn-circle.ripple::before,
.btn-soft-orange.btn-circle.ripple::after {
  background-color: #feece9;
}

.btn-soft-yellow.btn-circle.ripple::before,
.btn-soft-yellow.btn-circle.ripple::after {
  background-color: #fef3e4;
}

.btn-soft-green.btn-circle.ripple::before,
.btn-soft-green.btn-circle.ripple::after {
  background-color: #e7f5f0;
}

.btn-soft-leaf.btn-circle.ripple::before,
.btn-soft-leaf.btn-circle.ripple::after {
  background-color: #eaf3ef;
}

.btn-soft-aqua.btn-circle.ripple::before,
.btn-soft-aqua.btn-circle.ripple::after {
  background-color: #e4f1f6;
}

.btn-soft-navy.btn-circle.ripple::before,
.btn-soft-navy.btn-circle.ripple::after {
  background-color: #dfe0e3;
}

.btn-soft-ash.btn-circle.ripple::before,
.btn-soft-ash.btn-circle.ripple::after {
  background-color: #eeeff0;
}

.btn-soft-dark.btn-circle.ripple::before,
.btn-soft-dark.btn-circle.ripple::after {
  background-color: #dcddde;
}

.btn-soft-primary.btn-circle.ripple::before,
.btn-soft-primary.btn-circle.ripple::after {
  background-color: #e0e9fa;
}

.pricing-wrapper [class*=col-] {
  z-index: 1;
}

.pricing-wrapper [class*=col-].popular {
  z-index: 3 !important;
}

.pricing-wrapper [class*=col-] .pricing.card .card-body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.pricing-wrapper [class*=col-] .pricing.card .card-body .icon {
  margin-bottom: 0.75rem;
}

.pricing-wrapper [class*=col-] .pricing.card .card-body .card-title {
  margin: 0 0 1.75rem 0;
  padding: 0;
}

.pricing-wrapper [class*=col-] .pricing.card .prices {
  padding: 0;
  margin: 0;
  line-height: 1;
  background: none;
  font-weight: normal;
  position: relative;
  height: 3rem;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-value {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  .pricing-wrapper [class*=col-] .pricing.card .prices .price-value {
    font-size: 3rem;
  }
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-currency {
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 0.2rem;
  padding-top: 0.4rem;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-duration {
  font-size: 0.8rem;
  font-weight: 500;
  color: #60697b;
  margin-top: auto;
  margin-bottom: 0.25rem;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-duration:before {
  content: "/";
  padding-right: 0.25rem;
  padding-left: 0.1rem;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-hidden {
  display: none;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-show {
  -webkit-animation: priceShow 0.6s forwards;
  animation: priceShow 0.6s forwards;
}

.pricing-wrapper [class*=col-] .pricing.card .prices .price-hide {
  -webkit-animation: priceFade 0.6s forwards;
  animation: priceFade 0.6s forwards;
}

.pricing-switcher-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pricing-switcher-wrapper p:last-child {
  position: relative;
}

.pricing-switcher-wrapper p:last-child .badge {
  position: absolute;
  top: -1rem;
  right: -2rem;
}

.pricing-switchers {
  width: 2rem;
  height: 1rem;
  clear: both;
  text-align: center;
  position: relative;
  background-color: rgba(30, 34, 40, 0.07);
  border-radius: 1.5rem;
  border: 0.2rem solid transparent;
  box-sizing: content-box;
}

.pricing-switchers .pricing-switcher {
  cursor: pointer;
  width: 100%;
  float: left;
  height: 1rem;
  line-height: 1rem;
  position: relative;
  z-index: 888;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}

.pricing-switchers .pricing-switcher:nth-child(1).pricing-switcher-active~.switcher-button {
  left: 0;
}

.pricing-switchers .pricing-switcher:nth-child(2).pricing-switcher-active~.switcher-button {
  left: 50%;
}

.pricing-switchers .switcher-button {
  height: 1rem;
  width: 1rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 555;
  margin: 0;
  border: none;
  transition: 0.3s ease-in-out;
  border-radius: 100%;
}

.pricing-switchers .pricing-switcher.pricing-switcher-active {
  color: #fff;
}

.switcher-dark .pricing-switchers {
  background-color: rgba(255, 255, 255, 0.06);
}

@media (min-width: 992px) {
  .pricing-wrapper .popular .pricing.card {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: -1rem;
  }
}

@media (min-width: 992px) {
  .process-wrapper.line [class*=col-] {
    position: relative;
  }

  .process-wrapper.line [class*=col-]:after {
    width: 100%;
    position: absolute;
    content: "";
    height: 1px;
    background: none;
    border-top: 1px solid rgba(164, 174, 198, 0.2);
    top: 1.5rem;
    z-index: 1;
    left: 3rem;
  }

  .process-wrapper.line [class*=col-]:last-child:after {
    display: none;
  }

  .process-wrapper.line.text-center [class*=col-]:before,
  .process-wrapper.line.text-center [class*=col-]:after {
    width: 50%;
    position: absolute;
    content: "";
    height: 1px;
    background: rgba(164, 174, 198, 0.2);
    border: 0;
    top: 1.5rem;
    z-index: 1;
    left: 0;
  }

  .process-wrapper.line.text-center [class*=col-]:after {
    right: 0;
    left: auto;
  }

  .process-wrapper.line.text-center [class*=col-]:first-child:before,
  .process-wrapper.line.text-center [class*=col-]:last-child:after {
    display: none;
  }
}

.process-wrapper.line .btn-circle {
  position: relative;
  z-index: 2;
}

.projects-tiles .project:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .projects-tiles .project {
    margin-top: 10rem;
  }

  .projects-tiles .project-details {
    padding: 2rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .projects-tiles .project {
    margin-top: 5rem;
  }
}

.projects-overflow .project-details {
  width: 100%;
}

.projects-overflow .item {
  position: relative;
  margin-top: 2.5rem;
}

.projects-overflow .item:first-child {
  margin-top: 0;
}

@media (min-width: 1200px) {
  .projects-overflow .project-details {
    max-width: 24rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .projects-overflow .project-details {
    max-width: 20rem;
  }
}

@media (min-width: 992px) {
  .projects-overflow .project-details {
    position: absolute;
  }

  .projects-overflow .item {
    margin-top: 10rem;
  }
}

@media (max-width: 991.98px) {
  .projects-overflow .project {
    box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04) !important;
    border-radius: 0.4rem !important;
  }

  .projects-overflow .project figure img {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .projects-overflow .project-details .card {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
  }
}

.projects-overflow2 .project-image {
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.projects-overflow2 .item {
  position: relative;
  margin-top: 2.5rem;
}

.projects-overflow2 .item .card {
  z-index: 1;
}

.projects-overflow2 .item .card .card-body {
  padding: 4rem;
}

.projects-overflow2 .item:first-child {
  margin-top: 0;
}

@media (min-width: 1400px) {
  .projects-overflow2 .project-image {
    max-width: 30rem;
  }
}

@media (min-width: 1200px) {
  .projects-overflow2 .project-image {
    max-width: 30rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .projects-overflow2 .project-image {
    max-width: 25rem;
  }
}

.overlap-grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

@media (min-width: 768px) {
  .overlap-grid-2 .item:nth-child(1) {
    width: 70%;
    margin-top: 0;
    margin-left: 30%;
    z-index: 3;
  }

  .overlap-grid-2 .item:nth-child(2) {
    width: 55%;
    margin-top: -45%;
    margin-left: 0;
    z-index: 4;
  }

  .overlap-grid-2 .item:nth-child(3) {
    width: 60%;
    margin-top: -35%;
    margin-left: 40%;
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .overlap-grid-2 .item {
    width: 100%;
  }

  .overlap-grid-2 .item+.item {
    margin-top: 1.5rem;
  }
}

.shape.rellax {
  position: absolute;
}

.shape.rellax {
  z-index: 1;
}

.position-relative .shape.rellax+figure {
  position: relative;
  z-index: 2;
}

.bg-dot,
.bg-line {
  opacity: 0.4;
}

.bg-dot {
  background-size: 0.75rem 0.75rem;
}

.bg-dot.blue {
  background-image: radial-gradient(#3f78e0 2px, transparent 2.5px);
}

.bg-dot.purple {
  background-image: radial-gradient(#747ed1 2px, transparent 2.5px);
}

.bg-dot.violet {
  background-image: radial-gradient(#a07cc5 2px, transparent 2.5px);
}

.bg-dot.pink {
  background-image: radial-gradient(#d16b86 2px, transparent 2.5px);
}

.bg-dot.red {
  background-image: radial-gradient(#e2626b 2px, transparent 2.5px);
}

.bg-dot.orange {
  background-image: radial-gradient(#f78b77 2px, transparent 2.5px);
}

.bg-dot.yellow {
  background-image: radial-gradient(#fab758 2px, transparent 2.5px);
}

.bg-dot.green {
  background-image: radial-gradient(#6bbea3 2px, transparent 2.5px);
}

.bg-dot.leaf {
  background-image: radial-gradient(#7cb798 2px, transparent 2.5px);
}

.bg-dot.aqua {
  background-image: radial-gradient(#54a8c7 2px, transparent 2.5px);
}

.bg-dot.navy {
  background-image: radial-gradient(#343f52 2px, transparent 2.5px);
}

.bg-dot.ash {
  background-image: radial-gradient(#9499a3 2px, transparent 2.5px);
}

.bg-dot.white {
  background-image: radial-gradient(#fff 2px, transparent 2.5px);
}

.bg-dot.light {
  background-image: radial-gradient(#fefefe 2px, transparent 2.5px);
}

.bg-dot.gray {
  background-image: radial-gradient(#f6f7f9 2px, transparent 2.5px);
}

.bg-dot.dark {
  background-image: radial-gradient(#262b32 2px, transparent 2.5px);
}

.bg-dot.primary {
  background-image: radial-gradient(#3f78e0 2px, transparent 2.5px);
}

.bg-line.blue {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #3f78e0 1.6px, #3f78e0 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.purple {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #747ed1 1.6px, #747ed1 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.violet {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #a07cc5 1.6px, #a07cc5 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.pink {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #d16b86 1.6px, #d16b86 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.red {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #e2626b 1.6px, #e2626b 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.orange {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #f78b77 1.6px, #f78b77 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.yellow {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #fab758 1.6px, #fab758 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.green {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #6bbea3 1.6px, #6bbea3 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.leaf {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #7cb798 1.6px, #7cb798 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.aqua {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #54a8c7 1.6px, #54a8c7 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.navy {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #343f52 1.6px, #343f52 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.ash {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #9499a3 1.6px, #9499a3 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.white {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #fff 1.6px, #fff 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.light {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #fefefe 1.6px, #fefefe 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.gray {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #f6f7f9 1.6px, #f6f7f9 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.dark {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #262b32 1.6px, #262b32 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

.bg-line.primary {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #3f78e0 1.6px, #3f78e0 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}

@media (max-width: 575.98px) {
  .shape {
    display: none;
  }
}

.classic-view .post {
  margin-bottom: 2rem;
}

.classic-view .post-header {
  margin-bottom: 0.9rem;
}

.blog.single .post {
  margin-bottom: 0;
}

.post-content {
  position: relative;
  hyphens: auto;
}

@media (min-width: 768px) {
  .classic-view article .card-body {
    padding: 2rem 2.5rem 1.25rem;
  }

  .classic-view article .card-footer {
    padding: 1.25rem 2.5rem 1.25rem;
  }

  .blog.single .card-body {
    padding: 2.8rem 3rem 2.8rem;
  }

  .grid-view article .card-body {
    padding: 1.75rem 1.75rem 1rem 1.75rem;
  }

  .grid-view article .card-footer {
    padding: 1.25rem 1.75rem 1.25rem;
  }
}

@media (max-width: 767px) {

  .classic-view article .card-body,
  .grid-view article .card-body {
    padding-bottom: 1rem;
  }
}

aside:not(.doc-sidebar) .widget+.widget {
  margin-top: 2rem;
}

footer [class*=col-] .widget+.widget {
  margin-top: 1rem;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  height: 100%;
}

.sidebar nav .nav-link {
  font-weight: 500;
  padding: 0;
}

.sidebar nav .nav-link.active {
  color: #3f78e0 !important;
}

#comments {
  margin: 0;
  position: relative;
}

#comments ol.commentlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

#comments ol.commentlist li.comment {
  margin-top: 2rem;
}

#comments ol.commentlist li.comment:first-child {
  margin: 0;
}

#comments ul.children {
  margin: 2rem 0 0 0;
  overflow: inherit;
  padding: 0 0 0 3.5rem;
  list-style: none;
}

#comments ul.children li.comment {
  position: relative;
}

#comments ul.children li.comment:before {
  display: block;
  font-family: "Custom";
  content: "\e904";
  font-size: calc(1.325rem + 0.9vw);
  position: absolute;
  left: -2.5rem;
  top: -1.25rem;
  color: rgba(164, 174, 198, 0.25);
}

@media (min-width: 1200px) {
  #comments ul.children li.comment:before {
    font-size: 2rem;
  }
}

#comments .comment-author,
#comments .post-meta {
  margin: 0;
}

#comments .comment-author {
  margin-bottom: 0.2rem;
}

.author-info h6,
.author-info .h6 {
  margin-bottom: 0.2rem;
}

#comments .comment-header {
  margin-bottom: 0.5rem;
}

#comments .post-meta li:before {
  margin: 0 0.6rem 0 0.4rem;
}

.user-avatar {
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  position: relative;
  border-radius: 100%;
}

@media (max-width: 767px) {
  #comments .user-avatar {
    display: none;
  }

  #comments ul.children {
    padding-left: 1.5rem;
  }

  #comments ul.children li.comment:before {
    display: none;
  }
}

.wrapper-border {
  border-bottom: 1px solid rgba(164, 174, 198, 0.2);
}

.wrapper-border.bg-dark {
  border-color: rgba(255, 255, 255, 0.08);
}

footer.bg-dark {
  background: #21262c !important;
}

.image-wrapper {
  background: no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.image-wrapper:not(.mobile) {
  background-attachment: fixed !important;
}

.image-wrapper.bg-auto {
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll !important;
}

.image-wrapper.bg-full {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll !important;
}

.image-wrapper.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll !important;
}

.image-wrapper.bg-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(30, 34, 40, 0.5);
}

.image-wrapper.bg-overlay-300:before {
  background: rgba(30, 34, 40, 0.3);
}

.image-wrapper.bg-overlay-400:before {
  background: rgba(30, 34, 40, 0.4);
}

.image-wrapper.bg-overlay:not(.bg-content) *,
.image-wrapper.bg-overlay.bg-content .content {
  position: relative;
  z-index: 2;
}

.image-wrapper.card:before {
  border-radius: 0.4rem !important;
}

@media (max-width: 991.98px) {
  .image-wrapper.bg-full {
    background-size: cover !important;
  }
}

@media (max-width: 767px) {
  .bg-map {
    background: none !important;
  }
}

.video-wrapper-inner {
  height: 30rem;
  width: 100%;
}

.video-wrapper {
  position: relative;
}

.video-wrapper .content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(30, 34, 40, 0.5);
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: baseline;
  background-size: cover;
}

video::-webkit-media-controls {
  display: none !important;
}

.wrapper.angled {
  position: relative;
  border: 0;
}

.wrapper.angled:before,
.wrapper.angled:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  z-index: 0;
  border: 0 solid transparent;
}

.wrapper.angled.lower-end:after {
  border-right-color: transparent;
  border-right-width: 100vw;
  border-bottom-width: 4rem;
  bottom: -4rem;
}

.wrapper.angled.lower-start:after {
  border-left-color: transparent;
  border-left-width: 100vw;
  border-bottom-width: 4rem;
  bottom: -4rem;
}

.wrapper.angled.upper-end:before {
  border-right-color: transparent;
  border-right-width: 100vw;
  border-top-width: 4rem;
  top: -4rem;
}

.wrapper.angled.upper-start:before {
  border-left-color: transparent;
  border-left-width: 100vw;
  border-top-width: 4rem;
  top: -4rem;
}

footer.bg-dark.angled.lower-end:after,
footer.bg-dark.angled.upper-end:before {
  border-right-color: #21262c !important;
}

footer.bg-dark.angled.lower-start:after,
footer.bg-dark.angled.upper-start:before {
  border-left-color: #21262c !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}