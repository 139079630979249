@import "constant";

// 992px
@media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    .site-navbar.navbar.center-nav {
        .offcanvas-nav {
            background: initial;
            box-shadow: unset;
        }
    }

    .hero-slider-wrapper {
        height: calc(100vh - var(--navbar-height) - var(--topbar-height));

        .hero-slider,
        .owl-stage-outer,
        .owl-stage,
        .owl-item {
            height: 100%;
        }

        .hero-slider {
            .owl-slide {
                height: 100%;
            }
        }
    }

    .navbar-nav > li > ul.mega-menu .mega-menu-content {
        padding: 0.5rem 0.75rem;
    }

    .pdf-item,
    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 90vw;
        }
    }

    .pdf-item {
        .modal-dialog {
            max-width: 80vw;
        }
    }

    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 55vw;
        }
    }

    .site-navbar.navbar.fixed .navbar-nav > .nav-item > .dropdown-menu,
    .site-navbar.navbar:not(.transparent) .navbar-nav > .nav-item:not(.language-select) > .dropdown-menu {
        margin-top: 0 !important;
    }

    .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
        color: rgb(var(--bs-primary-rgb));
    }

    .site-navbar.navbar .navbar-brand .logo-img {
        width: 250px;
    }

    .mega-menu-content {
        .masonry-column {
            width: 33.3333333%;
        }

        .masonry-column + .masonry-column {
            border-left: 1px solid rgba(164, 174, 198, 0.2);
        }
    }

    .dropdown-header {
        white-space: nowrap;
    }
    .site-navbar .navbar-nav {
        .nav-link {
            position: relative;
        }
        .nav-link:hover::after,
        .nav-link-active.nav-link::after {
            width: 100%;
            right: auto;
            background-color: var(--bs-primary);
            left: 0;
        }

        .nav-link::after {
            border-radius: 10em;
            box-sizing: border-box;
            border-right: solid 20px #fff;
            border-left: solid 20px #fff;
            transition: 0.25s linear;
            content: "";
            position: absolute;
            left: auto;
            right: 0;
            bottom: 20px;
            height: 2px;
            width: 0;
        }
    }
}
