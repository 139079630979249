@import 'xs';
@import 'sm';
@import 'md';
@import 'lg';
@import 'xl';
@import 'xxl';

@media screen and (max-width: 767px) {
    .content-html-wrapper {
        & table tr {
            & td:first-child {
                width: 40% !important;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .content-html-wrapper table tr {
        td:first-child::after {
            content: ':';
            color: white;
        }
        td:nth-child(2) {
            padding-left: 20px;
        }
        td {
            width: 100% !important;
        }
        td:first-child {
            width: 100% !important;
        }
        display: flex;
        flex-direction: column;
    }
}
