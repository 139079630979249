.health-plan-variant {
    .health-plan-logo {
        align-items: center;
        justify-content: center;
        .logo-container {
            box-shadow: 0px 0px 1em rgba(80, 93, 99, 0.5) !important;
            max-height: 5.5rem;
            height: 100%;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    .post-category {
        text-shadow: 0 0 1px #2f3840, 0 0 2px #17375d, 0 2px 3px #333;
        &:before {
            box-shadow: 0 0 1px 0 #2f3840, 0 0 2px 0 #17375d, 0 1px 3px 0 #333;
        }
    }
    .post-title {
        color: var(--primaryLight);
        text-shadow: 1px 1px 1px #fff, 0 0 4px #333;
    }
    .blog {
        .page-title {
            text-shadow: 1px 1px 3px #333;
            color: var(--primaryLight);
        }
    }
}
