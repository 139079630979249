:root {
    --primary: #072c5c;
    --primaryLight: #2abbfd;
    --primaryDark: #041e40;
    --textOnPrimary: #ffffff;
    --secondary: #03a9f4;
    --secondaryLight: #47c4fd;
    --secondaryDark: #3f78e0;
    --textOnSecondary: #000000;

    --navbar-height: 68px;
    --topbar-height: 35px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "IBM Plex Serif", Helvetica Neue, Arial, sans-serif;
    }

    --bs-primary-rgb: 7, 45, 92;

    .btn-primary:not(.btn-play) {
        background-color: rgb(var(--bs-primary-rgb));
        border-color: rgb(var(--bs-primary-rgb));
    }
}

html {
    scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
.ff-san {
    font-family: "IBM Plex Sans", Helvetica Neue, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
.ff-serif {
    font-family: "Source Serif Pro", serif;
}

// end base

header.image-wrapper,
.navbar {
    z-index: 999;
}

.navbar .hamburger,
.navbar [data-toggle="offcanvas-info"],
.navbar .btn {
    pointer-events: none;
}

.navbar .container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.thumbnail {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.carousel-box-overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(80, 93, 99, 0.5);
    color: white;
}

.video-block {
    li:before {
        display: none;
    }
}

.dropdown-item {
    &:hover {
        background: #e0e9fa;
    }
}

#vlipa-navbar {
    height: var(--navbar-height);
}

//hero section

#hero_header {
    position: relative;
    background-color: black;
    height: 60vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    background-size: cover;
}

#hero_header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

#hero_header .container {
    position: relative;
    z-index: 2;
}

#hero_header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
}

#footer-custom {
    background: var(--primary);
}

.header {
    color: var(--secondary);
}

.site-navbar.navbar:not(.banner--unstick),
.navbar.banner--stick:not(.navbar-dark),
.navbar.banner--stick.transparent {
    box-shadow: 0px 0px 1em rgba(80, 93, 99, 0.5) !important;
}

.dropdown-menu {
    border: 1px solid #ccc !important;
    box-shadow: 0px 0px 1em rgba(80, 93, 99, 0.5) !important;
    padding: 0.5rem 0 !important;
}

.site-navbar.navbar {
    .offcanvas-nav {
        box-shadow: 2px 0px 4px #333;
    }

    .mega-menu-content {
        .dropdown-header-title {
            position: relative;
            text-shadow: 1px 1px 1px rgb(128 128 128 / 20%);
            font-size: 1.1em;
            margin-bottom: 0.25rem;

            &::after {
                transition: all 200ms ease-in-out;
                position: absolute;
                bottom: -0.25rem;
                left: 0;
                width: 100px;
                height: 2px;
                content: "";
                background-color: var(--bs-primary);
            }
        }
    }

    .navbar-nav.sm-collapsible {
        .dropdown-header-title {
            color: white;
            padding-top: 0.25rem;
        }
    }
}

.site-navbar.navbar {
    .navbar-brand {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .navbar-nav:not(.sm-collapsible) > li > a {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .navbar-nav:not(.sm-collapsible) > li.show > a,
    .navbar-nav:not(.sm-collapsible) > li > a:hover {
        color: var(--bs-primary);
        background-color: white;

        .sub-arrow {
            color: var(--bs-primary);
        }
    }
}

.site-navbar.navbar.banner--stick {
    .navbar-brand {
        img {
            width: 250px;
            padding: 0;
        }
    }
}

.site-navbar.navbar {
    &.banner--clone {
        display: none;
    }
    .navbar-brand {
        img {
            width: 300px;
            height: 100%;
        }
    }
}

@media (max-width: 991.98px) {
    .navbar-nav .dropdown-menu {
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
    }

    .navbar-nav .nav-link,
    .navbar-nav .dropdown-menu .dropdown-item {
        &:hover {
            background: transparent;
            text-decoration: underline;

            .sub-arrow {
                color: var(--secondary);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 576px) {
    .pdf-item,
    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 100vw;
            margin: 0;
        }
    }
}

.dropdown-menu .dropdown-item {
    &:hover {
        background: #e0e9fa;
    }
}

.navbar-nav[class*="collapsible"] .dropdown-menu .dropdown-item {
    &:hover {
        background: transparent;
        text-decoration: underline;
    }
}

.post-thumbnail,
.advantage .post-item {
    &:not(.no-transform):hover {
        section {
            transform: scale(1.1);
            transition: all 0.5s;
        }
    }

    .post-title {
        bottom: 0;

        div {
            text-shadow: 1px 1px 3px #333;
        }
    }
}

// slide

#home-slide {
    h2 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 3.5rem;
        color: #03a9f4;
        white-space: 2px;
        font-family: "IBM Plex Serif", sans-serif;
        text-shadow: 1px 1px 2px #fff, 0 0 6px #333;
        display: inline-block;
    }

    p {
        color: #fff;
        text-shadow: 2px 2px 3px #333333, 0 0 15px #545354;
    }
}

// end slide

.card-hover {
    &:hover {
        box-shadow: 0rem 0.25rem 1.75rem rgb(30 34 40 / 7%) !important;
    }
}

.page-description {
    p {
        margin-bottom: 0 !important;
    }
}

#category-page {
    .post-header {
        h1 {
            display: block;
            width: min-content;
            white-space: nowrap;
        }
    }

    .inner-page {
        h3 {
            &::after {
                display: none;
            }
        }
    }
}

.category-header-title {
    &::after {
        left: 0 !important;
        margin-left: 0 !important;
    }
}

// TODO: Advantage

#advantage {
    .post-content {
        p {
            margin-bottom: unset;
        }

        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.advantage {
    transition: all 0.2s ease-in-out;

    .post-item {
        height: 100%;
    }
}

.zooming-container {
    &.advantage-active {
        .card {
            position: absolute;
            width: 120%;
            // height: 120%;
            left: -10%;
            top: -5%;
            box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%), 0rem 0rem 0.75rem rgb(30 34 40 / 45%) !important;
            z-index: 1;
        }
    }
}

@media (max-width: 768px) {
    .zooming-container {
        cursor: unset;
    }
}

#about-us-page {
    height: calc(100vh - 58px);

    .information-table {
        table {
            // Important usage: This content piece come from CMS so we opt to do any customize CSS at client
            width: 100% !important;
        }
        table td * {
            .uil {
                display: none;
            }
            word-break: break-word;
        }
    }
}

.about-us-page-customize {
    height: unset !important;
}

.hidden-heading {
    h2 {
        display: none !important;
    }
}

.owl-carousel-homepage {
    transition: all 700ms ease;
}

/* TODO: markdown content styles */

#markdown-wrapper {
    h1,
    h2,
    h3 {
        &::after {
            display: none;
        }
    }

    p {
        margin-bottom: 0 !important;
    }
}

.liststyle-none {
    &::before {
        display: none !important;
    }
}

#markdown-wrapper img {
    width: 100%;
    border: 1px solid #eee;
}

.markdown-content-wrapper {
    &.slide-markdown-wrapper {
        p {
            color: white;
        }
    }
}

/* TODO: --- end markdown content styles */

#dropdown-basic {
    padding: unset;
    transform: none;
    color: white;
    margin: unset;
    border: none;
    transition: all 200ms ease-in-out;
    // line-height: unset;

    text-decoration-style: solid;

    &:after {
        display: none;
    }

    .text-hover {
        position: relative;

        &::before {
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            top: 20px;
            left: 0;
            pointer-events: none;
            content: "";
            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
        }
    }

    &:hover {
        .text-hover {
            &::before {
                transform-origin: 0% 50%;
                transform: scale3d(1, 1, 1);
                transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
            }
        }
    }
}

#topbar {
    // height: var(--topbar-height);

    #icon-down {
        line-height: 0;
        height: 0;
        padding: 0;
        margin: 0;
    }
}

.topbar-dropdown-menu {
    margin-top: -1px;
}

.about-section {
    text-align: justify;
}

.home-section {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &.home-slide {
        padding: unset;
    }
}

.link-with-icon {
    display: flex;
    align-items: center;
    line-height: 1.2;

    .uil {
        height: min-content;
        padding: 0;
        margin: 0;
    }
}

.base-icon {
    font-size: 14px;
}

.expand-header {
    .base-icon {
        min-width: 1.1rem;
        line-height: 0;
    }
}

.post-header {
    .post-meta {
        margin-top: 10px;
    }
}

.post-content {
    p {
        font-weight: 400;
        margin-bottom: 0;
    }
}

// TODO: rellax override

.rellax {
    opacity: 0;
}

aside:not(.doc-sidebar) .widget + .widget {
    margin-top: 0.5rem;
}

// site-navbar

@keyframes no-js-show {
    from {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

// top-bar css
.top-bar {
    .uil-fast-mail-alt {
        line-height: 0;
    }
}

.mega-menu {
    a.header-link {
        &:hover {
            color: var(--bs-primary);
        }
    }

    .dropdown-item,
    .dropdown-header {
        padding: 0.1rem 1rem;
    }

    .dropdown-header {
        white-space: unset;
        &:hover {
            .dropdown-header-title:not(.no-hover)::after {
                width: 100%;
            }
        }
    }

    a.dropdown-item {
        font-weight: normal;
    }
}

.custom-header-title {
    position: relative;
    &:after {
        transition: all 200ms ease-in-out;
        position: absolute;
        bottom: -0.25rem;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        height: 2px;
        content: "";
        background-color: var(--bs-primary);
    }
}

// Override popover default styling
.popover {
    max-width: 350px;
    &:not(.popover-header) {
        .popover-body {
            padding: 0.5rem 0.75rem;
            font-size: 0.75rem;
        }
    }
}

.header-navbar {
    z-index: 1000;
    top: calc(var(--topbar-height) * -1);
}