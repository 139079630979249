@import "constant";

@media (min-width: #{map-get($grid-breakpoints, 'sm')}) {
    .home-section {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .about-section {
        text-align: unset;
    }
}
