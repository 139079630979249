@-moz-keyframes rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.animate__rotate {
    animation: rotate 2s linear infinite;
}
