@import "constant";

@media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    #page-section {
        .container {
            max-width: calc(100vw / 1.5);
        }
    }

    .home-section {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }

    .popup-title {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .rellax {
        opacity: 1;
    }

    .expand-header {
        .base-icon {
            min-width: unset;
        }
    }

    .health-plan-variant {
        .health-plan-logo {
            justify-content: end;
        }
    }

    #about-us-page {
        .information-table {
            table {
                td .uil {
                    display: inline;
                }
                // Important usage: This content piece come from CMS so we opt to do any customize CSS at client
                width: unset !important;
            }
        }
    }
}
